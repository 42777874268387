




































import {defineComponent, PropType, ref} from '@vue/composition-api';
import {UseCookieConsentService} from '@/cookie-consent/services/UseCookieConsentService';
import CookieConsentSettings, {
  CookeConsentSettingsEvents,
} from '@/cookie-consent/components/CookieConsentSettings.vue';
import {ConsentCategory} from '@/cookie-consent/types/ConsentCategory';
import {inject} from '@/container';

export default defineComponent({
  name: 'CookieConsentDialog',
  components: {CookieConsentSettings},
  model: {prop: 'modelValue', event: 'update:modelValue'},
  props: {
    modelValue: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  setup(props, {emit}) {
    const consentService = inject(UseCookieConsentService);
    const settingsOpen = ref(false);

    function emitConsentEvent(categories: ConsentCategory[]) {
      consentService.emitConsentEvent(categories);
      emit('update:modelValue', false);
    }

    return {
      ConsentCategory,
      ...CookeConsentSettingsEvents,
      settingsOpen,
      emitConsentEvent,
    };
  },
});
