import {StripeService} from '@/payments/services/stripe';
import {getEnvironment} from '@/environment';

/**
 * Installs the stripe service plugin
 * @param Vue
 */
export default function install(Vue) {
  Vue.prototype.$stripe = new StripeService(getEnvironment().stripeKey);
}
