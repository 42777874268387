<template>
  <v-app>
    <cookie-consent-dialog
      v-if="!isInertiaRoute && $features.isEnabled(FeatureFlag.CookieConsent)"
      v-model="consentDialogOpen"
    />
    <router-view v-if="isReady" />
    <loading-overlay
      :value="!isInertiaRoute && (!isReady || !quoteElapsed)"
      :message="isError ? $t('loadingOverlay.errorLoadingUser') : null"
      :progress-value="isError ? 100 : null"
    />
    <s-version-snackbar />
  </v-app>
</template>

<script>
import {requiresAnonymousUser, requiresAuthenticatedUser} from '@/router/auth';
import LoadingOverlay from '@/loading/components/LoadingOverlay';
import SVersionSnackbar from '@/versioning/components/SVersionSnackbar';
import {inject} from '@/container';
import {InteractiveRefreshDialogService} from '@/auth/services/InteractiveRefreshDialogService';
import CookieConsentDialog from '@/cookie-consent/components/CookieConsentDialog';
import {FeatureFlag} from '@/feature-flags';
import {UseCookieConsentService} from '@/cookie-consent/services/UseCookieConsentService';
import {CONTACT, INERTIA_LEGACY_TASK, PRIVACY, TERMS} from '@/router/route-names';
import {isDevelopment} from '@/environment';

let QUOTE_TIMEOUT = 1000;
if (isDevelopment()) {
  QUOTE_TIMEOUT = 0;
}

const {answeredDialog} = inject(UseCookieConsentService);

export default {
  name: 'App',
  components: {CookieConsentDialog, SVersionSnackbar, LoadingOverlay},
  data() {
    return {
      FeatureFlag,
      consentDialogOpen: !answeredDialog(),
      quoteElapsed: false,
      unauthenticated: false,
      currentUserPromise: null,
    };
  },
  computed: {
    user() {
      return this.$auth.user;
    },
    isReady() {
      return (
        this.isInertiaRoute ||
        (!this.isError &&
          (!this.needsUserResolved || this.userLoadedCheck || this.anonymousUserCheck))
      );
    },
    isError() {
      return this.$auth.isUserError && !this.$auth.isUserAnonymous;
    },
    userLoadedCheck() {
      return requiresAuthenticatedUser(this.$route) && this.$auth.hasUserEverLoggedIn;
    },
    anonymousUserCheck() {
      return requiresAnonymousUser(this.$route) && this.$auth.isUserAnonymous;
    },
    needsUserResolved() {
      return requiresAuthenticatedUser(this.$route) || requiresAnonymousUser(this.$route);
    },
    isInertiaRoute() {
      return this.$route.name === INERTIA_LEGACY_TASK;
    },
  },
  watch: {
    $route(to, from) {
      const excludeConsentFromRoutes = [PRIVACY, CONTACT, TERMS, INERTIA_LEGACY_TASK];
      if (excludeConsentFromRoutes.includes(to.name)) {
        this.consentDialogOpen = false;
      }

      if (
        excludeConsentFromRoutes.includes(from.name) &&
        !excludeConsentFromRoutes.includes(to.name)
      ) {
        this.consentDialogOpen = !answeredDialog();
      }
    },
  },
  created() {
    // Loads the current authenticated user and log them into the global Gate
    this.currentUserPromise = this.$auth.fetchCurrentUser().catch(() => {});

    setTimeout(() => (this.quoteElapsed = true), QUOTE_TIMEOUT);

    this.$auth.interactiveRefreshHandler = inject(
      InteractiveRefreshDialogService
    ).handleInteractiveRefresh;
  },
};
</script>

<style lang="scss">
@import '~ag-grid-community/dist/styles/ag-grid.css';
@import '~ag-grid-community/dist/styles/ag-theme-material.css';
@import '~katex/dist/katex.min.css';

.text-bold {
  font-weight: bold;
}

div#hubspot-messages-iframe-container {
  bottom: 50px !important;
  max-height: calc(100% - 80px) !important;
}

.s-badge {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  border-radius: 9999px;
  font-weight: 500;
  letter-spacing: 0.025em;
  width: fit-content;
  border-width: 1.5px;
  border-style: solid;
  box-shadow:
    inset 0.5px 2px 6px 0px rgba(44, 32, 80, 0.04),
    inset 0px 1px 2px 0px rgba(46, 36, 81, 0.03);
  line-height: 1;
}

.s-badge.sm {
  padding: 0.25rem 0.5rem !important;
  font-size: 0.75rem !important;
}

.s-badge.md {
  padding: 0.375rem 0.75rem !important;
  font-size: 0.875rem !important;
}

.s-badge.lg {
  padding: 0.5rem 1rem !important;
  font-size: 1rem !important;
}

.s-badge.white {
  background-color: #fff !important;
  border-color: #e5e7eb !important;
  color: #4a5568 !important;
}

.s-badge.gray {
  background-color: #f7fafc !important;
  border-color: #edf2f7 !important;
  color: #4a5568 !important;
}

.s-badge.blue {
  background-color: #d5edf8 !important;
  border-color: #abdaf1 !important;
  color: #2c7cbe !important;
}

.s-badge.green {
  background: #e5f9e6 !important;
  border-color: #bff0c1 !important;
  color: #449c47 !important;
}

.s-badge.yellow {
  background-color: #ffffcc !important;
  border-color: #ff9700 !important;
  color: #ff3301 !important;
}

.s-badge.red {
  background-color: #ffcc33 !important;
  border-color: #ff3301 !important;
  color: #ff3301 !important;
}
</style>
