import QuestionResponse from './QuestionResponse';
import {Fields} from '@vuex-orm/core';

/**
 * CalculationResponse class
 */
export class CalculationResponse extends QuestionResponse {
  static entity = 'CalculationResponse';

  floatAnswer!: number;
  stringAnswer!: string;

  static fields(): Fields {
    return {
      ...super.fields(),
      floatAnswer: this.number(null),
      stringAnswer: this.string(null),
    };
  }
}

export default CalculationResponse;
