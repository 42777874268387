import {IconsObject} from '@/icons/services/IconService';

const table = 'mdi-table';

const grades: IconsObject = {
  exportCsvGrades: table,
  gradeTable: table,
};

export default grades;
