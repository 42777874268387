import {HTTP} from '@/common/api/http';
import {API_BASE_URL} from '@/common/api/base';
import {IStembleAxiosPromise} from '@/common/api/types/IStembleAxiosPromise';
import {RawLoginTokenResponse} from '@/auth/types/RawLoginTokenResponse';

const BASE_URL = API_BASE_URL + '/auth';

/**
 * Send a login request
 *
 * @param {string} email
 * @param {string} password
 * @param {boolean} rememberMe
 * @returns {Promise<RawLoginTokenResponse>}
 */
export const login = async (
  email: string,
  password: string,
  {rememberMe = false} = {}
): Promise<RawLoginTokenResponse> => {
  return HTTP.post(`${BASE_URL}/login`, {
    email,
    password,
    rememberMe,
  });
};

/**
 * Send an act as request
 *
 * @param email
 * @param password
 * @returns {Promise<RawLoginTokenResponse>}
 */
export const actAs = async (email: string, password: string): Promise<RawLoginTokenResponse> => {
  return HTTP.post(`${BASE_URL}/act-as`, {
    email,
    password,
  });
};

/**
 * Request a new authentication token
 *
 * @returns {Promise<any>}
 */
export const refresh = async () => {
  return HTTP.post(`${BASE_URL}/refresh`, null);
};

/**
 * Register a user
 *
 * @param {String} firstName
 * @param {String} lastName
 * @param {String} email
 * @param {String} studentId
 * @param {String} password
 * @param {String} token
 * @returns {Promise<RawLoginTokenResponse>}
 */
export const register = async (
  firstName: number,
  lastName: number,
  email: number | undefined, // FIXME: this will change once we allow email again
  studentId: string | null | undefined,
  password: string,
  token: string
): Promise<RawLoginTokenResponse> => {
  return HTTP.post(`${BASE_URL}/register`, {
    firstName,
    lastName,
    email,
    studentId,
    password,
    token,
  });
};

/**
 * Requests that the back-end send a password reset e-mail to the user
 *
 * @param email
 * @returns {IStembleAxiosPromise}
 */
export const requestPasswordResetLink = async (email: string): IStembleAxiosPromise => {
  return HTTP.post(`${BASE_URL}/password/email`, {
    email,
  });
};

/**
 * Attempt to reset the user's password
 *
 * @param email
 * @param password
 * @param token
 * @returns {IStembleAxiosPromise}
 */
export const resetPassword = async (
  email: string,
  password: string,
  token: string
): IStembleAxiosPromise => {
  return HTTP.post(`${BASE_URL}/password/reset`, {
    token,
    email,
    password,
  });
};

/**
 * Logs the user out
 *
 * @returns {IStembleAxiosPromise}
 */
export const logout = async (): IStembleAxiosPromise => {
  return HTTP.post(`${BASE_URL}/logout`);
};

/**
 * Verify the current user's e-mail address
 *
 * @param {String} token
 * @returns {IStembleAxiosPromise}
 */
export const verifyEmail = async (token: string): IStembleAxiosPromise => {
  return HTTP.post(`${BASE_URL}/email/verify`, {
    token,
  });
};

/**
 * Request a new verification token for the current user
 *
 * @returns {IStembleAxiosPromise}
 */
export const resendVerificationEmail = async (): IStembleAxiosPromise => {
  return HTTP.get(`${BASE_URL}/email/resend`);
};

export default {
  login,
  register,
  requestPasswordResetLink,
  resetPassword,
  logout,
  verifyEmail,
  resendVerificationEmail,
};
