import {HTTP} from '@/common/api/http';
import {API_BASE_URL} from '@/common/api/base';
import {RawVisitorResponse} from '@/onboarding/types/RawVisitorResponse';
import {IStembleAxiosPromise} from '@/common/api/types/IStembleAxiosPromise';
import User from '@/users/models/User';

export const HUBSPOT_VISITOR_URL = API_BASE_URL + '/hubspot-visitors';

/**
 * Fetch a hubspot visitor token
 * @return Promise
 */
export const getVisitorToken = async (user: User): IStembleAxiosPromise<RawVisitorResponse> => {
  return HTTP.get(`${HUBSPOT_VISITOR_URL}/token/${user.id}`);
};
