export class Specification {
  constructor() {
    this.courseHierarchyService = null;
  }

  isSatisfiedBy(courseRole) {
    return courseRole !== null;
  }

  setServices(courseHierarchyService) {
    this.courseHierarchyService = courseHierarchyService;
  }

  static getIds(courseLikes) {
    return courseLikes instanceof Array ? courseLikes : [courseLikes];
  }
}

export default Specification;
