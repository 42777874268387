import {getCourseHierarchies, getForCurrentUser} from '@/courses/api/course-hierarchies';
import {makeGlobalSingleton} from '@/container';

export const CourseHierarchyService = makeGlobalSingleton(() => {
  return new CourseHierarchyServiceClass();
});

export default class CourseHierarchyServiceClass {
  constructor() {
    this.courses = {};
    this.courseLikeIdToCourseId = {};
  }

  async fetchForCurrentUser() {
    const response = await getForCurrentUser();
    this.insertCourseHierarchies(response.data.data);
  }

  async fetchForCourseLikeIds(courseLikeIds) {
    const response = await getCourseHierarchies(courseLikeIds);
    this.insertCourseHierarchies(response.data.data);
  }

  insertCourseHierarchies(courseHierarchies) {
    for (const courseHierarchy of courseHierarchies) {
      this.insertCourseHierarchy(courseHierarchy);
    }
  }

  insertCourseHierarchy(courseHierarchy) {
    const {courseId, sectionIds, subCourseHierarchy} = courseHierarchy;
    this.courses[courseId] = courseHierarchy;

    this.courseLikeIdToCourseId[courseId] = courseId;
    for (const sectionId of sectionIds) {
      this.courseLikeIdToCourseId[sectionId] = courseId;
    }
    if (subCourseHierarchy !== null) {
      this.insertCourseHierarchy(subCourseHierarchy);
    }
  }

  _getCourseArray(courseLikeId) {
    const courseId = this.courseLikeIdToCourseId[courseLikeId];
    if (courseId === undefined) {
      return null;
    }
    return this.courses[courseId];
  }

  getCourseId(courseLikeId) {
    return this.courseLikeIdToCourseId[courseLikeId] || null;
  }

  getChildrenIds(courseLikeId) {
    const courseArray = this._getCourseArray(courseLikeId);
    if (courseArray === null || this.isSection(courseLikeId)) {
      return [];
    }
    const result = [...courseArray.sectionIds];
    if (courseArray.subCourseHierarchy !== null) {
      result.push(courseArray.subCourseHierarchy.courseId);
    }
    return result;
  }

  getSectionIds(courseLikeId) {
    const courseArray = this._getCourseArray(courseLikeId);
    if (courseArray === null || this.isSection(courseLikeId)) {
      return [];
    }
    return courseArray.sectionIds;
  }

  isCourse(courseLikeId) {
    const courseArray = this._getCourseArray(courseLikeId);
    if (courseArray === null) {
      return false;
    }
    return courseArray.courseId === courseLikeId;
  }

  isSection(courseLikeId) {
    const courseArray = this._getCourseArray(courseLikeId);
    if (courseArray === null) {
      return false;
    }
    return courseArray.courseId !== courseLikeId;
  }

  getParentId(courseLikeId) {
    const courseArray = this._getCourseArray(courseLikeId);
    if (courseArray === null) {
      return null;
    }
    return this.isSection(courseLikeId) ? courseArray.courseId : courseArray.parentId;
  }

  isCourseLikeInCourse(courseLikeId, courseId) {
    return this.getCourseId(courseLikeId) === courseId;
  }
}
