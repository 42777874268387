import {AbstractDistribution} from '@/distributions/models/AbstractDistribution';
import {ChemicalElementDistributionDto} from '@/distributions/dtos/ChemicalElementDistributionDto';
import {ChemicalElementValue} from '@/task-values/models/ChemicalElementValue';

export class ChemicalElementDistribution
  extends AbstractDistribution<ChemicalElementValue>
  implements ChemicalElementDistributionDto
{
  static entity = 'ChemicalElementDistribution';
}
