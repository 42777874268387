import {HTTP} from '@/common/api/http';
import {API_BASE_URL} from '@/common/api/base';
import {USER_BASE_URL} from '@/users/api/users';
import {IStembleAxiosPromise} from '@/common/api/types/IStembleAxiosPromise';

const COURSE_HIERARCHIES_BASE_URL = `${API_BASE_URL}/course-hierarchies`;

/**
 * Get the course hierarchies arrays for the current user
 * @returns {IStembleAxiosPromise}
 */
export const getForCurrentUser = async (): IStembleAxiosPromise => {
  return HTTP.get(`${USER_BASE_URL}/course-hierarchies`);
};

/**
 * Get many course hierarchies
 * @param {number|number[]} courseLikeIds
 * @returns {IStembleAxiosPromise}
 */
export const getCourseHierarchies = async (courseLikeIds: number): IStembleAxiosPromise => {
  return HTTP.get(COURSE_HIERARCHIES_BASE_URL, {params: {courseLikeIds}});
};

export default {
  getForCurrentUser,
  getCourseHierarchies,
};
