import Axios, {AxiosInstance} from 'axios';
import {boolsToInts, extractMetaData, momentsToDates} from '@/common/utils/axios';

export const HTTP: AxiosInstance = Axios.create({
  baseURL: '/',
});

// Converts any boolean GET parameters to integers since Laravel doesn't like string true/false
HTTP.interceptors.request.use(boolsToInts);
HTTP.interceptors.request.use(momentsToDates);

HTTP.interceptors.response.use(extractMetaData);
