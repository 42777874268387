import QuestionResponse from './QuestionResponse';
import {Fields} from '@vuex-orm/core';

/**
 * DynamicResponse class
 */
export class DynamicResponse extends QuestionResponse {
  static entity = 'DynamicResponse';

  data!: {[key: string]: any};

  static fields(): Fields {
    return {
      ...super.fields(),
      data: this.attr(null),
    };
  }
}

export default DynamicResponse;
