import {HTTP} from '@/common/api/http';
import {API_BASE_URL} from '@/common/api/base';
import {IStembleAxiosPromise} from '@/common/api/types/IStembleAxiosPromise';
import {IGetTaskFeedbacksParams} from '@/tasks/types/IGetTaskFeedbacksParams';

export const TASK_FEEDBACKS_BASE_URL = API_BASE_URL + '/task-feedbacks';

/**
 * Get many task types
 * @returns {IStembleAxiosPromise}
 * @param params
 */
export const getTaskFeedbacks = (params: IGetTaskFeedbacksParams = {}): IStembleAxiosPromise => {
  return HTTP.get(TASK_FEEDBACKS_BASE_URL, {params});
};

export default {
  getTaskFeedbacks,
};
