export interface QuoteObject {
  text: string;
  author: string;
}

export const QUOTES: QuoteObject[] = [
  {
    text: "Education is not just about going to school and getting a degree. It's about widening your knowledge and absorbing the truth about life.",
    author: 'Shakuntala Devi',
  },
  {
    text: 'It is the mark of an educated mind to be able to entertain a thought without accepting it.',
    author: 'Aristotle',
  },
  {
    text: 'Education is not only a ladder of opportunity, but it is also an investment in our future.',
    author: 'Ed Markey',
  },
  {
    text: 'There is no end to education. It is not that you read a book, pass an examination, and finish with education. The whole of life, from the moment you are born to the moment you die, is a process of learning.',
    author: 'Jiddu Krishnamurti',
  },
  {
    text: 'The function of education is to teach one to think intensively and to think critically. Intelligence plus character - that is the goal of true education.',
    author: 'Martin Luther King, Jr.',
  },
  {
    text: 'For me, it is far better to grasp the Universe as it really is than to persist in delusion, however satisfying and reassuring.',
    author: 'Carl Sagan',
  },
  {
    text: 'If you wish to make an apple pie from scratch, you must first invent the universe.',
    author: 'Carl Sagan',
  },
  {
    text: 'The nitrogen in our DNA, the calcium in our teeth, the iron in our blood, the carbon in our apple pies were made in the interiors of collapsing stars. We are made of starstuff.',
    author: 'Carl Sagan',
  },
  {
    text: "When you make the finding yourself - even if you're the last person on Earth to see the light - you'll never forget it.",
    author: 'Carl Sagan',
  },
  {
    text: 'Common sense is the collection of prejudices acquired by age eighteen.',
    author: 'Albert Einstein',
  },
  {
    text: 'All truths are easy to understand once they are discovered; the point is to discover them.',
    author: 'Galileo Galilei',
  },
  {
    text: 'The whole of science is nothing more than a refinement of everyday thinking.',
    author: 'Albert Einstein',
  },
  {
    text: "If you don't know where you are going, any road will get you there",
    author: 'Lewis Carroll',
  },
  {
    text: 'If you wish to improve, be content to appear clueless or stupid in extraneous matters.',
    author: 'Epictetus',
  },
  {
    text: 'A wealth of information creates a poverty of attention.',
    author: 'Herbert Simon',
  },
  {
    text: 'Almost everything will work again if you unplug it for a few minutes, including you.',
    author: 'Anne Lamott',
  },
  {
    text: 'To doubt everything or to believe everything are two equally convenient solutions; both dispense with the necessity of reflection.',
    author: 'Henri Poincaré',
  },
  {
    text: 'It is by logic that we prove, but by intuition that we discover.',
    author: 'Henri Poincaré',
  },
  {
    text: 'The universe is full of magical things patiently waiting for our wits to grow sharper.',
    author: 'Eden Phillpotts',
  },
  {
    text: 'When we try to pick out anything by itself, we find it hitched to everything else in the universe.',
    author: 'John Muir',
  },
  {
    text: 'Any fool can know. The point is to understand.',
    author: 'Albert Einstein',
  },
  {
    text: 'The price of anything is the amount of life you exchange for it.',
    author: 'Henry David Thoreau',
  },
  {
    text: 'Every day of our lives, we are on the verge of making those slight changes that would make all the difference.',
    author: 'Mignon McLaughlin',
  },
  {
    text:
      'Outside is pure energy and colorless substance. All of the rest happens through the mechanism of our senses. ' +
      'Our eyes see just a small fraction of the light in the world. It is a trick to make a colored world, which does not exist outside of human beings.',
    author: 'Albert Hofmann',
  },
  {
    text: 'For every complex problem there is an answer that is clear, simple, and wrong.',
    author: 'H. L. Mencken',
  },
  {
    text: 'If you are so sure you are right that you can’t even listen to another view ... you’re probably wrong.',
    author: 'Jesse Lyn Stoner',
  },
  {
    text: "The truth knocks on the door and you say, ‘Go away, I'm looking for the truth,’ and so it goes away. Puzzling.",
    author: 'Robert M. Pirsig',
  },
  {
    text: 'Towering genius disdains a beaten path.  It seeks regions hitherto unexplored.',
    author: 'Abraham Lincoln',
  },
  {
    text: "You can't go back and change the beginning, but you can start where you are and change the ending.”",
    author: 'C. S. Lewis',
  },
  {
    text:
      'I regard consciousness as fundamental. ' +
      'I regard matter as derivative from consciousness. ' +
      'We cannot get behind consciousness. ' +
      'Everything that we talk about, everything that we regard as existing, postulates consciousness.',
    author: 'Max Planck',
  },
  {
    text:
      'Without courage we cannot practice any other virtue with consistency. ' +
      "We can't be kind, true, merciful, generous, or honest.",
    author: 'Maya Angelou',
  },
  {
    text: 'The first principle is that you must not fool yourself – and you are the easiest person to fool.',
    author: 'Richard Feynman',
  },
];
