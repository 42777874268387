import {ActionFactory} from '@/orm/api/actions';
import EventSchedule from '@/events/models/EventSchedule';
import {
  createEventSchedule,
  deleteEventSchedule,
  getEventSchedule,
  getEventSchedules,
  patchEventSchedule,
  putEventSchedule,
} from '@/events/api/event-schedules';

const factory = new ActionFactory(() => EventSchedule);

export const get = factory.insertAction(getEventSchedule);
export const fetch = factory.insertAction(getEventSchedules);
export const create = factory.insertAction(createEventSchedule);
export const update = factory.insertAction(putEventSchedule);
export const patch = factory.insertAction(patchEventSchedule);
export const remove = factory.deleteAction(deleteEventSchedule);
