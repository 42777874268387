import {ref, watch} from '@vue/composition-api';
import {root} from '@/main';
import {Route as VueRoute} from 'vue-router';
import {VueRouter} from 'vue-router/types/router';
import {makeDependency} from '@/container';

/**
 * Inject the router through the container
 */
export const Router = makeDependency(() => useRouter());

/**
 * Inject the router through a composition function
 */
export const useRouter = (): VueRouter => {
  return root.$router;
};

/**
 * Injects the current route through the container
 *
 * Due to limitations in @vue/composition-api, an object resembling a Route is returned to proxy
 * access to the reactive ref.
 */
export const Route = makeDependency(() => useRoute());

/**
 * Injects the current route through a composition function.
 *
 * Due to limitations in @vue/composition-api, an object resembling a Route is returned to proxy
 * access to the reactive ref.
 */
export const useRoute = (): VueRoute => {
  const route = ref(root.$route);

  // Update the route as it changes
  watch(
    () => root.$route,
    (r) => {
      route.value = r;
    }
  );

  // Get all Route keys
  const keys = Object.keys(route.value) as (keyof VueRoute)[];

  // Creates getters for all Route properties so that they trigger reactivity
  return keys.reduce((acc, key) => {
    Object.defineProperty(acc, key, {
      get() {
        return route.value[key];
      },
    });
    return acc;
  }, {} as VueRoute);
};
