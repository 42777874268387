import {HTTP} from '@/common/api/http';
import {API_BASE_URL} from '@/common/api/base';
import {TASKS_BASE_URL} from '../../tasks/api/tasks';
import {IGetIssuedTaskStatesParams} from '@/task-states/types/IGetIssuedTaskStatesParams';
import {IStembleAxiosPromise} from '@/common/api/types/IStembleAxiosPromise';
import {IGetIssuedTaskStateParams} from '@/task-states/types/IGetIssuedTaskStateParams';
import {IGetIssuedStateForTaskParams} from '@/task-states/types/IGetIssuedStateForTaskParams';
import {IExportTaskStateCsvParams} from '@/tasks/types/IExportTaskStateCsvParams';

export const ISSUE_TASK_STATE_BASE_URL = `${API_BASE_URL}/issued-task-states`;

/**
 * Fetch many task states
 * @return {IStembleAxiosPromise}
 * @param params
 */
export const getIssuedTaskStates = async (
  params: IGetIssuedTaskStatesParams = {}
): IStembleAxiosPromise => {
  return HTTP.get(ISSUE_TASK_STATE_BASE_URL, {params});
};

/**
 * Get a single task state by ID
 * @param {Number} id
 * @param params
 * @return {IStembleAxiosPromise}
 */
export const getIssuedTaskState = async (
  id: number,
  params: IGetIssuedTaskStateParams = {}
): IStembleAxiosPromise => {
  return HTTP.get(`${ISSUE_TASK_STATE_BASE_URL}/${id}`, {params});
};

/**
 * Get or reissue an IssuedTaskState for a task. If no user is given, the current
 * authenticated user is assumed.
 * @param {Number} task the task ID
 * @param params
 * @returns {IStembleAxiosPromise}
 */
export const getIssuedStateForTask = (
  task: number,
  params: IGetIssuedStateForTaskParams = {}
): IStembleAxiosPromise => {
  const url = `${TASKS_BASE_URL}/${task}/issued-state`;
  if (params.reissue) {
    return HTTP.post(url, params);
  } else {
    return HTTP.get(url, {params});
  }
};

export const exportTaskStateCsv = (params: IExportTaskStateCsvParams) => {
  return HTTP.get(`${ISSUE_TASK_STATE_BASE_URL}/csv`, {responseType: 'blob', params});
};

export default {
  getIssuedTaskState,
  getIssuedTaskStates,
  getIssuedStateForTask,
  exportTaskStateCsv,
};
