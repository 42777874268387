import {API_BASE_URL} from '@/common/api/base';
import {IStembleAxiosPromise} from '@/common/api/types/IStembleAxiosPromise';
import {HTTP} from '@/common/api/http';
import FileUpload from '@/tasks/models/FileUpload';
import {forEach} from 'ag-grid-community/dist/lib/utils/array';

export const FILE_UPLOADS_BASE_URL = API_BASE_URL + '/file-uploads';

export const downloadFile = (id: number) => {
  return HTTP.get(`${FILE_UPLOADS_BASE_URL}/${id}/display`, {responseType: 'blob'});
};

export const createFileUpload = (files: File[], fileUpload: FileUpload): IStembleAxiosPromise => {
  const formData = new FormData();

  files.forEach((file) => {
    formData.append('files[]', file);
  });

  formData.append('linkedEntityType', fileUpload.linkedEntityType);
  formData.append('linkedEntityId', fileUpload.linkedEntityId.toString());
  return HTTP.post(`/api/v0/file-uploads`, formData, {
    headers: {'Content-Type': 'multipart/form-data'},
  });
};

export const deleteFileUpload = (fileUpload: FileUpload): IStembleAxiosPromise => {
  return HTTP.delete(`/api/v0/file-uploads/${fileUpload.id}`);
};

export default {
  downloadFile,
  createFileUpload,
};
