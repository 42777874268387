import {StembleModel} from '@/common/models/StembleModel';
import {Fields} from '@vuex-orm/core';
import {TaskValueValueType} from '@/task-values/types/TaskValueValueType';
import {TaskValueDto} from '@/task-values/dtos/TaskValueDto';
import {TaskValueQueryParameters} from '@/task-values/types/TaskValueQueryParameters';

export abstract class AbstractTaskValue<
    T extends TaskValueValueType = TaskValueValueType,
    K extends string | number = string,
  >
  extends StembleModel
  implements TaskValueDto<any>
{
  id!: K;
  taskId!: number;
  taskStateId!: number | null;
  taskResponseId!: number | null;

  abstract getValue(): T;

  static fullQuery(options: TaskValueQueryParameters) {
    const q = options.query;
    if (options.includeSource ?? true) {
      q.with('source');
    }
    return q;
  }

  static fields(): Fields {
    return {
      id: this.string(null),
      taskId: this.number(null),
      taskStateId: this.number(null),
      taskResponseId: this.number(null),
    };
  }
}
