import {IResolvable} from '@/container/types/IResolvable';
import {IFactoryFunction} from '@/container/types/IFactoryFunction';
import {IContainer} from '@/container/types/IContainer';
import {
  getCurrentInstance as getCurrentVueInstance,
  inject as vueInject,
  provide as vueProvide,
  InjectionKey,
} from '@vue/composition-api';
import {IContainerBindings} from '@/container/types/IContainerBindings';
import {makeContainerBindings} from '@/container/utils/container-bindings';
import {DEFAULT_GLOBAL, DEFAULT_SINGLETON} from '@/container/dependency-factory';
import {symbolFor} from '@/container/utils/injection-keys';

let globalContainer: IContainer;
let activeContainer: IContainer;

resetGlobalContainer();

/** Symbol for injecting the container in Vue instances */
const containerSymbol: InjectionKey<IContainer> = Symbol();

export function resolveActiveContainer(): IContainer {
  if (getCurrentVueInstance()) {
    // Make sure default is not undefined so that Vue doesn't warn about non-existent injection key
    const container = vueInject(containerSymbol, null as unknown as IContainer);
    if (container) {
      return container;
    }
  }
  return activeContainer;
}

/**
 * Re-instantiate the global container, emptying any bindings
 */
export function resetGlobalContainer(): IContainer {
  if (activeContainer?.isInjecting()) {
    throw new Error('cannot reset the container while injecting');
  }
  globalContainer = makeContainer();
  activeContainer = globalContainer;
  return globalContainer;
}

/**
 * Build a new container, optionally providing the bindings
 * @param bindings
 */
export function makeContainer(bindings?: IContainerBindings): IContainer {
  const effectiveBindings = bindings ?? makeContainerBindings();
  let isInjecting = false;

  function getRootBindings(): IContainerBindings {
    let currentBindings = effectiveBindings;
    for (let i = 0; i < 30 && currentBindings?.parent; i++) {
      currentBindings = currentBindings.parent;
    }
    return currentBindings;
  }

  function recursivelyResolve<T, FactoryArgs extends any[]>(
    service: IResolvable<T, FactoryArgs>,
    bindings: IContainerBindings,
    ...args: FactoryArgs
  ): T | undefined {
    let instance: T | undefined;
    let currentBindings: IContainerBindings | undefined = bindings;
    for (let i = 0; i < 30 && currentBindings; i++) {
      instance = currentBindings.resolve<T, FactoryArgs>(service, ...args);
      if (instance !== undefined) {
        return instance;
      }

      currentBindings = currentBindings.parent;
    }
  }

  return {
    extend(): IContainer {
      return makeContainer(effectiveBindings.extend());
    },
    clone(): IContainer {
      return makeContainer(effectiveBindings.clone());
    },
    /**
     * Bind an instance into the container
     * @param service
     * @param instance
     */
    singleton<T>(service: IResolvable<T>, instance?: T) {
      if (instance === undefined) {
        instance = this.inject(service);
      }
      effectiveBindings.singleton(service, instance);
      return this;
    },
    /**
     * Bind a factory into the container
     * @param service
     * @param factory
     */
    factory<T, FactoryArgs extends any[]>(
      service: IResolvable<T, FactoryArgs>,
      factory?: IFactoryFunction<T, FactoryArgs>
    ): IContainer {
      if (factory === undefined) {
        // Ensures `this` is the service object
        factory = (...args: FactoryArgs) => service.factory.call(service, ...args);
      }
      effectiveBindings.factory(service, factory);
      return this;
    },
    isInjecting(): boolean {
      return isInjecting;
    },
    provide<T, FactoryArgs extends any[]>(
      service: IResolvable<T, FactoryArgs>,
      ...args: FactoryArgs
    ) {
      const instance = this.inject<T, FactoryArgs>(service, ...args);
      vueProvide(symbolFor(service), instance);
      return this;
    },
    /**
     * Resolve a service using the container
     * @param service
     * @param args
     */
    inject<T, FactoryArgs extends any[]>(
      service: IResolvable<T, FactoryArgs>,
      ...args: FactoryArgs
    ): T {
      const oldContainer = activeContainer;
      try {
        activeContainer = this;
        isInjecting = true;
        const isGlobal = service?.global ?? DEFAULT_GLOBAL;

        let instance: T | undefined;
        if (getCurrentVueInstance()) {
          // Make sure default is not undefined so that Vue doesn't warn about non-existent injection key
          instance = vueInject(symbolFor(service), null as unknown as T);
          if (instance) {
            return instance;
          }
        }

        const currentBindings = isGlobal ? getRootBindings() : effectiveBindings;
        instance = recursivelyResolve(service, currentBindings, ...args);
        if (instance !== undefined) {
          return instance;
        }

        instance = service.factory.call(service, ...args);

        if (service?.singleton ?? DEFAULT_SINGLETON) {
          currentBindings.singleton(service, instance);
        }

        return instance;
      } finally {
        activeContainer = oldContainer;
        isInjecting = false;
      }
    },
  };
}

/**
 * Clone the active container
 */
export function cloneContainer(): IContainer {
  return resolveActiveContainer().clone();
}

/**
 * Extend the active container
 */
export function extendContainer(): IContainer {
  return resolveActiveContainer().extend();
}

/*
 * Vue-specific functions
 */
/**
 * Extends and provides the extended container on the current Vue instance
 */
export function provideContainer(): IContainer {
  const container = extendContainer();
  vueProvide(containerSymbol, container);
  return container;
}
