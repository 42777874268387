import swal, {SweetAlertOptions} from 'sweetalert2';
import {AuthService} from '@/auth/services/authService';
import {useTranslate} from '@/locales/composables/i18n';
import {inject, makeDependency} from '@/container';
import {Theme} from '@/theme/services';
import {DependencyType} from '@/container/types/DependencyType';
import {getInstance} from '@/auth/plugins/authServicePlugin';
import {isDevelopment} from '@/environment';

export const InteractiveRefreshDialogService = makeDependency(() => {
  return makeInteractiveRefreshDialogService(
    getInstance() as AuthService,
    useTranslate(),
    inject(Theme)
  );
});

export function makeInteractiveRefreshDialogService(
  authService: AuthService,
  $t: ReturnType<typeof useTranslate>,
  theme: DependencyType<typeof Theme>
) {
  let refreshHandlerPromise: Promise<void> | null = null;

  async function attemptRefresh(password: string) {
    if (!password) {
      return false;
    }

    try {
      await authService.nonInteractiveLogin(authService.user.email, password, true);
    } catch (e) {
      swal.increaseTimer(5000);
      return false;
    }
  }

  async function showDialog() {
    // 60s in milliseconds in production
    const timerLength = (isDevelopment() ? 15 : 60) * 1000;
    const options: SweetAlertOptions = {
      title: $t('refreshFailedHandler.confirmPassword') as string,
      text: $t('refreshFailedHandler.explanationOfPassword') as string,
      input: 'password',
      inputPlaceholder: $t('password') as string,
      confirmButtonText: $t('login') as string,
      confirmButtonColor: theme.secondary,
      inputAttributes: {
        autocapitalize: 'off',
        autocorrect: 'off',
      },
      timerProgressBar: true,
      showLoaderOnConfirm: true,
      preConfirm: attemptRefresh,
      allowOutsideClick: false,
      allowEscapeKey: false,
      timer: timerLength,
    };
    return swal.fire(options);
  }

  function clearPromise() {
    refreshHandlerPromise = null;
  }

  async function handleInteractiveRefresh() {
    if (refreshHandlerPromise === null) {
      refreshHandlerPromise = showDialog()
        .then((result) => {
          if (!result.isConfirmed) {
            throw new Error('password dialog dismissed');
          }
        })
        .finally(clearPromise);
    }
    return refreshHandlerPromise;
  }

  return {
    handleInteractiveRefresh,
  };
}
