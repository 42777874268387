export enum RoleName {
  SuperUser = 'Super User',
  CourseAdministrator = 'Course Administrator',
  LabAdministrator = 'Lab Administrator',
  CourseInstructor = 'Course Instructor',
  LabInstructor = 'Lab Instructor',
  Student = 'Student',
  TeachingAssistant = 'Teaching Assistant',
  Faculty = 'Faculty',
  CourseInstructorReadOnly = 'Course Instructor (Read Only)',
  LabInstructorReadOnly = 'Lab Instructor (Read Only)',
}
