import User from '@/users/models/User';
import Assignment from '@/assignments/models/Assignment';
import BaseGrade from './BaseGrade';
import {CourseSection} from '@/courses/models';
import * as AssignmentGradeApi from '@/grades/api/orm/AssignmentGradeApi';
import {Joined} from '@/orm/types/Joined';
import {Fields} from '@vuex-orm/core';
import {RawAssignmentGrade} from '@/grades/types/RawAssignmentGrade';

/**
 * AssignmentGrade class
 *
 * Represents a single assignment grade
 */
export class AssignmentGrade extends BaseGrade implements RawAssignmentGrade {
  static entity = 'AssignmentGrade';
  static mapping: {[id: number]: {[id: number]: number}} = {};

  id!: number;
  userId!: number;
  user!: Joined<User>;
  sectionId!: number | null;
  section!: Joined<CourseSection>;
  assignmentId!: number;
  assignment!: Joined<Assignment>;

  static fields(): Fields {
    return {
      ...super.fields(),
      id: this.increment(),
      userId: this.number(null),
      user: this.belongsTo(User, 'userId'),
      sectionId: this.number(null).nullable(),
      section: this.belongsTo(CourseSection, 'sectionId'),
      assignmentId: this.number(null),
      assignment: this.belongsTo(Assignment, 'assignmentId'),
    };
  }

  static get api() {
    return AssignmentGradeApi;
  }

  /**
   * Builds a query pre-populated with the correct assignment grade id for a
   * user and assignment.
   * @param userId
   * @param assignmentId
   * @returns {Query<AssignmentGrade>}
   */
  static queryByUserAssignment(userId: number, assignmentId: number) {
    return this.query().whereId(this.getMapValue(userId, assignmentId));
  }

  /**
   * Gets an ID mapping, guaranteeing a return value of the ID or undefined.
   *
   * @param userId
   * @param assignmentId
   * @returns {*}
   */
  static getMapValue(userId: number, assignmentId: number) {
    const userObj = this.mapping[userId] || {};
    return userObj[assignmentId];
  }

  static updateMap(model: AssignmentGrade) {
    const {userId, assignmentId} = model;
    if (userId && assignmentId) {
      this.mapping[userId] = this.mapping[userId] || {};
      this.mapping[userId][assignmentId] = model.id;
    }
  }

  static deleteMapEntry(model: AssignmentGrade) {
    const {userId, assignmentId} = model;
    if (userId && assignmentId) {
      if (this.mapping[userId] && this.mapping[userId][assignmentId]) {
        delete this.mapping[userId][assignmentId];
      }
    }
  }
}

export default AssignmentGrade;
