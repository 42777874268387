import {IContainer} from '@/container/types/IContainer';
import {IResolvable} from '@/container/types/IResolvable';
import {symbolFor} from '@/container/utils/injection-keys';
import {IInjectOptions} from '@/container/types/IInjectOptions';
import {InjectKey} from 'vue/types/options';
import {resolveActiveContainer} from '@/container/container';

/**
 * Provide and return an instance of the requested injectable
 * @param injectable
 * @param args
 */
export function provide<T, FactoryArgs extends any[]>(
  injectable: IResolvable<T, FactoryArgs>,
  ...args: FactoryArgs
) {
  const injectionContext = resolveActiveContainer().provide<T, FactoryArgs>(injectable, ...args);

  return {
    inject() {
      return injectionContext.inject(injectable, ...args);
    },
  };
}

/**
 * Create an object that can be used in Vue's provide option
 * @param injectables an array of injectables to provide
 */
export function mapProvides(...injectables: IResolvable<any, []>[]): Record<symbol, any> {
  // Typescript doesn't allow symbols as index types, so we have to use any for now
  const result: Record<any, any> = {};
  for (const injectable of injectables) {
    result[symbolFor(injectable) as any] = inject(injectable);
  }
  return result as Record<symbol, any>;
}

/**
 * Inject a service using the active container
 * @param injectable
 * @param args
 */
export const inject: IContainer['inject'] = function <T, FactoryArgs extends any[]>(
  injectable: IResolvable<T, FactoryArgs>,
  ...args: FactoryArgs
): T {
  return resolveActiveContainer().inject<T, FactoryArgs>(injectable, ...args);
};

/**
 * Make an object of inject options to inject dependencies using Vue's inject keyword
 * @param mappings
 */
export function mapInjects<M extends Record<string, IResolvable<any, []>>>(
  mappings: M
): IInjectOptions<M> {
  const result: Record<string, any> = {};
  for (const key of Object.keys(mappings)) {
    result[key] = makeInjectOptionsValue(mappings[key]);
  }
  return result as IInjectOptions<M>;
}

/**
 * Makes the Vue inject options for a single injectable
 * @param injectable
 * @param args
 */
function makeInjectOptionsValue<T, FactoryArgs extends any[]>(
  injectable: IResolvable<T, FactoryArgs>,
  ...args: FactoryArgs
) {
  return {
    from: symbolFor(injectable) as InjectKey,
    default: () => inject(injectable, ...args),
  };
}
