import {ActionFactory} from '@/orm/api/actions';
import {
  createCourseRole,
  createCourseRoles,
  createCourseRolesByCsv,
  createCourseRolesByEmails,
  deleteCourseRole,
  deleteCourseRoles,
  getCourseRole,
  getCourseRoles,
  patchCourseRole,
  patchCourseRoles,
} from '@/users/api/course-roles';
import CourseRole from '@/users/models/CourseRole';

const factory = new ActionFactory(() => CourseRole);

export const get = factory.insertAction(getCourseRole);
export const fetch = factory.insertAction(getCourseRoles);
export const create = factory.insertAction(createCourseRole);
export const post = factory.insertAction(createCourseRole);
export const postMany = factory.insertAction(createCourseRoles);
export const postManyByEmail = factory.insertAction(createCourseRolesByEmails);
export const postManyByCsv = factory.insertAction(createCourseRolesByCsv);
export const patch = factory.insertAction(patchCourseRole);
export const patchMany = factory.insertAction(patchCourseRoles);
export const remove = factory.deleteAction(deleteCourseRole);
export const removeMany = factory.deleteAction(deleteCourseRoles);
