/**
 * Installs the loading flags plugin that provides a handler generator for
 * API requests
 * @param Vue
 * @param options
 */
import {LoadingFlagsService} from '@/loading/services/LoadingFlagsService';
import {inject} from '@/container';
import {DependencyType} from '@/container/types/DependencyType';

/*
 * Augment Vue type
 */
declare module 'vue/types/vue' {
  interface Vue {
    $loadingFlags: DependencyType<typeof LoadingFlagsService>;
  }
}

export default function install(Vue: any) {
  Vue.prototype.$loadingFlags = inject(LoadingFlagsService);
}
