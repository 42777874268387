import {VuexUserStorage} from './vuex';
import User from '../../models/User';

export class VuexORMUserStorage extends VuexUserStorage {
  async set(user) {
    await super.set(user);
    await User.insert({data: user});
    return this.get();
  }

  get() {
    const user = super.get();
    if (user !== null) {
      const id = user.id;
      return User.query().with('roles').with('courseRoles.role').find(id);
    }
    return null;
  }
}
