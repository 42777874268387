import {LateResponsePenaltyPolicyContent} from '@/assignment-policies/types/RawLateResponsePenaltyPolicy';
import {FlattenContent} from '@/orm/types/ContentObject';

export enum PolicyType {
  LateResponsePenaltyPolicy = 'LateResponsePenaltyPolicy',
}

export const SUPPORTED_POLICY_TYPES = [PolicyType.LateResponsePenaltyPolicy];

export const POLICY_TYPE_DESCRIPTION_KEYS = {
  [PolicyType.LateResponsePenaltyPolicy]: 'policyDescriptions.lateResponsePenalty',
};

export type NestedRawAssignmentPolicy<T extends PolicyType = PolicyType> = {
  id: number;
  type: T;
  name: string;
  courseLikeAssignmentId: number;
  content: RawAssignmentPolicyContentMap[T];
};

export type RawAssignmentPolicy<T extends PolicyType> = FlattenContent<
  NestedRawAssignmentPolicy<T>
>;

export type RawAssignmentPolicyContentMap = {
  [PolicyType.LateResponsePenaltyPolicy]: LateResponsePenaltyPolicyContent;
};
