import {alpha, confirmed, email, max, min, regex, required, integer} from 'vee-validate/dist/rules';
import {extend} from 'vee-validate';
import {useTranslate} from '@/locales/composables/i18n';
import {
  validateDate,
  validateDateRange,
  validateIso8601DateTime,
} from '@/form-validation/utils/dates';
import {validateIntegerId} from '@/form-validation/utils/entities';

const $t = useTranslate();

export const PASSWORD_REGEX = '^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$';
export const FIRST_NAME_REGEX = /^[a-zA-Z]+([\s-]?[a-zA-Z.])*$/;
export const LAST_NAME_REGEX = FIRST_NAME_REGEX;
export const PHONE_NUMBER_REGEX =
  /^(?:\+?\d{1,3}[\s-]?)?(?:\(\d{1,}\)[\s-]?)?(\d{1,}[\s-]?){4}$|^$/;

/**
 * Bootstrapping function for extending the vee-validate rules. This is called by the plugin.
 */
export const extendRules = () => {
  extend('required', {
    ...required,
    message: () => $t('formValidation.required') as string,
  });

  extend('regex', {
    ...regex,
    message: () => $t('formValidation.passwordRegex') as string,
  });

  extend('email', {
    ...email,
    message: () => $t('formValidation.email') as string,
  });

  extend('alpha', {
    ...alpha,
    message: () => $t('formValidation.alpha') as string,
  });

  extend('first_name', {
    validate(value: string): boolean {
      return FIRST_NAME_REGEX.test(value);
    },
    message: () => $t('formValidation.firstName') as string,
  });

  extend('last_name', {
    validate(value: string): boolean {
      return LAST_NAME_REGEX.test(value);
    },
    message: () => $t('formValidation.lastName') as string,
  });

  extend('phone_number', {
    validate(value: string): boolean {
      return new RegExp(PHONE_NUMBER_REGEX).test(value);
    },
    message: () => $t('formValidation.phoneNumber') as string,
  });

  extend('confirmed', {
    ...confirmed,
    message: () => $t('formValidation.confirmPassword') as string,
  });

  extend('min', {
    ...min,
    message: (_, values) => $t('formValidation.min', {count: values.length}) as string,
  });

  extend('min_value', {
    params: [{name: 'min', cast: (value) => Number(value)}],
    // @ts-ignore -- TS2339: validate() typing doesn't check params
    validate: (value, {min}) => value >= min,
    message: (_, {min}) => $t('formValidation.minValue', {min}) as string,
  });

  extend('max_value', {
    params: [{name: 'max', cast: (value) => Number(value)}],
    // @ts-ignore -- TS2339: validate() typing doesn't check params
    validate: (value, {max}) => value <= max,
    message: (_, {max}) => $t('formValidation.maxValue', {max}) as string,
  });

  extend('exclusive_min_value', {
    params: [{name: 'min', cast: (value) => Number(value)}],
    // @ts-ignore -- TS2339: validate() typing doesn't check params
    validate: (value, {min}) => value > min,
    message: (_, {min}) => $t('formValidation.exclusiveMinValue', {min}) as string,
  });

  extend('max', {
    ...max,
    message: (_, values) => $t('formValidation.max', {count: values.length}) as string,
  });

  extend('integer', {
    ...integer,
    message: () => $t('formValidation.integer') as string,
  });

  extend('exclusive_between', {
    params: [
      {name: 'min', cast: (value) => Number(value)},
      {name: 'max', cast: (value) => Number(value)},
    ],
    // @ts-ignore -- TS2339: validate() typing doesn't check params
    validate: (value, {min, max}) => min < value && max > value,
    message: (_, {min, max}) => $t('formValidation.exclusiveBetween', {min, max}) as string,
  });

  extend('date', {
    validate: validateDate,
    message: () => $t('formValidation.date') as string,
  });

  extend('iso8601_date', {
    validate: validateIso8601DateTime,
    message: () => $t('formValidation.date') as string,
  });

  extend('date_range', {
    validate: validateDateRange,
    message: () => $t('formValidation.dateRange') as string,
  });

  extend('required_integer_id', {
    validate: validateIntegerId,
    message: () => $t('formValidation.required') as string,
  });
};
