import {Fields} from '@vuex-orm/core';
import Grade from './Grade';
import AssignmentGrade from './AssignmentGrade';
import SectionGrade from './SectionGrade';
import AssignmentAverageGrade from './AssignmentAverageGrade';
import SectionAverageGrade from './SectionAverageGrade';
import {Joined} from '@/orm/types/Joined';
import * as GradeMatrixApi from '@/grades/api/orm/GradeMatrixApi';
import {StembleModel} from '@/common/models/StembleModel';

/**
 * GradeMatrix class
 *
 * A utility entity that serves to easily fetch and store many different types
 * of grade objects.
 *
 * The hasManyBy relationships are only to allow the API
 * to return a list of the respective entity and have Vuex ORM create the
 * instances of the entities automatically.
 */
export class GradeMatrix extends StembleModel {
  static entity = 'GradeMatrix';

  id!: number;
  taskGradeIds!: number[];
  taskGrades!: Joined<Grade[]>;
  assignmentGradeIds!: number[];
  assignmentGrades!: Joined<AssignmentGrade[]>;
  sectionGradeIds!: number[];
  sectionGrades!: Joined<SectionGrade[]>;
  assignmentAverageGradeIds!: number[];
  assignmentAverageGrades!: Joined<SectionAverageGrade[]>;
  sectionAverageGradeIds!: number[];
  sectionAverageGrades!: Joined<SectionAverageGrade[]>;

  static fields(): Fields {
    return {
      id: this.increment(),
      taskGradeIds: this.attr(null),
      taskGrades: this.hasManyBy(Grade, 'taskGradeIds'),
      assignmentGradeIds: this.attr(null),
      assignmentGrades: this.hasManyBy(AssignmentGrade, 'assignmentGradeIds'),
      sectionGradeIds: this.attr(null),
      sectionGrades: this.hasManyBy(SectionGrade, 'sectionGradeIds'),
      assignmentAverageGradeIds: this.attr(null),
      assignmentAverageGrades: this.hasManyBy(AssignmentAverageGrade, 'assignmentAverageGradeIds'),
      sectionAverageGradeIds: this.attr(null),
      sectionAverageGrades: this.hasManyBy(SectionAverageGrade, 'sectionAverageGradeIds'),
    };
  }

  static get api() {
    return GradeMatrixApi;
  }
}

export default GradeMatrix;
