import {ChemicalIsotopeValueDto} from '@/task-values/dtos/ChemicalIsotopeValueDto';
import {AbstractTaskValue} from '@/task-values/models/AbstractTaskValue';
import {RawIsotope} from '@/chemistry/types/RawIsotope';
import {Fields} from '@vuex-orm/core';

export class ChemicalIsotopeValue<K extends string | number = string>
  extends AbstractTaskValue<RawIsotope, K>
  implements ChemicalIsotopeValueDto
{
  static entity = 'ChemicalIsotopeValue';

  name!: string;
  massNumber!: number;
  mass!: string;
  abundance!: string;
  symbol!: string;
  atomicNumber!: number;
  neutrons!: number;
  electrons!: number;

  static fields(): Fields {
    return {
      ...super.fields(),
      name: this.string(''),
      massNumber: this.number(null),
      mass: this.string(''),
      abundance: this.string(''),
      symbol: this.string(''),
      atomicNumber: this.number(null),
      neutrons: this.number(null),
      electrons: this.number(null),
    };
  }

  getValue() {
    return this;
  }
}
