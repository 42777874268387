import {TaskScope} from '@/tasks/models/TaskScope';
import {InstitutionScopeDto} from '@/tasks/dtos/InstitutionScopeDto';
import Institution from '@/institutions/models/Institution';
import {Joined} from '@/orm/types/Joined';
import {Fields} from '@vuex-orm/core';

export class InstitutionScope extends TaskScope implements InstitutionScopeDto {
  static entity = 'InstitutionScope';

  institutionId!: number;
  institution!: Joined<Institution>;

  static fields(): Fields {
    return {
      ...super.fields(),
      institutionId: this.number(null),
      institution: this.belongsTo(Institution, 'institutionId'),
    };
  }
}
