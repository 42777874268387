import {HTTP} from '@/common/api/http';
import {API_BASE_URL} from '@/common/api/base';
import {IStembleAxiosPromise} from '@/common/api/types/IStembleAxiosPromise';
import {IGetEventsParams} from '@/events/types/IGetEventsParams';

export const EVENTS_BASE_URL = API_BASE_URL + '/events';

/**
 * Get a single event
 * @param id
 * @returns {IStembleAxiosPromise}
 */
export const getEvent = async (id: number): IStembleAxiosPromise => {
  return HTTP.get(`${EVENTS_BASE_URL}/${id}`);
};

/**
 * Get several section events
 * @returns {IStembleAxiosPromise}
 * @param params
 */
export const getEvents = async (params: IGetEventsParams = {}): IStembleAxiosPromise => {
  return HTTP.get(EVENTS_BASE_URL, {params});
};

/**
 * Create a new event
 * @param data
 * @returns {IStembleAxiosPromise}
 */
export const createEvent = async (data: any): IStembleAxiosPromise => {
  return HTTP.post(EVENTS_BASE_URL, data);
};

/**
 * Update an event
 * @param id
 * @param data
 * @returns {IStembleAxiosPromise}
 */
export const updateEvent = async (id: number, data: any): IStembleAxiosPromise => {
  return HTTP.put(`${EVENTS_BASE_URL}/${id}`, data);
};

/**
 * Update an event
 * @param id
 * @param data
 * @returns {IStembleAxiosPromise}
 */
export const patchEvent = async (id: number, data: any): IStembleAxiosPromise => {
  return HTTP.patch(`${EVENTS_BASE_URL}/${id}`, data);
};

/**
 * Delete an event
 * @param id
 * @returns {IStembleAxiosPromise}
 */
export const deleteEvent = async (id: number): IStembleAxiosPromise => {
  return HTTP.delete(`${EVENTS_BASE_URL}/${id}`);
};

export default {
  getEvent,
  getEvents,
  createEvent,
  updateEvent,
  deleteEvent,
  patchEvent,
};
