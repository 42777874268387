import {PluginFunction} from 'vue';
import {IconService} from '@/icons/services/IconService';
import {inject} from '@/container';

/*
 * Augment Vue type
 */
declare module 'vue/types/vue' {
  interface Vue {
    /**
     * Get an icon via its key
     */
    $i: IconService['resolve'];
  }
}

export const IconPlugin: PluginFunction<any> = (Vue) => {
  const iconService = inject(IconService.injectable);
  Vue.prototype.$i = iconService.resolve.bind(iconService);
};
