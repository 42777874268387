import User from '@/users/models/User';
import {ActionFactory} from '@/orm/api/actions';
import {getCurrentUser, getUser, getUsers, patchUser} from '@/users/api/users';
import {sendInvitationEmail as apiSendInvitationEmail} from '@/users/api/user-invitations';
import {getParticipantsFor} from '@/courses/api/courses';
import {updateEmail as apiUpdateEmail} from '@/common/api/settings';

const factory = new ActionFactory(() => User);

export const fetch = factory.insertAction(getUsers);
export const get = factory.insertAction(getUser);
export const patch = factory.insertAction(patchUser);
export const updateEmail = factory.insertAction(apiUpdateEmail);
export const sendInvitationEmail = factory.insertAction(apiSendInvitationEmail);
export const getParticipantsForCourse = factory.insertAction(getParticipantsFor);
export const getCurrent = factory.insertAction(getCurrentUser);
