


















import {defineComponent} from '@vue/composition-api';
import StembleCard from '@/common/components/StembleCard.vue';

export default defineComponent({
  name: 'LoginPagesCard',
  components: {StembleCard},
});
