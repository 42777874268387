import {Fields} from '@vuex-orm/core';
import User from '../../users/models/User';
import Assignment from './Assignment';
import * as AssignmentExtensionApi from '@/assignments/api/orm/AssignmentExtensionApi';
import {Joined} from '@/orm/types/Joined';
import {RawAssignmentExtension} from '@/assignments/types/RawAssignmentExtension';
import {StembleModel} from '@/common/models/StembleModel';

/**
 * AssignmentExtension class
 */
export class AssignmentExtension extends StembleModel implements RawAssignmentExtension {
  static entity = 'AssignmentExtension';

  id!: number;
  createdAt!: string;
  createdById!: number | null;
  createdBy!: Joined<User>;
  userId!: number;
  user!: Joined<User>;
  newStartDate!: string | null;
  newDueDate!: string | null;
  assignmentId!: number;
  assignment!: Joined<Assignment>;
  deletedAt!: string | null;

  static fields(): Fields {
    return {
      id: this.number(null),
      createdAt: this.string(''),
      createdById: this.number(null).nullable(),
      createdBy: this.belongsTo(User, 'createdById'),
      userId: this.number(null),
      user: this.belongsTo(User, 'userId'),
      newStartDate: this.string(null).nullable(),
      newDueDate: this.string(null).nullable(),
      assignmentId: this.number(null),
      assignment: this.belongsTo(Assignment, 'assignmentId'),
      deletedAt: this.string(null).nullable(),
    };
  }

  static get api() {
    return AssignmentExtensionApi;
  }

  static queryByAssignmentUser(assignmentId: number, userId: number) {
    return this.query()
      .where((item: AssignmentExtension) => {
        return item.assignmentId == assignmentId && item.userId == userId;
      })
      .orderBy('createdAt', 'desc');
  }

  get revoked() {
    return this.deletedAt !== null;
  }
}

export default AssignmentExtension;
