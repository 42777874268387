import {HTTP} from '@/common/api/http';
import {API_BASE_URL} from '@/common/api/base';
import {IStembleAxiosPromise} from '@/common/api/types/IStembleAxiosPromise';
import {IGetRolesParams} from '@/users/types/IGetRolesParams';

export const ROLES_BASE_URL = API_BASE_URL + '/roles';

/**
 * Get a single role by ID
 * @param {Number} id
 * @returns {IStembleAxiosPromise}
 */
export const getRole = async (id: number): IStembleAxiosPromise => {
  return HTTP.get(`${ROLES_BASE_URL}/${id}`);
};

/**
 * Get many roles
 * @returns {IStembleAxiosPromise}
 * @param params
 */
export const getRoles = async (params: IGetRolesParams = {}): IStembleAxiosPromise => {
  return HTTP.get(ROLES_BASE_URL, {params});
};

export default {
  getRole,
  getRoles,
};
