import moment from 'moment';
import {AxiosRequestConfig} from 'axios';

/**
 * Converts all boolean parameters in axios GET requests to integers.
 * @param {Object} config
 * @return {Object}
 */
export const boolsToInts = (config: AxiosRequestConfig): Record<string, any> => {
  if (!config.params) {
    return config;
  }

  const booleanParameters: Record<string, number> = {};
  for (const [k, v] of Object.entries(config.params)) {
    if (typeof v === 'boolean') {
      booleanParameters[k] = v ? 1 : 0;
    }
  }
  const params = {...config.params, ...booleanParameters};
  return {...config, params};
};

/**
 * Converts all moment parameters in GET requests to Date instances
 * @param {Object} config
 * @return {Object}
 */
export const momentsToDates = (config: AxiosRequestConfig) => {
  if (!config.params) {
    return config;
  }

  const dateParameters: Record<string, string> = {};
  for (const [k, v] of Object.entries(config.params)) {
    if (moment.isMoment(v)) {
      dateParameters[k] = v.toISOString();
    }
  }
  const params = {...config.params, ...dateParameters};
  return {...config, params};
};

/**
 * Response interceptor to pull out pagination meta-data
 * @param response
 * @return {*}
 */
export const extractMetaData = (response: any) => {
  response.meta = response.data.meta;
  response.links = response.data.links;
  return response;
};

/**
 * /**
 * Utility for ignoring HTTP statuses in catch handlers. If the error has a response and its
 * HTTP status is in the provided codes, simply returns true. Otherwise, it will throw the
 * exception.
 * @param {number[]} codes
 */
export const ignoreHttpStatusCodes = (codes: number[]) => (err: any) => {
  const codesSet = new Set(codes);
  if (err?.response?.status) {
    if (codesSet.has(err.response.status)) {
      return true;
    }
  }
  throw err;
};
