import GradePolicy from '@/grades/permissions/GradePolicy';
import CourseAdminPolicy from '@/courses/permissions/CourseAdminPolicy';
import CourseLikeAssignmentPolicy from '@/courses/permissions/CourseLikeAssignmentPolicy';
import SuperAdminPolicy from '@/super-admin/permissions/SuperAdminPolicy';
import AssignmentPolicy from '@/assignments/permissions/AssignmentPolicy';
import CourseLikePolicy from '@/courses/permissions/CourseLikePolicy';
import CoursePolicy from '@/courses/permissions/CoursePolicy';
import CourseRolePolicy from '@/users/permissions/CourseRolePolicy';
import {PaymentRequestPolicy} from '@/payments/permissions/PaymentRequestPolicy';
import {UserPolicy} from '@/users/permissions/UserPolicy';
import {inject, makeDependency} from '@/container';
import {PermissionsService} from '@/permissions/services/PermissionsService';
import CourseHierarchyServiceClass, {
  CourseHierarchyService,
} from '@/courses/services/CourseHierarchyServiceClass';

export const DefaultPolicies = makeDependency(() =>
  makeDefaultPolicies(inject(PermissionsService.injectable), inject(CourseHierarchyService))
);

function makeDefaultPolicies(
  permissionsService: PermissionsService,
  courseHierarchyService: CourseHierarchyServiceClass
) {
  return {
    Grade: new GradePolicy(permissionsService),
    CourseAdmin: new CourseAdminPolicy(permissionsService),
    CourseLikeAssignment: new CourseLikeAssignmentPolicy(
      permissionsService,
      courseHierarchyService
    ),
    SuperAdmin: new SuperAdminPolicy(permissionsService),
    Assignment: new AssignmentPolicy(permissionsService),
    CourseLike: new CourseLikePolicy(permissionsService),
    Course: new CoursePolicy(permissionsService),
    CourseRole: new CourseRolePolicy(permissionsService),
    PaymentRequest: new PaymentRequestPolicy(permissionsService),
    User: new UserPolicy(permissionsService),
  };
}
