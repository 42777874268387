import IsChildOf from './IsChildOf';

export class IsSectionOf extends IsChildOf {
  isSatisfiedBy(courseRole) {
    return (
      this.courseHierarchyService.isSection(courseRole.courseLikeId) &&
      super.isSatisfiedBy(courseRole)
    );
  }
}

export default IsSectionOf;
