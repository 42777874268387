import {Fields, Model, Query} from '@vuex-orm/core';
import Assignment from './Assignment';
import moment from 'moment';
import AssignmentPolicy from '../../assignment-policies/models/AssignmentPolicy';
import * as CourseLikeAssignmentApi from '@/assignments/api/orm/CourseLikeAssignmentApi';
import {ICourseLikeAssignmentQueryParameters} from '@/assignments/types/ICourseLikeAssignmentQueryParameters';
import {RawCourseLikeAssignment} from '@/assignments/types/RawCourseLikeAssignment';

/**
 * Linking entity for assigning assignments to a CourseLike
 */
export class CourseLikeAssignment
  extends Model
  implements Omit<RawCourseLikeAssignment, 'assignment' | 'assignmentPolicies'>
{
  static entity = 'CourseLikeAssignment';

  id!: number;
  startDate!: string;
  dueDate!: string;
  createdAt!: string;
  pointValue!: number;
  visibleDate!: string;
  courseLikeId!: number;
  courseId!: number;
  assignmentId!: number;
  assignment!: Assignment;
  assignmentPolicies!: AssignmentPolicy[];
  isQuizMode!: boolean;
  quizModeReleaseDate!: string | null;
  preventPastDueSubmission!: boolean;

  static fields(): Fields {
    return {
      id: this.number(null),
      startDate: this.string(null),
      dueDate: this.string(null),
      createdAt: this.string(null),
      pointValue: this.number(1),
      visibleDate: this.string(null),
      courseLikeId: this.number(null),
      courseId: this.number(null),
      assignmentId: this.number(null),
      assignment: this.belongsTo(Assignment, 'assignmentId'),
      assignmentPolicies: this.hasMany(AssignmentPolicy, 'courseLikeAssignmentId'),
      isQuizMode: this.boolean(false),
      quizModeReleaseDate: this.string(null).nullable(),
      preventPastDueSubmission: this.boolean(false),
    };
  }

  static get api() {
    return CourseLikeAssignmentApi;
  }

  static fullQuery(options: ICourseLikeAssignmentQueryParameters = {}) {
    let q = options.query ?? this.query();

    q = q.with('assignment', (q2) =>
      Assignment.fullQuery({
        ...options.assignmentQueryParams,
        query: q2 as Query<Assignment>,
      })
    );

    q = q.with('assignmentPolicies.content');

    return q;
  }

  get isVisible(): boolean {
    return moment(this.visibleDate).isSameOrBefore(moment());
  }

  get isAssignedToCourse(): boolean {
    return this.courseId === this.courseLikeId;
  }
}

export default CourseLikeAssignment;
