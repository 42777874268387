import {StembleModel} from '@/common/models/StembleModel';
import {TaskScopeType} from '@/tasks/types/TaskScopeType';
import {TaskScope} from '@/tasks/models/TaskScope';
import {Joined} from '@/orm/types/Joined';
import {Fields, Query} from '@vuex-orm/core';
import {NestedTaskScope} from '@/tasks/types/NestedTaskScope';

type FullQueryParams = {
  includeContent?: boolean;
  query?: Query<TaskScopeContent>;
};

export class TaskScopeContent<T extends TaskScopeType = TaskScopeType>
  extends StembleModel
  implements Omit<NestedTaskScope, 'content'>
{
  static entity = 'TaskScopeContent';

  id!: number;
  type!: T;
  taskId!: number;
  content!: Joined<TaskScope>;

  static fields(): Fields {
    return {
      id: this.number(null),
      type: this.string(null),
      taskId: this.number(null),
      content: this.morphTo('id', 'type'),
    };
  }

  static fullQuery(options: FullQueryParams = {}) {
    const q = options?.query ?? this.query();
    if (options.includeContent ?? true) {
      q.with('content');
    }
    return q;
  }
}
