import Specification from './Specification';

export class HasPermissionTo extends Specification {
  constructor(permission) {
    super();
    this.permission = permission;
  }

  isSatisfiedBy(courseRole) {
    return courseRole.hasPermissionTo(this.permission);
  }
}

export default HasPermissionTo;
