import Assignment from '@/assignments/models/Assignment';
import BaseGrade from './BaseGrade';
import {CourseSection} from '@/courses/models';
import {Joined} from '@/orm/types/Joined';
import {Fields} from '@vuex-orm/core';

/**
 * AssignmentAverageGrade class
 *
 * Represents a single assignment grade averaged over relevant users
 */
export class AssignmentAverageGrade extends BaseGrade {
  static entity = 'AssignmentAverageGrade';
  static mapping: {[id: number]: number} = {};

  id!: number;
  sectionId!: number | null;
  section!: Joined<CourseSection>;
  assignmentId!: number;
  assignment!: Joined<Assignment>;

  static fields(): Fields {
    return {
      ...super.fields(),
      id: this.increment(),
      sectionId: this.number(null).nullable(),
      section: this.belongsTo(CourseSection, 'sectionId'),
      assignmentId: this.number(null),
      assignment: this.belongsTo(Assignment, 'assignmentId'),
    };
  }

  /**
   * Builds a query pre-populated with the correct assignment grade id for an
   * assignment.
   * @param assignmentId
   * @returns {Query<AssignmentAverageGrade>}
   */
  static queryByAssignment(assignmentId: number) {
    return this.query().whereId(this.getMapValue(assignmentId));
  }

  /**
   * Gets an ID mapping, guaranteeing a return value of the ID or undefined.
   *
   * @param assignmentId
   * @returns {*}
   */
  static getMapValue(assignmentId: number) {
    return this.mapping[assignmentId];
  }

  // Lifecycle hooks
  static updateMap(model: AssignmentAverageGrade) {
    const {assignmentId} = model;
    this.mapping[assignmentId] = model.id;
  }

  static deleteMapEntry(model: AssignmentAverageGrade) {
    const {assignmentId} = model;
    delete this.mapping[assignmentId];
  }
}

export default AssignmentAverageGrade;
