import {Fields, Model, Query} from '@vuex-orm/core';
import TaskVariableValue from './TaskVariableValue';
import {Joined} from '@/orm/types/Joined';
import * as TaskStateApi from '@/task-states/api/orm/TaskStateApi';
import {ITaskStateQueryParameters} from '@/task-states/types/ITaskStateQueryParameters';
import {IVariableMapping} from '@/task-states/types/IVariableMapping';
import {RawTaskState} from '@/task-states/types/RawTaskState';

/**
 * TaskState class
 */
export class TaskState<VariableMapping extends IVariableMapping = IVariableMapping>
  extends Model
  implements Omit<RawTaskState<VariableMapping>, 'values' | 'taskValues' | 'taskValueIds'>
{
  static entity = 'TaskState';

  id!: number;
  createdAt!: string;
  taskId!: number;
  valueIds!: number[];
  values!: Joined<TaskVariableValue[]>;
  variableIds!: number[];
  symbols!: (keyof VariableMapping)[];

  static fields(): Fields {
    return {
      id: this.number(null),
      createdAt: this.string(null),
      taskId: this.number(null),
      valueIds: this.attr([]),
      values: this.hasManyBy(TaskVariableValue, 'valueIds'),
      variableIds: this.attr([]),
      symbols: this.attr([]),
    };
  }

  static get api() {
    return TaskStateApi;
  }

  /**
   * Query method to properly load an assignment
   * @returns {Query<TaskState>}
   * @param options
   */
  static fullQuery(options: ITaskStateQueryParameters = {}): Query<TaskState> {
    let q = options.query ?? this.query();

    if (options.values ?? true) {
      q = q.with('values', (query1) =>
        TaskVariableValue.fullQuery({query: query1 as Query<TaskVariableValue>})
      );
    }

    return q;
  }

  /**
   * Gets a variable's value by the variable's symbol
   * @param symbol
   * @returns {TaskVariableValue|null}
   */
  getValueBySymbol<S extends keyof VariableMapping>(
    symbol: S
  ): TaskVariableValue<VariableMapping[S]> | null {
    for (let i = 0; i < this.values.length; i++) {
      if (this.symbols[i] === symbol) {
        return this.values[i] as TaskVariableValue<VariableMapping[S]>;
      }
    }
    return null;
  }

  /**
   * Alias for getValueBySymbol
   * @param name
   * @returns {*}
   */
  getValueByName(name: keyof VariableMapping) {
    return this.getValueBySymbol(name);
  }

  getTaskVariableValue(variableId: number) {
    for (let i = 0; i < this.values.length; i++) {
      if (this.variableIds[i] == variableId) {
        return this.values[i];
      }
    }
    return null;
  }
}

export default TaskState;
