import {CourseHierarchyService} from '../services/CourseHierarchyServiceClass';
import {inject} from '@/container';

/**
 * Installs the stripe service plugin
 * @param Vue
 */
export default function install(Vue) {
  Vue.prototype.$courseHierarchy = inject(CourseHierarchyService);
}
