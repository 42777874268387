import {HTTP} from '@/common/api/http';
import {API_BASE_URL} from '@/common/api/base';
import {IStembleAxiosPromise} from '@/common/api/types/IStembleAxiosPromise';
import {IGetUsersParams} from '@/users/types/IGetUsersParams';
import {IGetUserParams} from '@/users/types/IGetUserParams';
import {IPatchUserParams} from '@/users/types/IPatchUserParams';

export const CONVERSATION_BASE_URL = API_BASE_URL + '/conversations';
export const CONVERSATIONS_BASE_URL = API_BASE_URL + '/conversations';

/**
 * Gets the current user
 * @param {boolean} loadRoles
 * @returns {IStembleAxiosPromise}
 */
export const getUserConversations = async (): IStembleAxiosPromise => {
  return HTTP.get(CONVERSATIONS_BASE_URL);
};

export const addNewConversation = async (userId: number): IStembleAxiosPromise => {
  return HTTP.post(CONVERSATIONS_BASE_URL, {receiver_id: userId});
};

export const getCurrentConversation = async (conversationId: number): IStembleAxiosPromise => {
  return HTTP.get(CONVERSATIONS_BASE_URL + '/' + conversationId);
};

export default {
  getUserConversations,
};
