/**
 * Base class for all Policies
 */
import User from '@/users/models/User';
import {PermissionsService} from '@/permissions/services/PermissionsService';

export abstract class Policy {
  protected permissionService: PermissionsService;

  constructor(permissionsService: PermissionsService) {
    this.permissionService = permissionsService;
  }

  /**
   * Method executed before all others on a model policy. If this method returns
   * any value that is not null or undefined, the truthiness determines the
   * authorization (truthy allows, falsy denies)
   * @param {User} user
   */
  // eslint-disable-next-line no-unused-vars
  before(user: User): boolean | null {
    return null;
  }
}
