import {Fields} from '@vuex-orm/core';
import {RawChemicalIsotopeValue} from '@/task-states/types/RawTaskVariableValue';
import {ChemicalIsotopeValue} from '@/task-values/models/ChemicalIsotopeValue';

/**
 * ChemicalIsotopeValue class
 */
export class LegacyChemicalIsotopeValue
  extends ChemicalIsotopeValue<number>
  implements RawChemicalIsotopeValue
{
  static entity = 'LegacyChemicalIsotopeValue';

  static fields(): Fields {
    return {
      ...super.fields(),
      id: this.number(null),
    };
  }
}

export default LegacyChemicalIsotopeValue;
