import {Policy} from '@/permissions/Policy';
import {
  IsChildOf,
  IsInheritedOrSectionWithin,
  IsOrIsAnyParentOf,
} from '@/permissions/services/permissions-service/specifications';
import {OrSpecification} from '@/permissions/services/permissions-service/specifications/OrSpecification';
import {IsAnyChildOf} from '@/permissions/services/permissions-service/specifications/IsAnyChildOf';
import {IsInSameCourseAs} from '@/permissions/services/permissions-service/specifications/IsInSameCourseAs';
import {User} from '@/users/models/User';
import {Ability} from '@/permissions/types/Ability';

export class CourseLikePolicy extends Policy {
  /**
   * Whether or not a user can create a course
   * @param user
   */
  create(user: User) {
    return this.permissionService.checkAnyCoursePermission(user, Ability.CreateCourses);
  }

  /**
   * Whether or not a user can update a given course
   * @param user
   * @param courseLikeId
   */
  update(user: User, courseLikeId: number) {
    return this.permissionService.checkAnyCoursePermission(
      user,
      Ability.UpdateCourses,
      new IsOrIsAnyParentOf(courseLikeId)
    );
  }

  /**
   * See if a user can create assignments for a specific CourseLike
   * @param {User} user
   * @param {Number} courseLikeId
   * @returns {*}
   */
  createAssignments(user: User, courseLikeId: number) {
    return user.can('create', 'Assignment', courseLikeId);
  }

  /**
   * See if a user can create assignments in a CourseLike
   * @param {User} user
   * @param {Number} courseLikeId
   * @returns {*}
   */
  createAssignmentsIn(user: User, courseLikeId: number) {
    return user.can('createIn', 'Assignment', courseLikeId);
  }

  /**
   * Permission to assign to a specific CourseLike
   * @param user
   * @param courseLikeId
   */
  assignAssignmentsTo(user: User, courseLikeId: number) {
    return this.permissionService.checkAnyCoursePermission(
      user,
      Ability.AssignCourseLikeAssignments,
      new IsOrIsAnyParentOf(courseLikeId)
    );
  }

  /**
   * Permission to assign somewhere in a given CourseLike
   * @param user
   * @param courseLikeId
   */
  assignAssignmentsIn(user: User, courseLikeId: number) {
    return this.permissionService.checkAnyCoursePermission(
      user,
      Ability.AssignCourseLikeAssignments,
      new IsInheritedOrSectionWithin(courseLikeId)
    );
  }

  viewParticipants(user: User, courseLikeId: number) {
    return user.checkAnyCoursePermission(
      Ability.ViewUserInformation,
      new IsOrIsAnyParentOf(courseLikeId)
    );
  }

  viewParticipantsPage(user: User, courseLikeId: number) {
    return this.permissionService.checkAnyCoursePermission(
      user,
      Ability.ViewCourseRosters,
      new IsInSameCourseAs(courseLikeId)
    );
  }

  viewGradesFor(user: User, courseLikeId: number) {
    return this.viewSomeGradesFor(user, courseLikeId);
  }

  sendUserInvitationsFor(user: User, courseLikeId: number) {
    return user.can('createIn', 'CourseRole', courseLikeId);
  }

  viewSomeGradesFor(user: User, courseLikeId: number) {
    return this.permissionService.checkAnyCoursePermission(
      user,
      Ability.ViewGrades,
      new IsInSameCourseAs(courseLikeId)
    );
  }

  submitGradesFor(user: User, courseLikeId: number) {
    return this.permissionService.checkAnyCoursePermission(
      user,
      Ability.SubmitGrades,
      new IsInSameCourseAs(courseLikeId)
    );
  }

  viewAnalyticsFor(user: User, courseLikeId: number) {
    return this.permissionService.checkAnyCoursePermission(
      user,
      Ability.ViewAnalytics,
      new IsOrIsAnyParentOf(courseLikeId)
    );
  }

  viewSomeAnalyticsFor(user: User, courseLikeId: number) {
    const spec = new OrSpecification(
      new IsOrIsAnyParentOf(courseLikeId),
      new IsChildOf(courseLikeId)
    );
    return this.permissionService.checkAnyCoursePermission(user, Ability.ViewAnalytics, spec);
  }

  grantExtensionsFor(user: User, courseLikeId: number) {
    return user.checkAnyCoursePermission(
      Ability.GrantAssignmentExtensions,
      new IsOrIsAnyParentOf(courseLikeId)
    );
  }

  viewCourseAdminFor(user: User, courseLikeId: number) {
    return user.checkAnyCoursePermission(
      Ability.ViewCourseAdmin,
      new OrSpecification(new IsOrIsAnyParentOf(courseLikeId), new IsAnyChildOf(courseLikeId))
    );
  }
}

export default CourseLikePolicy;
