import {HTTP} from '@/common/api/http';
import {API_BASE_URL} from '@/common/api/base';
import {IStembleAxiosPromise} from '@/common/api/types/IStembleAxiosPromise';
import {IGetCourseRolesParams} from '@/users/types/IGetCourseRolesParams';
import {IPatchCourseRoleParams} from '@/users/types/IPatchCourseRoleParams';
import {IGetCourseRoleParams} from '@/users/types/IGetCourseRoleParams';

export const ROLES_BASE_URL = API_BASE_URL + '/course-roles';

export const getCourseRole = async (
  id: number,
  params: IGetCourseRoleParams = {}
): IStembleAxiosPromise => {
  return HTTP.get(`${ROLES_BASE_URL}/${id}`, {params});
};

/**
 * Get many course roles
 * @returns {IStembleAxiosPromise}
 */
export const getCourseRoles = async (params: IGetCourseRolesParams = {}): IStembleAxiosPromise => {
  return HTTP.get(ROLES_BASE_URL, {params});
};

/**
 * Create a single course role
 * @param {Number} userId
 * @param {Number} courseLikeId
 * @param {Number} roleId
 * @returns {IStembleAxiosPromise}
 */
export const createCourseRole = async (
  userId: number,
  courseLikeId: number,
  roleId: number
): IStembleAxiosPromise => {
  return HTTP.post(ROLES_BASE_URL, {userId, courseLikeId, roleId});
};

/**
 * Create the same course role for an array of users
 * @param {Number|Number[]} userId
 * @param {Number} courseLikeId
 * @param {Number} roleId
 * @param {Boolean} active
 * @returns {IStembleAxiosPromise}
 */
export const createCourseRoles = async (
  userId: number | number[],
  courseLikeId: number,
  roleId: number,
  active: boolean = true
): IStembleAxiosPromise => {
  return HTTP.post(`${ROLES_BASE_URL}/bulk`, {
    userId,
    courseLikeId,
    roleId,
    active,
  });
};

/**
 * Create the same course role for an array of users and sections
 * @param {string|string[]} email
 * @param {Number| number[]} courseLikeId
 * @param {Number} roleId
 * @param {Boolean} active
 * @returns {IStembleAxiosPromise}
 */
export const createCourseRolesByEmails = async (
  email: string | string[],
  courseLikeId: number | number[],
  roleId: number,
  active: boolean = true
): IStembleAxiosPromise => {
  return HTTP.post(`${ROLES_BASE_URL}/bulk-by-email`, {
    email,
    courseLikeId,
    roleId,
    active,
  });
};

/**
 * Create the same course role for an array of users and sections from CSV
 * @param {File} csvFile
 * @param {number} courseId
 * @param {Number| number[]} courseLikeId
 * @param {Number} roleId
 * @param {Boolean} active
 * @returns {IStembleAxiosPromise}
 */
export const createCourseRolesByCsv = async (
  csvFile: File,
  courseId: number,
  courseLikeId: number | number[],
  roleId: number,
  active: boolean = true
): IStembleAxiosPromise => {
  const courseLikeIds = Array.isArray(courseLikeId) ? courseLikeId : [courseLikeId];
  const formData = new FormData();
  formData.append('csvFile', csvFile);
  formData.append('courseLikeId', JSON.stringify(courseLikeIds));
  formData.append('roleId', roleId.toString());
  formData.append('courseId', courseId.toString());
  formData.append('active', active ? '1' : '0');
  return HTTP.post(`${ROLES_BASE_URL}/bulk-by-csv`, formData, {
    headers: {'Content-Type': 'multipart/form-data'},
  });
};

/**
 * Patch a course role
 * @param {Number} id
 * @param data
 * @returns {IStembleAxiosPromise}
 */
export const patchCourseRole = async (
  id: number,
  data: IPatchCourseRoleParams
): IStembleAxiosPromise => {
  return HTTP.patch(`${ROLES_BASE_URL}/${id}`, data);
};

/**
 * Patches many course roles in the same way
 * @param {number[]} ids
 * @param data
 * @returns {IStembleAxiosPromise}
 */
export const patchCourseRoles = async (
  ids: number[],
  data: IPatchCourseRoleParams
): IStembleAxiosPromise => {
  return HTTP.patch(`${ROLES_BASE_URL}/bulk`, {...data, ids});
};

/**
 * Delete a course role
 * @param {number} id
 * @returns {IStembleAxiosPromise}
 */
export const deleteCourseRole = async (id: number): IStembleAxiosPromise =>
  HTTP.delete(`${ROLES_BASE_URL}/${id}`);

/**
 * Delete a set of course roles
 * @param {Array} ids
 * @returns {IStembleAxiosPromise}
 */
export const deleteCourseRoles = async (ids: number[]) => {
  const data = {ids};
  return HTTP.delete(ROLES_BASE_URL, {data});
};

export default {
  getCourseRole,
  getCourseRoles,
  patchCourseRole,
  patchCourseRoles,
  deleteCourseRole,
  deleteCourseRoles,
};
