import {ActionFactory, thenAction} from '@/orm/api/actions';
import {TaskResponse} from '@/tasks/models/TaskResponse';
import {
  getResponsesForUserAssignment,
  getResponsesForUserAssignmentTask,
  getTaskResponse,
  getTaskResponses,
  regradeResponse as regradeResponseApi,
  submitResponse as submitResponseApi,
  storeResponse as storeResponseApi,
  submitTryItReponse,
} from '@/tasks/api/task-responses';
import {IStembleAxiosResponse} from '@/common/api/types/IStembleAxiosResponse';

const factory = new ActionFactory(() => TaskResponse);

export const storeResponse = factory.insertAction(storeResponseApi);
export const submitResponse = factory.insertAction(submitResponseApi);
export const regradeResponse = factory.insertAction(regradeResponseApi);
export const tryItResponse = thenAction(submitTryItReponse, async (res: IStembleAxiosResponse) => {
  return res.data.data;
});

export const get = factory.insertAction(getTaskResponse);
export const fetch = factory.insertAction(getTaskResponses);
export const loadManyForUserOnAssignment = factory.insertAction(getResponsesForUserAssignment);
export const loadManyForUserOnTask = factory.insertAction(getResponsesForUserAssignmentTask);
