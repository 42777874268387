import {
  createLocation,
  deleteLocation,
  getLocation,
  getLocations,
  patchLocation,
  putLocation,
} from '@/locations/api/locations';
import {ActionFactory} from '@/orm/api/actions';
import InstitutionLocation from '@/locations/models/InstitutionLocation';

const factory = new ActionFactory(() => InstitutionLocation);

export const fetch = factory.insertAction(getLocations);
export const get = factory.insertAction(getLocation);
export const create = factory.insertAction(createLocation);
export const post = factory.insertAction(createLocation);
export const update = factory.insertAction(putLocation);
export const put = factory.insertAction(putLocation);
export const patch = factory.insertAction(patchLocation);
export const remove = factory.deleteAction(deleteLocation);
