import {HTTP} from '@/common/api/http';
import {API_BASE_URL} from '@/common/api/base';
import {IGetPaymentRequestsParams} from '@/payments/types/IGetPaymentRequestsParams';
import {IStembleAxiosPromise} from '@/common/api/types/IStembleAxiosPromise';
import {IGetPaymentRequestParams} from '@/payments/types/IGetPaymentRequestParams';
import {RawPaymentRequest} from '@/payments/types/RawPaymentRequest';

export const PAYMENT_REQUESTS_BASE_URL = `${API_BASE_URL}/payment-requests`;

/**
 * Get payment requests
 * @return {IStembleAxiosPromise}
 * @param params
 */
export const getPaymentRequests = async (
  params: IGetPaymentRequestsParams = {}
): IStembleAxiosPromise => {
  return HTTP.get(PAYMENT_REQUESTS_BASE_URL, {params});
};

/**
 * Get a single PaymentRequest
 * @param {Number} id
 * @param params
 * @return {IStembleAxiosPromise}
 */
export const getPaymentRequest = async (id: number, params: IGetPaymentRequestParams = {}) => {
  return HTTP.get(`${PAYMENT_REQUESTS_BASE_URL}/${id}`, {params});
};

/**
 * Update a PaymentRequest
 * @param {Number} id
 * @param data
 * @return {IStembleAxiosPromise}
 */
export const updatePaymentRequest = async (
  id: number,
  data: Pick<Partial<RawPaymentRequest>, 'hasPaid' | 'isExempt'> = {}
): IStembleAxiosPromise<RawPaymentRequest> => {
  return HTTP.patch(`${PAYMENT_REQUESTS_BASE_URL}/${id}`, data);
};

/**
 * Claim an access code to pay a PaymentRequest
 * @param id
 * @param accessCode
 */
export const payWithAccessCode = async (id: number, accessCode: string) => {
  return HTTP.post(`${PAYMENT_REQUESTS_BASE_URL}/${id}/access-code-payment`, {
    accessCode,
  });
};

/**
 * Get a Stripe checkout session for paying the given PaymentRequest
 * @param {Number} requestId
 * @returns {IStembleAxiosPromise}
 */
export const getCheckoutSession = async (requestId: number): IStembleAxiosPromise => {
  return HTTP.get(`${PAYMENT_REQUESTS_BASE_URL}/${requestId}/session`);
};

export default {
  getCheckoutSession,
  getPaymentRequests,
  getPaymentRequest,
};
