import {Fields} from '@vuex-orm/core';
import * as InstitutionApi from '@/institutions/api/orm/InstitutionApi';
import {StembleModel} from '@/common/models/StembleModel';
import {InstitutionDto} from '@/institutions/dtos/InstitutionDto';

/**
 * Institution class
 */
export class Institution extends StembleModel implements InstitutionDto {
  static entity = 'Institution';

  id!: number;
  name!: string;
  abbreviation!: string;

  static fields(): Fields {
    return {
      id: this.number(null),
      name: this.string(''),
      abbreviation: this.string(''),
    };
  }

  static get api() {
    return InstitutionApi;
  }
}

export default Institution;
