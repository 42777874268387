export function validateDate(value: any): boolean {
  if (typeof value !== 'string') {
    return false;
  }
  return /^\d{4}-\d{2}-\d{2}$/.test(value);
}

export function validateIso8601DateTime(value: any): boolean {
  if (typeof value !== 'string') {
    return false;
  }
  // https://stackoverflow.com/questions/12756159/regex-and-iso8601-formatted-datetime
  return /^([+-]?\d{4}(?!\d{2}\b))((-?)((0[1-9]|1[0-2])(\3([12]\d|0[1-9]|3[01]))?|W([0-4]\d|5[0-2])(-?[1-7])?|(00[1-9]|0[1-9]\d|[12]\d{2}|3([0-5]\d|6[1-6])))([T\s]((([01]\d|2[0-3])((:?)[0-5]\d)?|24:?00)([.,]\d+(?!:))?)?(\17[0-5]\d([.,]\d+)?)?([zZ]|([+-])([01]\d|2[0-3]):?([0-5]\d)?)?)?)?$/.test(
    value
  );
}

export function validateDateRange(value: any): boolean {
  if (!Array.isArray(value)) {
    return false;
  }
  return value.length === 2 && value.every(validateDate);
}
