import {IFactoryBindings} from '@/container/types/IFactoryBindings';
import {ISingletonBindings} from '@/container/types/ISingletonBindings';
import {IContainerBindings} from '@/container/types/IContainerBindings';
import {IFactoryFunction} from '@/container/types/IFactoryFunction';
import {IHasInjectionKey} from '@/container/types/IHasInjectionKey';
import {IResolvable} from '@/container/types/IResolvable';
import {symbolFor} from '@/container/utils/injection-keys';
import {IMakeContainerBindingsOptions} from '@/container/types/IMakeContainerBindingsOptions';

/**
 * Builds new container bindings
 */
export function makeContainerBindings(options?: IMakeContainerBindingsOptions): IContainerBindings {
  const parent = options?.parent;
  const factories: IFactoryBindings = options?.factories ?? {};
  const instances: ISingletonBindings = options?.instances ?? {};

  function resolve<T, FactoryArgs extends any[]>(
    service: IResolvable<T, FactoryArgs>,
    ...args: FactoryArgs
  ): T | undefined {
    const instance = resolveInstance(service);
    if (instance !== undefined) {
      return instance;
    }

    const factory = resolveFactory<T, FactoryArgs>(service);
    if (factory) {
      return factory(...args);
    }
  }

  function resolveInstance<T>(key: IHasInjectionKey<T>): T | undefined {
    return instances[symbolFor(key) as any] as T | undefined;
  }

  function resolveFactory<T, FactoryArgs extends any[]>(
    key: IHasInjectionKey<T>
  ): IFactoryFunction<T, FactoryArgs> | undefined {
    return factories[symbolFor(key) as any] as IFactoryFunction<T, FactoryArgs> | undefined;
  }

  function singleton<T>(service: IHasInjectionKey<T>, instance: T): void {
    instances[symbolFor(service) as any] = instance;
  }

  function factory<T, FactoryArgs extends any[]>(
    service: IResolvable<T, FactoryArgs>,
    factory: IFactoryFunction<T, FactoryArgs>
  ): void {
    factories[symbolFor(service) as any] = factory;
  }

  function clone() {
    return makeContainerBindings({
      instances: {...instances},
      factories: {...factories},
    });
  }

  return {
    parent,
    extend() {
      return makeContainerBindings({
        parent: this,
      });
    },
    clone,
    resolve,
    resolveInstance,
    resolveFactory,
    singleton,
    factory,
  };
}
