import {IAnalyticsPlugin} from './IAnalyticsPlugin';
import * as Sentry from '@sentry/vue';

export function sentryPlugin(): IAnalyticsPlugin {
  return {
    /* All plugins require a name */
    name: 'sentry-plugin',
    /**
     * Identify a visitor in Sentry
     * @link https://docs.sentry.io/platforms/javascript/enriching-events/identify-user/
     */
    identify: ({payload}) => {
      const {userId, traits} = payload;
      if (userId) {
        Sentry.setUser({
          id: userId,
          email: traits.email,
        });
      }
    },
    loaded: () => {
      return true;
    },
    reset: () => {
      Sentry.setUser(null);
    },
  };
}
