


























































import Teleport from 'vue2-teleport';
import AssignmentGrade from '@/grades/models/AssignmentGrade';
import {Course} from '@/courses/models';
import {computed, defineComponent, PropType, ref} from '@vue/composition-api';
import {useRoute} from '@/router/composables';

export default defineComponent({
  name: 'AssignmentGrade',
  components: {
    Teleport,
  },
  props: {
    grade: {
      type: Object as PropType<AssignmentGrade>,
      default: null,
    },
    course: {
      type: Object as PropType<Course>,
      default: null,
    },
    tooltip: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
  },
  setup(props) {
    const overlayOpen = ref(false);
    const notStarted = '-';
    const route = useRoute();
    const urlCourse = computed(() => {
      const courseId = route.params.courseId;
      return Course.find(courseId);
    });
    const course = ref<Course>(props.course ?? urlCourse);
    const currentGrade = computed(() => {
      return (props.grade.exactNumerator / props.grade.exactDenominator) * 100;
    });
    const originalGrade = computed(() => {
      return (props.grade.originalNumerator / props.grade.originalDenominator) * 100;
    });
    const pointsAvailable = props.grade?.pointsAvailable;
    const currentGradeDisplay = computed(() => {
      if (props.grade?.isEffective) {
        if (course.value?.gradeDisplay === 'PERCENTAGE') {
          return currentGrade.value.toFixed(1).replace('.0', '') + '%';
        }
        if (course.value?.gradeDisplay === 'FRACTION') {
          return `${Math.round(pointsAvailable * currentGrade.value) / 100}/${pointsAvailable}`;
        }
      }
      return notStarted;
    });

    const originalGradeDisplay = computed(() => {
      if (props.grade?.isEffective) {
        if (course.value?.gradeDisplay === 'PERCENTAGE') {
          return originalGrade.value.toFixed(1).replace('.0', '') + '%';
        }
        if (course.value?.gradeDisplay === 'FRACTION') {
          return `${Math.round(pointsAvailable * originalGrade.value) / 100}/${pointsAvailable}`;
        }
      }

      return notStarted;
    });
    const gradeAdjustment = computed(() => {
      // TODO: this assumes relative adjustments, so it will have to change if we do absolute
      if (!props.grade) {
        return notStarted;
      }
      return (currentGrade.value - originalGrade.value).toFixed(1).replace('.0', '') + '%';
    });
    const adjustment = computed(() => {
      if (props.grade?.gradeAdjustments) {
        return props.grade?.gradeAdjustments[0];
      }
      return null;
    });
    return {
      currentGrade,
      originalGrade,
      currentGradeDisplay,
      originalGradeDisplay,
      gradeAdjustment,
      adjustment,
      overlayOpen,
    };
  },
});
