import Question from './Question';
import {Fields} from '@vuex-orm/core';
import {RawWatchVideoTask} from '@/tasks/types/RawTask';

/**
 * WatchVideoTask class
 */
export class WatchVideoTask extends Question implements RawWatchVideoTask {
  static entity = 'WatchVideoTask';

  durationFraction!: number;

  static fields(): Fields {
    return {
      ...super.fields(),
      durationFraction: this.number(0.9),
    };
  }
}

export default WatchVideoTask;
