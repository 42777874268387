import {Model} from '@vuex-orm/core';

/**
 * VariableContent class
 */
export default class VariableContent extends Model {
  static entity = 'VariableContent';

  static fields() {
    return {
      id: this.number(null),
      variableId: this.number(null),
    };
  }
}
