import {
  copyAssignmentsForCourse,
  createAssignment,
  deleteAssignment,
  getAssignment,
  getAssignments,
  patchAssignment,
  putAssignment,
} from '@/assignments/api/assignments';
import {ActionFactory} from '@/orm/api/actions';
import Assignment from '@/assignments/models/Assignment';
import {
  NestedCreateAssignmentData,
  CreateAssignmentData,
} from '@/assignments/types/CreateAssignmentData';
import {ContentNestingService} from '@/orm/services/ContentNestingService';
import {inject} from '@/container';
import {IStembleAxiosPromise} from '@/common/api/types/IStembleAxiosPromise';
import {RawAssignment} from '@/assignments/types/RawAssignment';
import {
  CreateCourseLikeAssignmentData,
  NestedCreateCourseLikeAssignmentData,
} from '@/assignments/types/CreateCourseLikeAssignmentData';

const factory = new ActionFactory(() => Assignment);
const {flatten} = inject(ContentNestingService);

const _flattenPolicies = (cla: NestedCreateCourseLikeAssignmentData) => {
  const _cla: CreateCourseLikeAssignmentData = {
    ...cla,
    assignmentPolicies: [],
  };
  if (cla.assignmentPolicies) {
    _cla.assignmentPolicies = cla.assignmentPolicies.map(flatten);
  }
  return _cla;
};

export function flattenPoliciesForUpdates(
  fn: (id: number, data: CreateAssignmentData) => IStembleAxiosPromise<RawAssignment>
) {
  return (id: number, data: NestedCreateAssignmentData) => {
    const _data: CreateAssignmentData = {
      ...data,
      courseLikeAssignments: data.courseLikeAssignments?.map(_flattenPolicies),
    };
    return fn(id, _data);
  };
}

export function flattenPoliciesForCreate(
  fn: (data: CreateAssignmentData) => IStembleAxiosPromise<RawAssignment>
) {
  return (data: NestedCreateAssignmentData) => {
    const _data: CreateAssignmentData = {
      ...data,
      courseLikeAssignments: data.courseLikeAssignments?.map(_flattenPolicies),
    };
    return fn(_data);
  };
}

export const fetch = factory.insertAction(getAssignments);
export const get = factory.insertAction(getAssignment);
export const put = factory.insertAction(flattenPoliciesForUpdates(putAssignment));
export const update = put;
export const patch = factory.insertAction(flattenPoliciesForUpdates(patchAssignment));
export const create = factory.insertAction(flattenPoliciesForCreate(createAssignment));
export const post = create;
export const remove = factory.deleteAction(deleteAssignment);
export const copyForCourse = factory.insertAction(copyAssignmentsForCourse);
