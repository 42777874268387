import {Fields} from '@vuex-orm/core';
import {Course} from '@/courses/models';
import User from '@/users/models/User';
import {Joined} from '@/orm/types/Joined';
import * as PaymentRequestApi from '@/payments/api/orm/PaymentRequestApi';
import {IPaymentRequestQueryParameters} from '@/payments/types/IPaymentRequestQueryParameters';
import {RawPaymentRequest} from '@/payments/types/RawPaymentRequest';
import {StembleModel} from '@/common/models/StembleModel';
import moment from 'moment';

export class PaymentRequest extends StembleModel implements Omit<RawPaymentRequest, 'course'> {
  static entity = 'PaymentRequest';

  id!: number;
  courseId!: number;
  course!: Joined<Course>;
  createdAt!: string;
  userId!: number;
  user!: Joined<User>;
  dueDate!: string;
  effectiveDueDate!: string;
  hasPaid!: boolean;
  isExempt!: boolean;

  static fields(): Fields {
    return {
      id: this.number(null),
      courseId: this.number(null),
      course: this.belongsTo(Course, 'courseId'),
      createdAt: this.string(''),
      userId: this.number(null).nullable(),
      user: this.belongsTo(User, 'userId'),
      dueDate: this.string('').nullable(),
      effectiveDueDate: this.string(''),
      hasPaid: this.boolean(false),
      isExempt: this.boolean(false),
    };
  }

  static get api() {
    return PaymentRequestApi;
  }

  static fullQuery(options: IPaymentRequestQueryParameters = {}) {
    let q = options.query ?? this.query();

    if (options.course) {
      q = q.with('course');
    }

    if (options.hasPaid !== undefined) {
      q = q.where('hasPaid', options.hasPaid);
    }
    if (options.isExempt !== undefined) {
      q = q.where('isExempt', options.isExempt);
    }

    if (options.filterEndedCourses) {
      // @ts-ignore
      q = q.whereHas('course', (query) => {
        query.where('endDate', (value: string) => moment().isBefore(value));
      });
    }

    return q;
  }
}

export default PaymentRequest;
