import {FeatureFlag} from '@/feature-flags';

export function splitFeatureFlags(flagString: string): [FeatureFlag, string | null][] {
  const featuresSplit = flagString.split(',');
  return featuresSplit
    .filter((value) => value !== '')
    .map((value) => {
      const [featureFlag, variant] = value.split(':', 2);
      return [featureFlag as FeatureFlag, variant ? variant : null];
    });
}
