import {getEnvironment} from '@/environment';

export type AppVersionPayload = {
  version: string;
  pollInterval?: number;
};

/**
 * Get the front-end app's version
 * @return {Promise<AppVersionPayload>}
 */
export const getAppVersion = async (): Promise<AppVersionPayload> => {
  return {
    version: getEnvironment().buildSha,
  };
};
