import Event from './Event';
import EventSchedule from './EventSchedule';
import {Fields} from '@vuex-orm/core';
import * as ScheduleMetaEventApi from '@/events/api/orm/ScheduleMetaEventApi';

/**
 * ScheduleMetaEvent class
 */
export default class ScheduleMetaEvent extends Event {
  static entity = 'ScheduleMetaEvent';

  isMandatory!: boolean;
  // FIXME: not compatible. May need to subclass
  // owner!: Joined<EventSchedule>;
  position!: number;

  static fields(): Fields {
    return {
      ...super.fields(),
      isMandatory: this.boolean(false),
      owner: this.belongsTo(EventSchedule, 'ownerId'),
      position: this.number(null),
    };
  }

  static get api() {
    return ScheduleMetaEventApi;
  }
}
