import {
  getGradesForUserOnAssignment,
  getGradesForUserOnTask,
  getTaskGrades,
  getTotalGradesForSection,
  submitManualTaskGrades,
} from '@/grades/api/task-grades';
import {ActionFactory} from '@/orm/api/actions';
import Grade from '@/grades/models/Grade';

const factory = new ActionFactory(() => Grade);

export const fetch = factory.insertAction(getTaskGrades);
export const loadGradesForAssignment = factory.insertAction(getGradesForUserOnAssignment);
export const loadGradesForTask = factory.insertAction(getGradesForUserOnTask);
export const loadTotalGradesForSection = factory.insertAction(getTotalGradesForSection);
export const submitManualGrade = factory.insertAction(submitManualTaskGrades);
