import Analytics, {AnalyticsInstance} from 'analytics';
import Router from 'vue-router';
import User from '@/users/models/User';
import {AuthService} from '@/auth/services/authService';
import {ignoreHttpStatusCodes} from '@/common/utils/axios';
import {sentryPlugin} from '@/telemetry/plugins/vue-analytics/sentry-plugin';
import {getEnvironment} from '@/environment';
import {IVueAnalyticsPluginOptions} from '@/telemetry/plugins/vue-analytics/IVueAnalyticsPluginOptions';

/*
 * Augment Vue type
 */
declare module 'vue/types/vue' {
  interface Vue {
    $analytics: AnalyticsInstance;
  }
}

/**
 * Sets up the router hook that triggers page views when Vue Router changes
 * @param router
 * @param auth
 */
const setupRouter = (router: Router, auth: AuthService) => {
  router.afterEach(() => {
    // isUserAnonymous means 100% sure the user is anonymous (right after logout, 401 response, no token, etc.)
    // Otherwise, we assume the user is required and wait until the request succeeds
    if (!auth.isUserAnonymous) {
      auth.fetchCurrentUser().catch(ignoreHttpStatusCodes([401]));
    }
  });
};

/**
 * Installs the analytics plugin
 * @param {Vue} Vue
 * @param {IVueAnalyticsPluginOptions} options
 */
export default function install(
  Vue: Vue,
  {analyticsOptions, router, auth}: IVueAnalyticsPluginOptions
) {
  const plugins = analyticsOptions?.plugins ?? [];

  const env = getEnvironment();

  if (env.sentryDsn) {
    plugins.push(sentryPlugin());
  }

  const instance = Analytics({
    app: 'stemble',
    ...analyticsOptions,
    plugins,
  });

  // @ts-ignore
  Vue.prototype.$analytics = instance;

  if (router && auth) {
    setupRouter(router, auth);
  }

  if (auth) {
    auth.postUserLoad((user: User) => {
      // @ts-ignore FIXME: remove when model types are merged
      instance.identify(user.id.toString(), {email: user.email});
    });

    auth.postLogout(() => {
      instance.reset();
    });
  }
}
