import Gate from '@/permissions/Gate';
import {inject} from '@/container';
import {PluginObject} from 'vue';

const plugin: PluginObject<{userStorage?: any}> = {
  install(Vue, {userStorage} = {}) {
    Vue.prototype.$gate = inject(Gate.injectable, {userStorage});
  },
};

export default plugin;
