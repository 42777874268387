export default class AssignablesService {
  /**
   * @param {CourseHierarchyService} hierarchyService
   */
  constructor(hierarchyService) {
    this.hierarchyService = hierarchyService;
  }

  /**
   * Given a user and array of assigners, determine which are assigned to the user
   * @param {User} user
   * @param {Array} assigners
   * @returns {Array}
   */
  getAssignersAssignedTo(user, assigners) {
    const courseLikes = user.courseRoles.map((cr) => cr.courseLikeId);
    const validCourseLikes = this.getCourseLikesAssigningTo(courseLikes);
    return assigners.filter((assigner) => {
      return validCourseLikes.has(assigner.courseLikeId);
    });
  }

  /**
   * Given one or many courseLikeIds, returns the Set of courseLikeIds that would be assigned
   * the assignable.
   * @param {Number|Number[]} courseLikeIds
   * @returns {Set<Number>}
   */
  getCourseLikesAssignedBy(courseLikeIds) {
    courseLikeIds = Array.isArray(courseLikeIds) ? courseLikeIds : [courseLikeIds];
    courseLikeIds = new Set(courseLikeIds);
    const finalCourseLikeIds = new Set(courseLikeIds);
    for (const courseLikeId of courseLikeIds) {
      for (const sectionId of this.hierarchyService.getSectionIds(courseLikeId)) {
        finalCourseLikeIds.add(sectionId);
      }
    }
    return finalCourseLikeIds;
  }

  /**
   * Given one or more courseLikeIds, return a set of courseLikeIds that would assign to these
   * courseLikeIds.
   * @param courseLikeIds
   * @returns {Set<Number>}
   */
  getCourseLikesAssigningTo(courseLikeIds) {
    courseLikeIds = Array.isArray(courseLikeIds) ? courseLikeIds : [courseLikeIds];
    courseLikeIds = new Set(courseLikeIds);
    const finalCourseLikeIds = new Set(courseLikeIds);
    for (const courseLikeId of courseLikeIds) {
      const parentId = this.hierarchyService.getParentId(courseLikeId);
      if (parentId !== null && this.hierarchyService.isSection(courseLikeId)) {
        finalCourseLikeIds.add(parentId);
      }
    }
    return finalCourseLikeIds;
  }
}
