import HasPermissionTo from '@/permissions/services/permissions-service/specifications/HasPermissionTo';
import IsActive from '@/permissions/services/permissions-service/specifications/IsActive';
import AndSpecification from '@/permissions/services/permissions-service/specifications/AndSpecification';
import CourseHierarchyServiceClass, {
  CourseHierarchyService,
} from '@/courses/services/CourseHierarchyServiceClass';
import User from '@/users/models/User';
import CourseRole from '@/users/models/CourseRole';
import {Specification} from '@/permissions/services/permissions-service/specifications';
import {inject, makeGlobalSingleton} from '@/container';
import {Ability} from '@/permissions/types/Ability';

export class PermissionsService {
  static injectable = makeGlobalSingleton(() => {
    return new PermissionsService(inject(CourseHierarchyService));
  });

  protected courseHierarchyService: CourseHierarchyServiceClass;

  constructor(courseHierarchyService: CourseHierarchyServiceClass) {
    this.courseHierarchyService = courseHierarchyService;
  }

  protected getCourseRoles(user: User, active = null): CourseRole[] {
    const courseRoles = user.courseRoles;
    if (active !== null) {
      return courseRoles.filter((courseRole) => courseRole.isActive === active);
    }
    return courseRoles;
  }

  /**
   * Build permission specification
   * @param {string} permission
   * @param {Specification|null} spec
   * @param {boolean|null} active
   * @returns {HasPermissionTo}
   */
  protected buildPermissionSpec(
    permission: Ability,
    spec: Specification | null = null,
    active: boolean = true
  ): Specification {
    let finalSpec: Specification = new HasPermissionTo(permission);
    if (active !== null) {
      // TODO: this should likely be strict in the future
      finalSpec = new AndSpecification(finalSpec, new IsActive(active));
    }
    if (spec !== null) {
      finalSpec = new AndSpecification(finalSpec, spec);
    }
    return finalSpec;
  }

  checkAnyCoursePermission(
    user: User,
    permission: Ability,
    spec: Specification | null = null,
    active: boolean = true
  ): boolean {
    if (user.hasPermissionTo(permission)) {
      return true;
    }

    const finalSpec = this.buildPermissionSpec(permission, spec, active);
    return this.checkCourseRolesAgainstSpecification(user, finalSpec);
  }

  checkCourseRolesAgainstSpecification(user: User, spec: Specification): boolean {
    spec.setServices(this.courseHierarchyService);

    for (const courseRole of this.getCourseRoles(user)) {
      if (spec.isSatisfiedBy(courseRole)) {
        return true;
      }
    }
    return false;
  }
}
