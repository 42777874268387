<template>
  <div>
    <not-found v-if="!isConversationLoading && !conversationExists" />
    <div v-if="!isConversationLoading && conversationExists" class="conversation-wrapper">
      <div v-if="otherUserId && isFaculty && sectionIDs.length > 0" class="student-info-wrapper">
        <student-information
          :student-id="otherUserId"
          :course-like-id="sectionIDs"
          :in-page="true"
          :can-start-conversation="false"
        ></student-information>
        <div class="page-heading-background"></div>
      </div>
      <div class="conversation-column">
        <div class="conversation-header">
          <div style="flex: 1 1 auto">
            <div v-if="!isFaculty" class="instructor">
              <v-avatar class="instructor-avatar">
                <v-img :src="otherUser.avatarUrl" :alt="otherUser.firstName"></v-img>
              </v-avatar>
              <span class="instructor-name">{{
                otherUser.firstName + ' ' + otherUser.lastName
              }}</span>
            </div>
          </div>
          <button class="btn-back" @click="onReturnClick">
            <v-icon>mdi-arrow-left</v-icon>
            <span>All Conversations</span>
          </button>
        </div>
        <div class="conversation-pane">
          <div class="conversation-scroll">
            <template v-for="msg in messages">
              <div
                :key="msg.id"
                class="mt-3"
                :class="msg.sender.id === currentUserId ? 'd-flex flex-row-reverse' : 'd-flex'"
              >
                <div class="message" :class="msg.sender.id === currentUserId ? 'mine' : 'theirs'">
                  <div class="mb-1">{{ msg.content }}</div>
                  <div class="caption font-weight-bold" style="opacity: 0.5">
                    {{ currentDateTime(msg.timestamp) }}
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
        <div class="message-footer">
          <div class="message-input-wrapper gap-4">
            <v-text-field
              ref="messageInput"
              v-model="message"
              label="Type your message"
              type="text"
              no-details
              solo
              hide-details
              background-color="white"
              class="mr-4"
              @keyup.enter="message.length > 0 ? addNewMessage() : ''"
            />
            <v-btn class="btn-submit" height="48" color="primary" @click="addNewMessage()">
              Send
              <v-icon class="ml-3" style="opacity: 0.7">mdi-send</v-icon>
            </v-btn>
          </div>
          <div v-show="isFaculty">
            <button
              class="open-smart-response"
              :disabled="messages.length === 0"
              @click="smartResponseOpen = true"
            >
              <v-icon class="smart-response-icon">mdi-auto-fix</v-icon>
              <span>Generate Smart Response</span>
            </button>
            <Teleport to=".v-application">
              <div
                class="modal-overlay"
                :class="smartResponseOpen ? 'open' : ''"
                :tabindex="smartResponseOpen ? 0 : -1"
              >
                <div class="modal-wrapper">
                  <div class="smart-response-modal">
                    <p class="smart-response-description">
                      Select a course to generate a smart response.
                    </p>
                    <v-select
                      v-model="selectedCourse"
                      label="Course"
                      color="secondary"
                      :disabled="generatingSmartResponse"
                      :loading="isSubmitting"
                      :items="filteredCoursesName"
                    ></v-select>
                    <div class="modal-actions">
                      <v-btn
                        color="secondary"
                        rounded
                        :disabled="generatingSmartResponse"
                        @click="generateSmartResponse(selectedCourse)"
                      >
                        <span class="generate-button">
                          <v-icon
                            v-if="generatingSmartResponse"
                            class="smart-response-icon smart-response-icon-generating"
                            >mdi-loading</v-icon
                          >
                          <v-icon v-else class="smart-response-icon">mdi-auto-fix</v-icon>
                          <span>Generate</span></span
                        >
                      </v-btn>
                    </div>
                  </div>
                </div>
                <div class="overlay-background" @click="smartResponseOpen = false"></div>
              </div>
            </Teleport>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {MESSAGES} from '@/router/route-names';
import Teleport from 'vue2-teleport';
import {useAsyncState} from '@vueuse/core';
import {
  createConversationMessage,
  getConversationMessages,
  smartResponse,
} from '@/living-syllabus/api/messages';
import {computed, ref, watch, watchEffect} from '@vue/composition-api';
import {getCurrentConversation} from '@/living-syllabus/api/conversations';
import StudentInformation from '@/components/student-information/StudentInformation.vue';
import {getCourseRoles} from '@/users/api/course-roles';
import NotFound from '@/errors/views/NotFound.vue';
import {useAuthService} from '@/auth/services/authService';
import {useRoute} from '@/router/composables';
import {LoadingFlag} from '@/loading/types/LoadingFlags';
import {getCoursesForUser} from '@/courses/api/courses';

export default {
  name: 'Conversation',
  components: {NotFound, Teleport, StudentInformation},
  props: {
    id: Number,
    userId: Number,
    courseId: Number,
  },
  computed: {
    isSubmitting() {
      return this.$loadingFlags.isLoading(LoadingFlag.LivingSyllabusSubmittingAnswer);
    },
  },
  methods: {
    onReturnClick() {
      this.$router.push({name: MESSAGES});
    },
  },
  setup(props) {
    const route = useRoute();
    const otherUserId = ref(parseInt(route.params.userId, 10));
    const auth = useAuthService();
    const conversationExists = ref(true);
    const today = ref('');
    const activeChat = ref(1);
    const message = ref('');
    const selectedCourse = ref('');

    const conversation = ref({});
    const courses = ref([]);
    const {state: conversationRequest, isLoading: isConversationLoading} = useAsyncState(
      getCurrentConversation(props.id)
        .then((response) => response.data.data)
        .catch(() => (conversationExists.value = false)),
      []
    );

    watch(conversationRequest, (currentConversation) => {
      conversation.value = currentConversation;
    });

    const messages = ref([]);

    const {state: messagesRequest} = useAsyncState(
      getConversationMessages(props.id).then((response) => response.data.data),
      []
    );

    const currentUserId = computed(() => {
      return auth.user.id;
    });

    const isFaculty = computed(() => {
      return auth.user.isFaculty();
    });

    const otherUser = computed(() => {
      if (!conversation.value.participants) {
        return {
          avatarUrl: '',
          firstName: '',
          lastName: '',
        };
      }

      return conversation.value.participants.find((user) => user.id !== currentUserId.value);
    });

    async function getUserCourses(userId) {
      const courses = await getCoursesForUser(userId, {
        checkSections: true,
        active: true,
      }).then((res) => res.data.data);

      const courseIds = courses?.map((c) => c.courseId);

      return getCoursesForUser(userId, {
        checkSections: true,
        active: true,
        id: courseIds,
      }).then((res) => res.data.data);
    }

    async function getCourseSections(userId) {
      const courseRoles = await getCourseRoles({
        user: userId,
        active: true,
      }).then((res) => res.data.data);

      return courseRoles?.map(({courseLikeId}) => courseLikeId);
    }

    const {state: sectionIDsOtherUser} = useAsyncState(getCourseSections(otherUserId.value), []);
    const {state: sectionIDsUser} = useAsyncState(getCourseSections(props.userId), []);
    const {state: coursesOtherUser} = useAsyncState(getUserCourses(otherUserId.value), []);

    watch(messagesRequest, (newMessages) => {
      messages.value = newMessages;
    });

    const filteredCourses = ref([]);
    const filteredCoursesName = computed(() => filteredCourses.value.map((obj) => obj.name));

    watchEffect(() => {
      filteredCourses.value = coursesOtherUser.value.filter((course) =>
        course.sections.some((section) => sectionIDsUser.value.includes(section.id))
      );
    });

    async function aiAnswer(messages, courseName) {
      let aiJson = {
        courseNumber: filteredCourses.value.find((course) => course.name === courseName).id,
        conversation: messages,
      };

      const messagesFromSender = messages.some(
        (message) => message.sender.id === otherUserId.value
      );
      if (messagesFromSender) {
        const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const response = await smartResponse(props.id, aiJson, userTimezone);
        message.value = response.data.message;
      }
    }

    function addNewMessage() {
      if (!message?.value?.length) {
        return;
      }
      const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      createConversationMessage(this.id, this.message, userTimezone, this.courseId).then((t) => {
        this.messages.push(t.data.data);
        setTimeout(() => (this.$refs.scrollView.scrollTop = this.$refs.scrollView.scrollHeight), 0);
        this.message = '';
        this.$refs.messageInput.focus();
      });
    }

    function currentDateTime(timestamp) {
      const current = new Date(timestamp.date);
      const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ];

      const time =
        current.getHours() +
        ':' +
        (current.getMinutes() < 10 ? '0' + current.getMinutes() : current.getMinutes());
      return months[current.getMonth()] + ' ' + current.getDate() + ', ' + time;
    }

    const smartResponseOpen = ref(false);
    const generatingSmartResponse = ref(false);
    const generateSmartResponse = async (selectedCourse) => {
      generatingSmartResponse.value = true;
      if (messages.value.length > 0) {
        await aiAnswer(messages.value, selectedCourse);
      } else {
        // error
      }

      setTimeout(() => {
        generatingSmartResponse.value = false;
        smartResponseOpen.value = false;
      }, 1000);
    };

    watch(otherUser, () => {
      document.title = `Conversation with ${otherUser.value.firstName} ${otherUser.value.lastName} | Stemble`;
    });

    return {
      otherUser,
      messages,
      conversation,
      sectionIDs: sectionIDsOtherUser,
      filteredCoursesName,
      currentUserId,
      otherUserId,
      isFaculty,
      conversationExists,
      isConversationLoading,
      courses,
      smartResponseOpen,
      generatingSmartResponse,
      generateSmartResponse,
      addNewMessage,
      currentDateTime,
      today,
      activeChat,
      message,
      selectedCourse,
    };
  },
};
</script>

<style scoped>
.conversation-wrapper {
  width: 100%;
  padding: 1rem 2rem 3rem 2rem;
  display: flex;
  flex-direction: column-reverse;
}

.page-heading-background {
  background: #f7f7f7;
  position: absolute;
  /* Height of Student avatar + padding */
  top: calc(130px + 2.5rem);
  left: 0;
  width: 200vw;
  height: 100vh;
  transform: translate3d(-50%, -100%, 0);
  z-index: -1;
}

@media (min-width: 1215px) {
  .conversation-wrapper {
    flex-direction: row;
    min-height: 600px;
    height: calc(100% - 4rem);
    align-items: stretch;
    justify-content: center;
  }
}

.student-info-wrapper {
  position: relative;
  z-index: 1;
  width: 100%;
  min-width: 550px;
  padding-top: 0.125rem;
}

@media (min-width: 1215px) {
  .student-info-wrapper {
    padding-right: 2rem;
    flex: 0 0 550px;
  }
}

.conversation-column {
  width: 100%;
  flex: 1 1 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  max-width: 768px;
  position: relative;
  z-index: 1;
}

.conversation-header {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.75rem;
  flex: 0;
}

.btn-back {
  color: #3375b8;
  display: flex;
  align-items: center;
  background: none;
  border: none;
  padding: 0.5rem 0;
}

.btn-back span {
  font-size: 1.125rem;
  letter-spacing: 0.0125em;
  text-decoration: underline;
  text-decoration-color: rgba(91, 135, 181, 0.3);
}

.btn-back .v-icon {
  margin-right: 0.5rem;
  text-decoration: none;
  opacity: 0.7;
}

.btn-back .v-icon:before {
  color: #3375b8;
}

.conversation-pane {
  flex: 1 1 0;
  min-height: 350px;
  overflow-y: auto;
  background: #f7f7f7;
  box-shadow:
    inset 0 0 0 1px rgba(147, 127, 116, 0.13),
    0px 1px 2px 0px rgba(18, 11, 40, 0.05),
    0px 4px 14px 0px rgba(46, 36, 81, 0.07);
  border-radius: 0.75rem;
  display: flex;
  flex-direction: column-reverse;
}

.conversation-scroll {
  height: auto;
  min-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 1rem 1.5rem 1.5rem 1.5rem;
}

.message-footer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.75rem;
  padding: 1rem 0 0 0;
}

.open-smart-response {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  color: #3375b8;
  font-size: 1rem;
  gap: 0.375rem;
  font-weight: 500;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 100ms ease-out;
}

.open-smart-response:hover {
  opacity: 1;
}

.open-smart-response .smart-response-icon {
  color: #3375b8;
  font-size: 1.25rem;
  margin-top: -0.1em;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.smart-response-icon-generating {
  position: relative;
  transform-origin: 50% 50%;
  animation: spin 1.25s infinite linear;
}

.message-input-wrapper {
  display: flex;
  flex: 0 0 auto;
  padding: 0;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  position: relative;
  bottom: 0;
}

.message {
  padding: 0.625rem 1rem;
  box-shadow:
    inset 0 0 0 1px rgba(147, 127, 116, 0.13),
    0px 1px 2px 0px rgba(18, 11, 40, 0.05),
    0px 4px 14px 0px rgba(46, 36, 81, 0.07);
  border-radius: 0.5rem;
  min-width: 120px;
}

.message.mine {
  background: #3375b8;
  color: #ffffff;
}

.message.theirs {
  background: #fff;
  color: #3375b8;
}

#chatTitle {
  border-bottom: 1px solid #9e9e9e;
}

.btn-submit {
  margin: 0 !important;
}

.instructor {
  display: flex;
  align-items: center;
}

.instructor-avatar {
  border: 2px solid white;
  box-shadow: 0 2px 8px rgba(33, 33, 33, 0.1);
  margin-right: 0.75rem;
  margin-left: -0.125rem;
}

.instructor-name {
  color: #4676aa;
  font-family: Roboto;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.modal-overlay {
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  opacity: 0;
  transition: all ease-out 150ms;
  font-family: 'Roboto', sans-serif;
}

.modal-overlay.open {
  pointer-events: all;
  opacity: 1;
}

.modal-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.smart-response-modal {
  text-align: left;
  line-height: 1.5;
  background: white;
  color: #262626;
  padding: 1rem 1.25rem;
  border-radius: 0.5rem;
  box-shadow:
    inset 0 0 0 1px rgba(147, 127, 116, 0.13),
    0px 1px 2px 0px rgba(18, 11, 40, 0.05),
    1px 4px 14px 0px rgba(44, 32, 80, 0.06);
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-width: 50rem;
}

.smart-response-description {
  font-size: 1.125rem;
}

.modal-overlay.open .smart-response-modal {
  pointer-events: all;
}

@media (min-width: 768px) {
  .smart-response-modal {
    padding: 1.75rem 2rem;
  }
}

.modal-actions {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.modal-actions button {
  margin: 0 !important;
}

.overlay-background {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0.7;
}

.generate-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
</style>
