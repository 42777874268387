import {ISendRequestFunction} from '@/orm/types/ISendRequestFunction';
import {Model} from '@vuex-orm/core';

type ExtractPromiseType<T> = T extends Promise<infer R> ? R : T;

/**
 * Build an action where the result is put through another function
 * @param action
 * @param thenFunction
 */
export function thenAction<
  A extends ISendRequestFunction,
  ThenFunction extends (res: ExtractPromiseType<ReturnType<A>>) => Promise<any>,
>(action: A, thenFunction: ThenFunction) {
  return async (...args: Parameters<A>) =>
    action(...args).then(thenFunction) as Promise<ExtractPromiseType<ReturnType<ThenFunction>>>;
}

export class ActionFactory<ModelAccessor extends () => typeof Model> {
  modelGetter: ModelAccessor;

  constructor(model: ModelAccessor) {
    this.modelGetter = model;
  }

  insertAction<A extends ISendRequestFunction>(apiFunction: A) {
    return async (...args: Parameters<A>) =>
      apiFunction(...args).then((res: any) => {
        return this.modelGetter().insert({data: res.data.data});
      });
  }

  updateAction<A extends ISendRequestFunction>(apiFunction: A) {
    return async (...args: Parameters<A>) =>
      apiFunction(...args).then((res: any) => {
        return this.modelGetter().update({data: res.data.data});
      });
  }

  insertOrUpdateAction<A extends ISendRequestFunction>(apiFunction: A) {
    return async (...args: Parameters<A>) =>
      apiFunction(...args).then((res: any) => {
        return this.modelGetter().insertOrUpdate({data: res.data.data});
      });
  }

  deleteAction<A extends ISendRequestFunction>(apiFunction: A) {
    return async (...args: Parameters<A>) =>
      apiFunction(...args).then((res: any) => {
        this.deleteHandler(res.data.data.id);
      });
  }

  batchAction<A extends ISendRequestFunction>(apiFunction: A) {
    return async (...args: Parameters<A>) =>
      apiFunction(...args).then((res: any) => {
        const batchResponses = res.data.data;
        for (const batchResponse of batchResponses) {
          switch (batchResponse.method) {
            case 'GET':
            case 'POST':
            case 'PUT':
            case 'PATCH':
              this.modelGetter().insert({data: batchResponse.resource});
              break;
            case 'DELETE':
              this.deleteHandler(batchResponse.resource.id);
              break;
          }
        }
        return res;
      });
  }

  private deleteHandler(ids: any) {
    if (ids instanceof Array) {
      ids.map((id) => this.modelGetter().delete(id));
    } else {
      this.modelGetter().delete(ids);
    }
  }
}
