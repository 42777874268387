import {IMakeServiceOptions} from '@/container/types/IMakeServiceOptions';
import {IService} from '@/container/types/IService';
import {InjectionKey} from '@/container/types/InjectionKey';
import {IFactoryFunction} from '@/container/types/IFactoryFunction';
import {IClassDependency} from '@/container/types/IClassDependency';
import {IResolvable} from '@/container/types/IResolvable';

export const DEFAULT_GLOBAL = false;
export const DEFAULT_SINGLETON = false;

/**
 * Build a new service object
 * @param factory
 * @param extraOptions
 */
export function makeDependency<T, FactoryArgs extends any[]>(
  factory: IFactoryFunction<T, FactoryArgs>,
  extraOptions?: IMakeServiceOptions<T>
): IService<T, FactoryArgs> {
  const symbol: InjectionKey<T> = extraOptions?.symbol ?? Symbol();

  return {
    symbol,
    factory,
    singleton: extraOptions?.singleton ?? DEFAULT_SINGLETON,
    global: extraOptions?.global ?? DEFAULT_GLOBAL,
  };
}

/**
 *
 * @param cls
 * @param extraOptions
 */
export function makeClassDependency<C extends IClassDependency<ReturnType<C['factory']>>>(
  cls: C,
  extraOptions?: IMakeServiceOptions<ReturnType<C['factory']>>
): C & IService<ReturnType<C['factory']>, Parameters<C['factory']>> {
  cls.symbol = (cls?.symbol ?? Symbol()) as InjectionKey<ReturnType<C['factory']>>;
  cls.singleton = cls?.singleton ?? extraOptions?.singleton ?? DEFAULT_SINGLETON;
  cls.global = cls?.global ?? extraOptions?.global ?? DEFAULT_GLOBAL;

  // TS doesn't understand that singleton and global have been definitely set
  // so cast it after doing the assignments
  return cls as C & IService<ReturnType<C['factory']>, Parameters<C['factory']>>;
}

/**
 * Create a global service
 * @param fn
 * @param extraOptions
 */
export function makeGlobalDependency<T, Args extends any[]>(
  fn: IFactoryFunction<T, Args>,
  extraOptions?: IMakeServiceOptions<T>
) {
  return makeDependency(fn, {
    ...extraOptions,
    global: true,
    singleton: false,
  });
}

/**
 * Create a global singleton object
 * @param fn
 * @param extraOptions
 */
export function makeGlobalSingleton<T, Args extends any[]>(
  fn: IFactoryFunction<T, Args>,
  extraOptions?: IMakeServiceOptions<T>
) {
  return makeDependency(fn, {
    ...extraOptions,
    global: true,
    singleton: true,
  });
}

/**
 * Make a singleton dependency
 * @param fn
 * @param extraOptions
 */
export function makeSingleton<T, Args extends any[]>(
  fn: IFactoryFunction<T, Args>,
  extraOptions?: IMakeServiceOptions<T>
) {
  return makeDependency(fn, {
    ...extraOptions,
    global: false,
    singleton: true,
  });
}
