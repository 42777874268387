import {AbstractDistribution} from '@/distributions/models/AbstractDistribution';
import {BooleanValue} from '@/task-values/models/BooleanValue';
import {BooleanDistributionDto} from '@/distributions/dtos/BooleanDistributionDto';

export class BooleanDistribution
  extends AbstractDistribution<BooleanValue>
  implements BooleanDistributionDto
{
  static entity = 'BooleanDistribution';
}
