import {RawAdjustment} from '@/assignment-policies/types/RawAdjustment';
import {RawFrequency} from '@/assignment-policies/types/RawFrequency';
import {Content, ContentKeys} from '@/orm/types/ContentObject';
import {
  NestedRawAssignmentPolicy,
  PolicyType,
  RawAssignmentPolicy,
} from '@/assignment-policies/types/RawAssignmentPolicy';

export const LateResponsePenaltyPolicyContentKeys: ContentKeys<LateResponsePenaltyPolicyContent> = [
  'submissionCutoff',
  'penalty',
  'frequency',
];

export type LateResponsePenaltyPolicyContent = Content<{
  submissionCutoff: string | null;
  penalty: RawAdjustment;
  frequency: RawFrequency;
}>;

export type RawLateResponsePenaltyPolicy =
  RawAssignmentPolicy<PolicyType.LateResponsePenaltyPolicy>;

export type NestedRawLateResponsePenaltyPolicy =
  NestedRawAssignmentPolicy<PolicyType.LateResponsePenaltyPolicy>;
