import Question from './Question';
import {Fields} from '@vuex-orm/core';
import {RawDataDrivenTask} from '@/tasks/types/RawTask';
import {RawTaskComponent} from '@/tasks/types/RawTaskComponent';

/**
 * DataDrivenTask class
 */
export class DataDrivenTask extends Question implements RawDataDrivenTask {
  static entity = 'DataDrivenTask';

  questionId!: string;
  uiConfigs!: RawTaskComponent[];

  static fields(): Fields {
    return {
      ...super.fields(),
      questionId: this.string(''),
      uiConfigs: this.attr([]),
    };
  }
}
