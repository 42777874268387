import User from '@/users/models/User';
import BaseGrade from './BaseGrade';
import {CourseSection} from '@/courses/models';
import {Joined} from '@/orm/types/Joined';
import {Fields} from '@vuex-orm/core';

/**
 * SectionGrade class
 *
 * Represents a user's grade in a section.
 */
export class SectionGrade extends BaseGrade {
  static entity = 'SectionGrade';
  static mapping: {[userId: number]: {[sectionId: number]: number}} = {};

  id!: number;
  userId!: number;
  user!: Joined<User>;
  sectionId!: number;
  section!: Joined<CourseSection>;

  static fields(): Fields {
    return {
      ...super.fields(),
      id: this.increment(),
      userId: this.number(null),
      user: this.belongsTo(User, 'userId'),
      sectionId: this.number(null),
      section: this.belongsTo(CourseSection, 'sectionId'),
    };
  }

  /**
   * Builds a query pre-populated with the correct userId
   * @param userId
   * @param sectionId
   * @returns {Query<SectionGrade>}
   */
  static queryForUser(userId: number, sectionId: number) {
    return this.query().whereId(this.getMapValue(userId, sectionId));
  }

  /**
   * Gets an ID mapping, guaranteeing a return value of the ID or undefined.
   *
   * @param userId
   * @param sectionId
   * @returns {*}
   */
  static getMapValue(userId: number, sectionId: number) {
    const userObj = this.mapping[userId] || {};
    return userObj[sectionId];
  }

  // Lifecycle hooks to maintain the mapping
  static updateMap(model: SectionGrade) {
    this.mapping[model.userId] = this.mapping[model.userId] || {};
    this.mapping[model.userId][model.sectionId] = model.id;
  }

  static deleteMapEntry(model: SectionGrade) {
    delete this.mapping[model.userId][model.sectionId];
  }
}

export default SectionGrade;
