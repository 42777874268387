import {HTTP} from '@/common/api/http';
import {API_BASE_URL} from '@/common/api/base';
import {USERS_BASE_URL} from '@/users/api/users';
import {ASSIGNMENTS_BASE_URL} from '@/assignments/api/assignments';
import {TASKS_BASE_URL} from './tasks';
import {IStembleAxiosPromise} from '@/common/api/types/IStembleAxiosPromise';
import {IGetTaskResponsesParams} from '@/tasks/types/IGetTaskResponsesParams';

export const TASK_RESPONSES_BASE_URL = API_BASE_URL + '/responses';

const postResponsePayload = (
  url: string,
  response: any,
  {taskStateId}: {taskStateId?: number} = {},
  files: File[] = []
): IStembleAxiosPromise => {
  if (files.length > 0) {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append('attachments[]', file);
    });
    const formDataEntries = buildFormData(response);

    formDataEntries.forEach((formDataEntry) => {
      formData.append(formDataEntry.key, formDataEntry.value);
    });

    if (taskStateId) {
      formData.append('taskStateId', taskStateId.toString());
    }

    return HTTP.post(url, formData, {
      headers: {'Content-Type': 'multipart/form-data'},
    });
  } else {
    return HTTP.post(url, {...response, taskStateId});
  }
};

/**
 * Submit a response to a task
 *
 * @param userId
 * @param assignmentId
 * @param taskId
 * @param response
 * @param taskStateId
 * @param files
 * @returns {IStembleAxiosPromise}
 */
export const storeResponse = async (
  userId: number,
  assignmentId: number,
  taskId: number,
  response: any,
  {taskStateId}: {taskStateId?: number} = {},
  files: File[] = []
): IStembleAxiosPromise => {
  const url = `${USERS_BASE_URL}/${userId}/assignments/${assignmentId}/tasks/${taskId}/responses/store`;
  return postResponsePayload(url, response, {taskStateId}, files);
};

/**
 * Submit a response to a task
 *
 * @param userId
 * @param assignmentId
 * @param taskId
 * @param response
 * @param taskStateId
 * @param files
 * @returns {IStembleAxiosPromise}
 */
export const submitResponse = async (
  userId: number,
  assignmentId: number,
  taskId: number,
  response: any,
  {taskStateId}: {taskStateId?: number} = {},
  files: File[] = []
): IStembleAxiosPromise => {
  const url = `${USERS_BASE_URL}/${userId}/assignments/${assignmentId}/tasks/${taskId}/responses/submit`;
  return postResponsePayload(url, response, {taskStateId}, files);
};

export const regradeResponse = async (taskResponseId: number): IStembleAxiosPromise => {
  const url = `${API_BASE_URL}/responses/${taskResponseId}/regrade`;
  return HTTP.post(url);
};

type FormDataEntry = {
  key: string;
  value: string | Blob;
};

const buildFormData = (object: any, parent: string = ''): FormDataEntry[] => {
  const formDataEntries: FormDataEntry[] = [];
  for (const key in object) {
    // eslint-disable-next-line no-prototype-builtins
    if (object.hasOwnProperty(key)) {
      const formKey = parent ? parent + `[${key}]` : key;
      if (typeof object[key] === 'object') {
        formDataEntries.push(...buildFormData(object[key], formKey));
      } else {
        formDataEntries.push({
          key: formKey,
          value: object[key],
        });
      }
    }
  }
  return formDataEntries;
};

/**
 * Submits a response for the purposes of trying a question
 * @param taskId
 * @param response
 * @param taskStateId
 * @returns {IStembleAxiosPromise}
 */
export const submitTryItReponse = async (
  taskId: number,
  response: any,
  {taskStateId}: {taskStateId?: number} = {}
): IStembleAxiosPromise => {
  return HTTP.post(`${TASKS_BASE_URL}/${taskId}/try-it`, {
    ...response,
    taskStateId,
  });
};

/**
 * Gets a specific task response by id.
 *
 * @param responseId
 * @returns {IStembleAxiosPromise}
 */
export const getTaskResponse = async (responseId: number): IStembleAxiosPromise => {
  return HTTP.get(`${TASK_RESPONSES_BASE_URL}/${responseId}`);
};

/**
 * Gets tasks responses by their ids.
 *
 * @returns {IStembleAxiosPromise}
 * @param params
 */
export const getTaskResponses = async (params: IGetTaskResponsesParams): IStembleAxiosPromise => {
  return HTTP.get(TASK_RESPONSES_BASE_URL, {params});
};

/**
 * Gets a specific task's response by a user on an assignment.
 *
 * @param userId
 * @param assignmentId
 * @param taskId
 * @returns {IStembleAxiosPromise}
 */
export const getResponsesForUserAssignmentTask = async (
  userId: number,
  assignmentId: number,
  taskId: number
): IStembleAxiosPromise => {
  return HTTP.get(
    `${USERS_BASE_URL}/${userId}/assignments/${assignmentId}/tasks/${taskId}/responses`
  );
};

/**
 * Gets responses for a user for all tasks on an assignment.
 *
 * @param userId
 * @param assignmentId
 * @returns {IStembleAxiosPromise}
 */
export const getResponsesForUserAssignment = async (
  userId: number,
  assignmentId: number
): IStembleAxiosPromise => {
  return HTTP.get(`${USERS_BASE_URL}/${userId}/assignments/${assignmentId}/responses`);
};

/**
 * Get responses from all users on any assignment for a task.
 *
 * @param taskId
 * @returns {Promise<void>}
 */
// eslint-disable-next-line no-unused-vars
export const getResponsesOnTask = async (taskId: number) => {
  // TODO: implement
};

/**
 * Gets responses from all users on a specific task on an assignment.
 *
 * @param assignmentId
 * @param taskId
 * @returns {IStembleAxiosPromise}
 */
export const getResponsesForAssignmentTask = async (
  assignmentId: number,
  taskId: number
): IStembleAxiosPromise => {
  return HTTP.get(`${ASSIGNMENTS_BASE_URL}/${assignmentId}/tasks/${taskId}/responses`);
};

export default {
  getTaskResponse,
  getTaskResponses,
  getResponsesForUserAssignmentTask,
  getResponsesForUserAssignment,
  getResponsesOnTask,
  getResponsesForAssignmentTask,
  submitResponse,
  submitTryItReponse,
};
