<template>
  <v-snackbar v-model="showSnackbar" :timeout="options.timeout" :color="options.color">
    <slot>
      <span class="white--text">{{
        options.message !== null ? options.message : $t(options.messageTranslationKey)
      }}</span>
    </slot>
    <template v-slot:action="{attrs}">
      <slot name="action" :attrs="attrs">
        <v-btn
          v-if="options.showButton"
          text
          class="white--text"
          v-bind="attrs"
          @click="close(false)"
        >
          {{ $t('successSnackbar.dismiss') }}
        </v-btn>
      </slot>
    </template>
  </v-snackbar>
</template>

<script>
import SuccessSnackbarPlugin from '@/common/plugins/success-snackbar';

export default {
  name: 'SuccessSnackbar',
  data: () => ({
    showSnackbarInternal: false,
    closePayload: null,
    queue: [],
    promiseData: {
      resolve: null,
      promise: null,
    },
    options: {},
  }),
  computed: {
    showSnackbar: {
      get() {
        return this.showSnackbarInternal;
      },
      set(value) {
        if (value === false) {
          this.resolve(this.closePayload);
        }
        this.showSnackbarInternal = value;
      },
    },
  },
  created() {
    this.clearOptions();
  },
  beforeMount() {
    SuccessSnackbarPlugin.snackbar = this;
  },
  methods: {
    async open(options) {
      const queueObject = this.pushToQueue(options);

      // If no promise is active, immediately handle the item
      if (this.promiseData.promise === null) {
        const [entry] = this.queue.splice(0, 1);
        return this.handleQueueEntry(entry);
      }

      return queueObject.promise;
    },
    pushToQueue(options) {
      let queueObject = {promise: null, resolve: null, options};
      queueObject.promise = new Promise((resolve) => {
        queueObject.resolve = resolve;
      });
      this.queue.push(queueObject);
      return queueObject;
    },
    handleQueueEntry(queueObject) {
      this.options = {...this.options, ...queueObject.options};
      this.promiseData = queueObject;
      this.showSnackbar = true;
      return queueObject.promise;
    },
    close(payload = null) {
      this.closePayload = payload;
      this.showSnackbar = false;
    },
    resolve(payload = null) {
      this.clearOptions();
      this.closePayload = null;
      if (this.promiseData.resolve) {
        this.promiseData.resolve(payload);
        this.promiseData.resolve = null;
        this.promiseData.promise = null;
      }
      if (this.queue.length > 0) {
        const [entry] = this.queue.splice(0, 1);
        this.$nextTick(() => {
          this.handleQueueEntry(entry);
        });
      }
    },
    clearOptions() {
      this.options = {
        message: null,
        messageTranslationKey: null,
        showButton: false,
        color: 'success',
        width: 290,
        timeout: 3000,
      };
    },
  },
};
</script>
