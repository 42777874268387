import {Model} from '@vuex-orm/core';

/**
 * MathJaxContent class
 */
export default class MathJaxContent extends Model {
  static entity = 'MathJaxContent';

  static fields() {
    return {
      id: this.number(null),
      formula: this.string(''),
    };
  }
}
