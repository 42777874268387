import {PluginFunction} from 'vue';
import {inject} from '@/container';
import {FeatureFlagService} from '@/feature-flags';
import {DependencyType} from '@/container/types/DependencyType';
import {splitFeatureFlags} from '@/feature-flags/utils/splitFeatureFlags';
import {FeatureFlag} from '@/feature-flags/types/FeatureFlag';

/*
 * Augment Vue type
 */
declare module 'vue/types/vue' {
  interface Vue {
    $features: DependencyType<typeof FeatureFlagService>;
  }
}

export const FeatureFlagsPlugin: PluginFunction<any> = (Vue) => {
  const featureFlagService = inject(FeatureFlagService);

  let featureFlags = splitFeatureFlags('All');

  // Special case of FeatureFlag.All enables all features with null variants
  if (featureFlags.length === 1 && featureFlags[0][0] === FeatureFlag.All) {
    featureFlags = Object.values(FeatureFlag).map((featureFlag) => [featureFlag, null]);
  }

  for (const [flag, variant] of featureFlags) {
    // @ts-ignore variant types
    featureFlagService.enable(flag, variant);
  }

  Vue.prototype.$features = featureFlagService;
};
