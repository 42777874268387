import {
  institution,
  course,
  courseName,
  courseCode,
  labTutorial,
  labTutorialCourse,
  sections,
  courseSettings,
} from '@/locales/en/courseSettings';

export const courseWizard = {
  institution,
  course,
  courseName,
  courseCode,
  labTutorial,
  labTutorialCourse,
  open: 'Create course',
  institutionName: `${institution} Name`,
  goToCourse: 'Go to your new course',
  screens: {
    basicMetadata: {
      title: `${course} Information`,
      prompt: 'Start by entering the basic information for your course below.',
      courseName: {
        label: courseName,
        hint: 'This is the full name for your course, usually as listed in a timetable or syllabus. For example: "General Chemistry I"',
      },
      courseCode: {
        label: courseCode,
        hint: 'This is the shorthand code usually reported on transcripts. For example: "CHEM 1000"',
      },
    },
    institution: {
      title: institution,
      prompt: 'For which institution are you teaching this course?',
      institution: {
        label: institution,
        hint: institution,
      },
    },
    sections: {
      title: sections,
      prompt:
        'Sections are optional components of courses in Stemble. They allow you to partition the full enrolment of ' +
        'your course into subgroups. These are typical for large classes. Each section can have a different ' +
        'roster, teaching staff, and set of assignments.',
    },
    dates: {
      title: 'Dates',
      prompt:
        'Select the dates on which your course begins and ends below by first clicking the start date, and then the end date.',
      startDate: {
        label: courseSettings.startDate,
      },
      endDate: {
        label: courseSettings.endDate,
      },
    },
    labTutorialToggle: {
      title: 'Lab/Tutorial Course',
      prompt:
        `Would you like to create an optional ${labTutorial.toLowerCase()} course to go with your parent course? ` +
        'Students will see this as a separate course in their navigation menu but will not need to pay for ' +
        'an additional course. It is a helpful feature to cleanly differentiate between lab-related content and ' +
        'lecture-related content.',
    },
    subCourseBasicMetadata: {title: `${labTutorial} Information`},
    subCourseSections: {title: `${labTutorial} Sections`},
    subCourseDates: {title: `${labTutorial} Dates`},
    summary: {title: 'Review', prompt: "Please review your course's data below before saving."},
    finish: {title: 'Finish', prompt: 'Your course has been created.'},
  },
};
