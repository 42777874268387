import SuccessSnackbar from '@/common/components/SuccessSnackbar.vue';

const SuccessSnackbarPlugin = {
  install(Vue) {
    this.snackbar = null;

    // making our modal component global
    Vue.component('success-snackbar', SuccessSnackbar);

    Vue.prototype.$successSnackbar = {
      async open(params) {
        return SuccessSnackbarPlugin.snackbar.open(params);
      },
    };
  },
};

export default SuccessSnackbarPlugin;
