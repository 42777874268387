import QuestionResponse from './QuestionResponse';
import {Fields} from '@vuex-orm/core';

/**
 * DataDrivenResponse class
 */
export class DataDrivenResponse extends QuestionResponse {
  static entity = 'DataDrivenResponse';

  data!: {[key: string]: any};

  static fields(): Fields {
    return {
      ...super.fields(),
      data: this.attr(null),
    };
  }
}
