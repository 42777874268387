import {FeatureToggleComponent as feature} from 'vue-feature-toggle';
import {makeGlobalSingleton} from '@/container';
import {FeatureFlag} from '@/feature-flags/types/FeatureFlag';
import {FeatureVariantMap} from '@/feature-flags/types/FeatureVariantMap';

function makeFeatureFlagService<
  FeatureFlag extends string,
  VariantMap extends Record<FeatureFlag, string | null> = Record<FeatureFlag, string | null>,
>() {
  function isEnabled(featureName: FeatureFlag, variant?: VariantMap[FeatureFlag]): boolean {
    return feature.isVisible(featureName, variant);
  }

  function setFeature(
    featureName: FeatureFlag,
    variant: VariantMap[FeatureFlag] | undefined,
    value: boolean
  ) {
    if (variant) {
      feature.visibility(featureName, variant, value);
    } else {
      feature.visibility(featureName, value);
    }
  }

  function enable(featureName: FeatureFlag, variant?: VariantMap[FeatureFlag]) {
    setFeature(featureName, variant, true);
  }

  function disable(featureName: FeatureFlag, variant?: VariantMap[FeatureFlag]) {
    setFeature(featureName, variant, false);
  }

  return {
    isEnabled,
    enable,
    disable,
  };
}

export const FeatureFlagService = makeGlobalSingleton(() =>
  makeFeatureFlagService<FeatureFlag, FeatureVariantMap>()
);
