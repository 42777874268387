









import {defineComponent} from '@vue/composition-api';
import {UseQuotes} from '@/loading/composables/UseQuotes';
import {inject} from '@/container';

export default defineComponent({
  name: 'EducationalQuote',
  setup() {
    return inject(UseQuotes);
  },
});
