import Event from './Event';
import EventSchedule from './EventSchedule';
import * as SectionEventApi from '@/events/api/orm/SectionEventApi';
import {Fields} from '@vuex-orm/core';
import {CourseSection, Course} from '@/courses/models';

/**
 * SectionEvent class
 */
export class SectionEvent extends Event {
  static entity = 'SectionEvent';

  static fields(): Fields {
    return {
      ...super.fields(),
      isMandatory: this.boolean(false),
      eventScheduleId: this.number(null),
      eventSchedule: this.belongsTo(EventSchedule, 'eventScheduleId'),
    };
  }

  static get api() {
    return SectionEventApi;
  }

  static queryForCourseSection(courseSection: CourseSection) {
    return this.query().where('ownerId', courseSection.id);
  }

  static queryForCourse(course: Course) {
    const sectionIds = course.sections.map((section) => section.id);
    return this.query().where('ownerId', sectionIds);
  }
}

export default SectionEvent;
