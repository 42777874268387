import {HTTP} from '@/common/api/http';
import {API_BASE_URL} from '@/common/api/base';
import {IGetInstitutionsParams} from '@/common/api/types/IGetInstitutionsParams';
import {IStembleAxiosPromise} from '@/common/api/types/IStembleAxiosPromise';
import {InstitutionDto} from '@/institutions/dtos/InstitutionDto';

export const INSTITUTIONS_URL = `${API_BASE_URL}/institutions`;

/**
 * Get many institutions
 * @returns {IStembleAxiosPromise}
 * @param params
 */
export const getInstitutions = async (
  params: IGetInstitutionsParams = {}
): IStembleAxiosPromise<InstitutionDto[]> => {
  return HTTP.get(INSTITUTIONS_URL, {params});
};

/**
 * Get an institution by ID
 * @param id
 * @returns {IStembleAxiosPromise}
 */
export const getInstitution = async (id: number): IStembleAxiosPromise<InstitutionDto> => {
  return HTTP.get(`${INSTITUTIONS_URL}/${id}`);
};

/**
 * Create an institution
 * @param institution Institution data
 */
export const createInstitution = async (institution: InstitutionDto): IStembleAxiosPromise => {
  return HTTP.post(`${INSTITUTIONS_URL}`, institution);
};

/**
 * Update an institution
 * @param id          Identifier for Institution to be updated
 * @param institution Institution data
 */
export const updateInstitution = async (
  id: number,
  institution: InstitutionDto
): IStembleAxiosPromise => {
  return HTTP.put(`${INSTITUTIONS_URL}/${id}`, institution);
};

/**
 * Patch the provided properties on an institution
 *
 * @param id          Identifier for Institution to be updated
 * @param institution Institution data
 */
export const patchInstitution = async (
  id: number,
  institution: Partial<InstitutionDto>
): IStembleAxiosPromise => {
  return HTTP.patch(`${INSTITUTIONS_URL}/${id}`, institution);
};

export const deleteInstitution = async (id: number): IStembleAxiosPromise => {
  return HTTP.delete(`${INSTITUTIONS_URL}/${id}`);
};

export default {
  getInstitutions,
  getInstitution,
  createInstitution,
  updateInstitution,
  patchInstitution,
  deleteInstitution,
};
