import Specification from './Specification';

export class IsParentOf extends Specification {
  constructor(courseLikes, depth = 0, crossCourseBoundaries = false) {
    super();
    this.courseLikes = Specification.getIds(courseLikes);
    this.depth = depth;
    this.crossCourseBoundaries = crossCourseBoundaries;
  }

  isSatisfiedBy(courseRole) {
    for (const courseLikeId of this.courseLikes) {
      if (this.isParentOf(courseRole.courseLikeId, courseLikeId, this.depth)) {
        return true;
      }
    }
    return false;
  }

  isParentOf(candidate, courseLike, depth = 0) {
    if (!this.crossCourseBoundaries && this.courseHierarchyService.isCourse(courseLike)) {
      return false;
    }
    let parent = this.courseHierarchyService.getParentId(courseLike);
    if (parent !== null) {
      if (parent === candidate) {
        return true;
      }

      if (depth > 0) {
        if (this.isParentOf(candidate, parent, depth - 1)) {
          return true;
        }
      }
    }
    return false;
  }
}

export default IsParentOf;
