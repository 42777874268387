export class UserStorage {
  // eslint-disable-next-line
  async set(user) {}

  get() {}

  hasUser() {
    return false;
  }
}
