import QuestionResponse from './QuestionResponse';
import {Fields} from '@vuex-orm/core';

/**
 * MultipleChoiceResponse class
 */
export class MultipleChoiceResponse extends QuestionResponse {
  static entity = 'MultipleChoiceResponse';

  choice!: string | number;

  static fields(): Fields {
    return {
      ...super.fields(),
      choice: this.attr(null),
    };
  }
}

export default MultipleChoiceResponse;
