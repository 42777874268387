import {IconsObject} from '@/icons/services/IconService';

const common: IconsObject = {
  edit: 'mdi-pencil',
  refresh: 'mdi-refresh',
  email: 'mdi-email',
  checkmark: 'mdi-check',
  menu: 'mdi-menu',
  settings: 'mdi-cog',
  search: 'mdi-magnify',
  add: 'mdi-plus',
  remove: 'mdi-minus',
  preview: 'mdi-eye',
  download: 'mdi-download',
  attachment: 'mdi-paperclip',
  trash: 'mdi-trash-can',
  info: 'mdi-information',
  close: 'mdi-close',
  help: 'mdi-help-circle',
  next: 'mdi-arrow-right',
  previous: 'mdi-arrow-left',
};

export default common;
