import {Fields, Model, Query} from '@vuex-orm/core';
import User from '../../users/models/User';
import Task from './Task';
import Assignment from '@/assignments/models/Assignment';
import Grade from '../../grades/models/Grade';
import TaskState from '../../task-states/models/TaskState';
import TaskFeedback from './TaskFeedback';
import {Joined} from '@/orm/types/Joined';
import * as TaskResponseApi from '@/tasks/api/orm/TaskResponseApi';
import TaskResponseTaskFeedback from '@/tasks/models/TaskResponseTaskFeedback';
import {ITaskResponseQueryParameters} from '@/tasks/types/ITaskResponseQueryParameters';
import {getFeedbackByPart, getFeedbackTextByPart} from '../utils/feedback';
import ResponseFileUpload from '@/tasks/models/ResponseFileUpload';
import {RawTaskResponse} from '@/tasks/types/RawTaskResponse';
import {TaskResponseMap} from '@/tasks/types/TaskResponseMap';
import {TaskResponseType} from '@/tasks/types/TaskResponseType';
import {IVariableMapping} from '@/task-states/types/IVariableMapping';
import {GradingStatus} from '@/assignments/types/GradingStatus';

/**
 * TaskResponse class
 */
export class TaskResponse<
    ResponseType extends TaskResponseType = TaskResponseType,
    VariableMapping extends IVariableMapping = IVariableMapping,
  >
  extends Model
  implements
    Omit<
      RawTaskResponse<ResponseType, boolean>,
      'content' | 'taskState' | 'feedback' | 'feedbackJoiners' | 'attachments' | 'taskValues'
    >
{
  static entity = 'TaskResponse';

  id!: number;
  responseType!: ResponseType;
  assignmentId!: number;
  assignment!: Joined<Assignment>;
  taskId!: number;
  task!: Joined<Task>;
  userId!: number;
  user!: Joined<User>;
  content!: Joined<TaskResponseMap[ResponseType]>;
  createdAt!: string;
  updatedAt!: string | null;
  lastSavedAt!: string | null;
  submittedAt!: string | null;
  grade!: Joined<Grade>;
  taskStateId!: number | null;
  taskState!: Joined<TaskState<VariableMapping>>;
  feedbackId!: number | null;
  feedback!: Joined<TaskFeedback>;
  feedbackJoiners!: Joined<TaskResponseTaskFeedback[]>;
  isComplete!: boolean;
  isDraft!: boolean;
  isSelfSubmitted!: boolean;
  gradingStatus!: GradingStatus;
  attachments!: Joined<ResponseFileUpload[]>;

  static fields(): Fields {
    return {
      id: this.number(null),
      responseType: this.string(''),
      assignmentId: this.number(null),
      assignment: this.belongsTo(Assignment, 'assignmentId'),
      taskId: this.number(null),
      task: this.belongsTo(Task, 'taskId'),
      userId: this.number(null),
      user: this.belongsTo(User, 'userId'),
      content: this.morphTo('id', 'responseType'),
      createdAt: this.string(null),
      updatedAt: this.string(null).nullable(),
      lastSavedAt: this.string(null).nullable(),
      submittedAt: this.string(null).nullable(),
      grade: this.hasOne(Grade, 'responseId'),
      taskStateId: this.number(null).nullable(),
      taskState: this.belongsTo(TaskState, 'taskStateId'),
      feedbackId: this.number(null).nullable(),
      feedback: this.belongsTo(TaskFeedback, 'feedbackId'),
      feedbackJoiners: this.hasMany(TaskResponseTaskFeedback, 'responseId'),
      isComplete: this.boolean(true),
      isDraft: this.boolean(true),
      isSelfSubmitted: this.boolean(true),
      gradingStatus: this.string(GradingStatus.Ungraded),
      attachments: this.hasMany(ResponseFileUpload, 'taskResponseId'),
    };
  }

  static get api() {
    return TaskResponseApi;
  }

  /**
   * Get a query for searching by a userId, assignmentId, and taskId.
   *
   * @param userId
   * @param assignmentId
   * @param taskId
   * @returns {Query<TaskResponse>}
   */
  static getByUserAssignmentTask(userId: number, assignmentId: number, taskId: number) {
    return this.query()
      .with('content')
      .where((record: TaskResponse) => {
        return (
          record.userId == userId && record.assignmentId == assignmentId && record.taskId == taskId
        );
      })
      .orderBy('createdAt', 'desc');
  }

  static fullQuery(options?: ITaskResponseQueryParameters) {
    let q = options?.query ?? this.query();

    if (options?.feedback) {
      q = q.with('feedbackJoiners', (joinerQuery) => {
        return TaskResponseTaskFeedback.fullQuery({
          query: joinerQuery as Query<TaskResponseTaskFeedback>,
        });
      });
    }
    q = q
      .with('content|grade')
      .with('attachments')
      .with('taskState', (query3) => TaskState.fullQuery({query: query3 as Query<TaskState>}))
      .orderBy('createdAt');

    return q;
  }

  getFeedbackByPart() {
    return getFeedbackByPart(this.feedbackJoiners);
  }

  getFeedbackTextByPart(): Record<string, string[]> {
    return getFeedbackTextByPart(this.getFeedbackByPart());
  }
}

export default TaskResponse;
