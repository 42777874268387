import {ErrorReportingService} from '@/errors/services/error-reporting';
import {VueConstructor} from 'vue';
import ErrorReporter from '@/errors/services/error-reporting/error-reporter';
import {StemblePlugin} from '@/common/plugins/stemble-plugin';
import {filters} from '@/errors/plugins/error-reporting/filters';
import {inject} from '@/container';
import {getEnvironment, isDevelopment} from '@/environment';

/*
 * Augment Vue type
 */
declare module 'vue/types/vue' {
  interface Vue {
    $errorReporting: ErrorReportingService;
  }
}

let instance: ErrorReportingService | null = null;

export function getInstance() {
  return instance;
}

type ErrorReportingPlugin = StemblePlugin<ErrorReportingService>;

const plugin: ErrorReportingPlugin = {
  install(Vue: VueConstructor) {
    const reporters: ErrorReporter[] = [];

    const newInstance = inject(ErrorReportingService, {
      reporters,
      filters,
    });
    instance = newInstance;

    // Make available to all Vue components
    Vue.prototype.$errorReporting = newInstance;

    /*
     * Configure global error handlers
     */

    // Configure the Vue error handler
    Vue.config.errorHandler = (err, vm, info) => {
      newInstance.catchError(err, {
        vueInfo: info,
      });
      if (isDevelopment()) {
        console.error(err);
      }
    };

    // Configure the catch-all error handler for any uncaught errors
    window.onerror = (errorMessage, url, lineNo, columnNo, error) => {
      error = error || new Error(errorMessage as string);
      newInstance.catchError(error);

      // Prevent default if in production and not allowed console errors
      return !isDevelopment();
    };

    window.onunhandledrejection = (event: PromiseRejectionEvent) => {
      const {reason} = event;
      newInstance.catchError(reason);

      // Prevent default if in production and not allowed console errors
      if (!isDevelopment()) {
        event.preventDefault();
      }
    };
  },

  getInstance(): ErrorReportingService | null {
    return instance;
  },
};

/**
 * Installs the error reporting service
 */
export default plugin;
