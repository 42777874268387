import {IHasInjectionKey} from '@/container/types/IHasInjectionKey';
import {InjectionKey} from '@/container/types/InjectionKey';

/**
 * Gets the Symbol instance for a service
 * @param service
 */
export function symbolFor<T>(service: IHasInjectionKey<T>): InjectionKey<T> {
  return service.symbol;
}
