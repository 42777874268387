import Question from './Question';
import {Fields} from '@vuex-orm/core';
import {RawMultipleChoiceQuestion} from '@/tasks/types/RawTask';

/**
 * Task class
 */
export class MultipleChoiceQuestion extends Question implements RawMultipleChoiceQuestion {
  static entity = 'MultipleChoiceQuestion';

  options!: (string | number)[];
  correctOption!: string | number;

  static fields(): Fields {
    return {
      ...super.fields(),
      options: this.attr(null),
      correctOption: this.attr(null),
    };
  }
}

export default MultipleChoiceQuestion;
