export default {
  draft: 'mdi-pencil-circle',
  normal: 'mdi-checkbox-blank-circle',
  graded: 'mdi-checkbox-blank-circle-outline',
  lateNormal: 'mdi-clock-alert',
  lateGraded: 'mdi-clock-alert-outline',
  hasAttachmentsNormal: 'mdi-plus-circle',
  hasAttachmentsGraded: 'mdi-plus-circle-outline',
  selected: 'mdi-record-circle',
  selectedAndGraded: 'mdi-circle-double',
};
