import Vue from 'vue';
import VueI18n from 'vue-i18n';

// @ts-ignore Vuetify doesn't have types for locale/en yet?
import vuetifyEnglish from 'vuetify/lib/locale/en';
// @ts-ignore Vuetify doesn't have types for locale/en yet?
import vuetifyFrench from 'vuetify/lib/locale/fr';
import {fr} from '@/locales/fr';
import {en} from '@/locales/en';

export type TranslateFunction = ReturnType<typeof useTranslate>;

Vue.use(VueI18n);

function getBrowserLocale() {
  const navigatorLocale =
    navigator.languages !== undefined ? navigator.languages[0] : navigator.language;

  if (!navigatorLocale) {
    return undefined;
  }

  return navigatorLocale.trim().split(/-|_/)[0];
}

function getStartingLocale() {
  const browserLocale = getBrowserLocale();
  if (browserLocale == 'en' || browserLocale == 'fr') {
    return browserLocale;
  }
  return 'en';
}

const messages = {
  en: {
    ...en,
    $vuetify: vuetifyEnglish,
  },
  fr: {
    ...fr,
    $vuetify: vuetifyFrench,
  },
};

const instance = new VueI18n({
  locale: getStartingLocale(),
  fallbackLocale: 'en',
  messages,
});

/**
 * Provides the VueI18n translate function as a composition function
 */
export const useTranslate = () => {
  return instance.t.bind(instance);
};

export const usePluralizedTranslations = () => {
  return instance.tc.bind(instance);
};

export default instance;
