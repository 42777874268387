import {StembleModel} from '@/common/models/StembleModel';
import {Joined} from '@/orm/types/Joined';
import {Fields, Query} from '@vuex-orm/core';
import {TaskValueType} from '@/task-values/types/TaskValueType';
import {NestedTaskValue} from '@/task-values/types/NestedTaskValue';
import {AbstractTaskValue} from '@/task-values/models/AbstractTaskValue';

type FullQueryParams = {
  includeContent?: boolean;
  query?: Query<TaskValueContent>;
};
export class TaskValueContent<T extends TaskValueType = TaskValueType>
  extends StembleModel
  implements Omit<NestedTaskValue<T>, 'content'>
{
  static entity = 'TaskValueContent';

  id!: string;
  type!: T;
  content!: Joined<NestedTaskValue<T>['content']>;

  static fields(): Fields {
    return {
      id: this.number(null),
      type: this.string(''),
      content: this.morphTo('id', 'type'),
    };
  }

  static fullQuery(options: FullQueryParams = {}) {
    const q = options?.query ?? this.query();
    if (options.includeContent ?? true) {
      q.with('content', (q1) =>
        // FIXME: Depends on q1 being a query on the correct model sub-class
        // FIXME: Make sure to test this and pick this up!
        AbstractTaskValue.fullQuery({includeSource: true, query: q1 as Query<AbstractTaskValue>})
      );
    }
    return q;
  }
}
