import {Model} from '@vuex-orm/core';

/**
 * TextContent class
 */
export default class TextContent extends Model {
  static entity = 'TextContent';

  static fields() {
    return {
      id: this.number(null),
      text: this.string(''),
    };
  }
}
