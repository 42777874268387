import {HTTP} from '@/common/api/http';
import {API_BASE_URL} from '@/common/api/base';
import {IGetTaskParams} from '@/tasks/types/IGetTaskParams';
import {IStembleAxiosPromise} from '@/common/api/types/IStembleAxiosPromise';
import {IGetTasksParams} from '@/tasks/types/IGetTasksParams';
import {IPatchTaskParams} from '@/tasks/types/IPatchTaskParams';
import {RawTask} from '@/tasks/types/RawTask';

export const TASKS_BASE_URL = API_BASE_URL + '/tasks';

/**
 * Get a single task by ID
 * @param {Number} id
 * @param params
 * @returns {IStembleAxiosPromise}
 */
export const getTask = (id: number, params: IGetTaskParams = {}): IStembleAxiosPromise => {
  return HTTP.get(`${TASKS_BASE_URL}/${id}`, {params});
};

/**
 * Fetch many tasks
 * @returns {IStembleAxiosPromise}
 * @param params
 */
export const getTasks = async (params: IGetTasksParams = {}): IStembleAxiosPromise => {
  return HTTP.get(TASKS_BASE_URL, {params});
};

/**
 * Patch task data
 * @param id
 * @param params
 */
export const patchTask = async (
  id: number,
  params: IPatchTaskParams
): IStembleAxiosPromise<RawTask> => {
  return HTTP.patch(`${TASKS_BASE_URL}/${id}`, params);
};

export const nextTaskApi = async (id: number): IStembleAxiosPromise<RawTask> => {
  return HTTP.get(`${TASKS_BASE_URL}/${id}/next`);
};

export const previousTaskApi = async (id: number): IStembleAxiosPromise<RawTask> => {
  return HTTP.get(`${TASKS_BASE_URL}/${id}/previous`);
};

export default {
  getTask,
  getTasks,
  patchTask,
};
