import {HTTP} from '@/common/api/http';
import {API_BASE_URL} from '@/common/api/base';
import {IStembleAxiosPromise} from '@/common/api/types/IStembleAxiosPromise';

export const SEND_USER_EMAIL_BASE_URL = `${API_BASE_URL}/user-invitations`;

/**
 * Send a new user email invitation
 * @returns {IStembleAxiosPromise}
 */
export const sendInvitationEmail = async (
  email: string,
  params: {resend?: boolean} = {}
): IStembleAxiosPromise => {
  return HTTP.post(SEND_USER_EMAIL_BASE_URL, {email, ...params});
};

/**
 * Request a new invitation email
 *
 * @param {string} email
 * @returns {Promise<any>}
 */
export const resendInvitation = async (email: string) => {
  return HTTP.post(`${SEND_USER_EMAIL_BASE_URL}/resend`, {email});
};
