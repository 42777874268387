import {UserStorage} from '.';

export class VuexUserStorage extends UserStorage {
  constructor({store, moduleName = 'userStorage'}) {
    super();
    this.store = store;
    this.moduleName = moduleName;

    this.registerModule();
  }

  registerModule() {
    this.store.registerModule(this.moduleName, {
      namespaced: true,
      state: {
        user: null,
      },
      mutations: {
        setUser(state, {user}) {
          state.user = user;
        },
        clearUser(state) {
          state.user = null;
        },
      },
    });
  }

  async set(user) {
    this.store.commit(`${this.moduleName}/setUser`, {user});
    return user;
  }

  get() {
    return this.store.state[this.moduleName].user;
  }

  clear() {
    return this.store.commit(`${this.moduleName}/clearUser`);
  }

  hasUser() {
    return this.get() !== null;
  }
}
