import {StembleModel} from '@/common/models/StembleModel';
import {DistributionType} from '@/distributions/types/DistributionType';
import {DistributionMap, NestedDistribution} from '@/distributions/types/NestedDistribution';
import {Joined} from '@/orm/types/Joined';
import {Fields} from '@vuex-orm/core';

export class DistributionContent<T extends DistributionType = DistributionType>
  extends StembleModel
  implements Omit<NestedDistribution, 'content'>
{
  static entity = 'DistributionContent';

  id!: number;
  type!: T;
  content!: Joined<DistributionMap[T]>;

  static fields(): Fields {
    return {
      id: this.number(null),
      type: this.string(null),
      content: this.morphTo('id', 'type'),
    };
  }
}
