<template>
  <div class="conversation-page">
    <div class="conversation-page__inner">
      <h2 class="page-title">Conversations</h2>
      <div class="conversation-list">
        <v-data-table
          v-if="conversations.length > 0"
          :headers="headers"
          :items="conversations"
          item-key="id"
          :items-per-page="5"
          :item-class="unreadMessageCheck"
        >
          <template v-slot:body="{items}">
            <tbody>
              <tr v-for="item in items" :key="item.id" class="chatTable" @click="onRowClick(item)">
                <td class="">
                  <span
                    :class="item.unreadMessage ? 'name-wrapper name-wrapper--bold' : 'name-wrapper'"
                    ><span v-if="item.unreadMessage" class="notification-light"></span>
                    {{ otherUser(item).firstName + ' ' + otherUser(item).lastName }}</span
                  >
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
        <div v-else-if="!isConversationsLoading" class="conversations-placeholder">
          You don't have any conversations.
        </div>
        <v-dialog v-model="dialog" max-width="600px">
          <template v-slot:activator="{on, props}">
            <v-btn
              v-show="!$gate.isFaculty()"
              rounded
              small
              color="primary"
              v-bind="props"
              class="add-button"
              @click="dialog = true"
            >
              <v-icon class="add-button__icon">mdi-plus</v-icon>
              New Conversation
            </v-btn>
          </template>
          <v-card>
            <v-card-title> Instructors</v-card-title>
            <v-card-text>
              <v-data-table
                :headers="headers"
                :items="instructors"
                item-key="user_id"
                :items-per-page="5"
                :item-class="unreadMessageCheck"
              >
                <template v-slot:body="{items}">
                  <tbody>
                    <tr
                      v-for="item in items"
                      :key="item.id"
                      class="chatTable"
                      :style="
                        item.unreadMessage ? 'font-weight: bold; background-color: aliceblue;' : ''
                      "
                      @click="addConversation(item)"
                    >
                      <td>{{ item.first_name + ' ' + item.last_name }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
            </v-card-text>
            <v-card-actions>
              <v-btn block @click="dialog = false">Close Dialog</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </div>
  </div>
</template>
<script>
import {CONVERSATION_PAGE} from '@/router/route-names';
import {addNewConversation, getUserConversations} from '@/living-syllabus/api/conversations';
import {useAsyncState} from '@vueuse/core';
import {ref, watch} from '@vue/composition-api';
import {getInstructors} from '@/users/api/users';

export default {
  components: {},
  data() {
    return {
      expanded: [],
      singleExpand: true,
      headers: [],
      dialog: false,
    };
  },
  computed: {
    currentUserId() {
      return this.$auth.user.id;
    },
  },
  methods: {
    onRowClick(item) {
      this.$router.push({
        name: CONVERSATION_PAGE,
        params: {id: item.id, userId: this.otherUser(item).id},
      });
    },
    unreadMessageCheck(item) {
      return item ? '' : 'mdi-circle';
    },
    addConversation(item) {
      addNewConversation(item.user_id).then((t) => {
        if (!this.conversations.find((c) => c.id === t.data.data.id)) {
          this.conversations.push(t.data.data);
        }

        this.$router.push({
          name: CONVERSATION_PAGE,
          params: {
            id: t.data.data.id,
            courseLikeId: t.data.data.courseLikeId,
            userId: this.otherUser(t.data.data).id,
          },
        });
        this.dialog = false;
      });
    },
    otherUser(conversation) {
      if (!conversation.participants) {
        return {
          avatarUrl: '',
          firstName: '',
          lastName: '',
        };
      }
      return conversation.participants.find((user) => user.id !== this.currentUserId);
    },
  },
  setup() {
    document.title = `Conversations | Stemble`;

    const conversations = ref([]);
    const instructors = ref([]);

    const {state: instructorsRequest} = useAsyncState(
      getInstructors().then((t) => t.data),
      []
    );
    watch(instructorsRequest, (allInstructors) => {
      instructors.value = allInstructors;
    });

    const {state: conversationRequest, isLoading: isConversationsLoading} = useAsyncState(
      getUserConversations().then((t) => t.data.data),
      []
    );

    watch(conversationRequest, (newConversation) => {
      conversations.value = newConversation;
    });

    return {
      conversations,
      instructors,
      isConversationsLoading,
    };
  },
};
</script>

<style>
.conversation-page {
  width: 100%;
  padding: 1rem 1.25rem;
}

.conversation-page__inner {
  position: relative;
  width: 100%;
  max-width: 768px;
  margin: 0 auto;
}

.conversation-page .add-button {
  position: absolute;
  border-radius: 100px;
  top: -0.25rem;
  right: -0.125rem;
  margin: 0 !important;
  padding: 0 1.25rem !important;
  height: 2.375rem !important;
}

.add-button__icon {
  font-size: 1.5rem;
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
}

.page-title {
  color: #525252;
  font-size: 1.875rem;
  line-height: 2.25rem;
  font-weight: 400;
  margin-bottom: 1.25rem;
}

.conversation-list {
}

.conversation-list .v-data-table .v-data-table__wrapper {
  box-shadow:
    inset 0 0 0 1px rgba(147, 127, 116, 0.13),
    0px 1px 2px 0px rgba(18, 11, 40, 0.05),
    0px 4px 14px 0px rgba(46, 36, 81, 0.07);
  border-radius: 0.5rem;
}

.conversation-list .v-data-table {
  background-color: transparent !important;
}

.conversation-list .v-data-table .v-data-footer {
  border-top: none !important;
}

.conversation-list .chatTable {
  background: white;
  cursor: pointer;
  transition: color ease-out 100ms;
}

.conversation-list .chatTable td {
  font-size: 1.125rem !important;
  font-weight: 400 !important;
}

.conversation-list .chatTable :hover {
  color: #2c7cbe;
  background: #f7f7f7;
}

.unreadMessages {
  font-weight: bold;
  background-color: aliceblue;
  /* Add other styles for highlighting */
}

.conversation-list .conversations-placeholder {
  font-style: italic;
  opacity: 0.7;
  padding: 1rem 0 1.5rem 0;
}

.name-wrapper {
  display: flex;
  align-items: center;
}

.name-wrapper--bold {
  font-weight: 600 !important;
}

.notification-light {
  border-radius: 100px;
  width: 0.75rem;
  height: 0.75rem;
  background: #ffcc33;
  box-shadow:
    inset 0 0 5px -1px #ff3301,
    0 0 4px -1px #fe6500;
  margin-right: 0.75rem;
}
</style>
