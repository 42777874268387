import User from '@/users/models/User';
import Assignment from '@/assignments/models/Assignment';
import Task from '@/tasks/models/Task';
import TaskResponse from '@/tasks/models/TaskResponse';
import BaseGrade from './BaseGrade';
import {CourseSection} from '@/courses/models';
import {Joined} from '@/orm/types/Joined';
import * as TaskGradeApi from '@/grades/api/orm/TaskGradeApi';
import {Fields} from '@vuex-orm/core';
import {GradeAdjustment} from '@/grades/models/GradeAdjustment';
import {GradeStatus} from '@/assignments/types/GradeStatus';

/**
 * Grade class
 *
 * Represents a single task grade object.
 */
export class Grade extends BaseGrade {
  static entity = 'Grade';
  static mapping: {[id: number]: {[id: number]: {[id: number]: number}}} = {};

  id!: number;
  userId!: number;
  user!: Joined<User>;
  sectionId!: number | null;
  section!: Joined<CourseSection>;
  assignmentId!: number;
  assignment!: Joined<Assignment>;
  taskId!: number;
  task!: Joined<Task>;
  responseId!: number | null;
  response!: Joined<TaskResponse>;

  static fields(): Fields {
    return {
      ...super.fields(),
      id: this.number(null),
      userId: this.number(null),
      user: this.belongsTo(User, 'userId'),
      sectionId: this.number(null).nullable(),
      section: this.belongsTo(CourseSection, 'sectionId'),
      assignmentId: this.number(null),
      assignment: this.belongsTo(Assignment, 'assignmentId'),
      taskId: this.number(null),
      task: this.belongsTo(Task, 'taskId'),
      responseId: this.number(null).nullable(),
      response: this.belongsTo(TaskResponse, 'responseId'),
    };
  }

  static get api() {
    return TaskGradeApi;
  }

  /**
   * Builds a query pre-populated with where and orderBy
   * @param userId
   * @param assignmentId
   * @param taskId
   * @param effectiveOnly
   * @returns {Query<Grade>}
   */
  static queryByUserAssignmentTask(
    userId: number,
    assignmentId: number,
    taskId: number,
    effectiveOnly = true
  ) {
    let query = this.query();
    if (effectiveOnly) {
      query = query.whereId(this.getMapValue(userId, assignmentId, taskId));
    } else {
      query = query.where((item: Grade) => {
        return item.userId == userId && item.assignmentId == assignmentId && item.taskId == taskId;
      });
    }
    return query.orderBy('createdAt', 'desc');
  }

  hasAdjustments(): boolean {
    return !!GradeAdjustment.query().where('gradeId', this.id).all().length;
  }

  /**
   * Gets an ID mapping, guaranteeing a return value of the ID or undefined.
   *
   * @param userId
   * @param assignmentId
   * @param taskId
   * @returns {*}
   */
  static getMapValue(userId: number, assignmentId: number, taskId: number) {
    const userObj = this.mapping[userId] || {};
    const assignmentObj = userObj[assignmentId] || {};
    return assignmentObj[taskId];
  }

  // Lifecycle hooks to maintain the mapping
  static updateMap(model: any) {
    if (model.isEffective) {
      this.mapping[model.userId] = this.mapping[model.userId] || {};
      this.mapping[model.userId][model.assignmentId] =
        this.mapping[model.userId][model.assignmentId] || {};
      this.mapping[model.userId][model.assignmentId][model.taskId] = model.id;
    }
  }

  static deleteMapEntry(model: any) {
    if (model.userId) {
      const userLevel = this.mapping[model.userId];
      if (userLevel && model.assignmentId) {
        const assignmentLevel = userLevel[model.assignmentId];
        if (assignmentLevel && model.taskId) {
          delete this.mapping[model.userId][model.assignmentId][model.taskId];
        }
      }
    }
  }
}

export default Grade;
