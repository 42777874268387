import {ErrorReporter} from './error-reporter';
import {CustomError} from '@/errors/types/CustomError';

export class ConsoleReporter implements ErrorReporter {
  async reportError(err: CustomError): Promise<void> {
    console.error(err);
  }

  async reportErrors(errors: CustomError[]): Promise<void> {
    for (const err of errors) {
      console.error(err);
    }
  }
}

export default ConsoleReporter;
