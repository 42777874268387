import {Fields, Model} from '@vuex-orm/core';
import Task from './Task';
import {Joined} from '@/orm/types/Joined';
import Grade from '@/grades/models/Grade';
import TaskResponse from '@/tasks/models/TaskResponse';
import * as TaskAssignmentApi from '@/tasks/api/orm/TaskAssignmentApi';
import {RawTaskAssignment} from '@/tasks/types/RawTaskAssignment';
import {TaskType} from '@/tasks/types/TaskType';

/**
 * TaskAssignment class
 */
export class TaskAssignment<T extends TaskType = TaskType>
  extends Model
  implements Omit<RawTaskAssignment<T>, 'task'>
{
  static entity = 'TaskAssignment';

  id!: number;
  pointValue!: number;
  bonusPoints!: number;
  penaltyPoints!: number;
  orderingIndex!: number;
  assignmentId!: number;
  taskId!: number;
  task!: Joined<Task<T>>;

  static fields(): Fields {
    return {
      id: this.number(null),
      pointValue: this.number(null),
      bonusPoints: this.number(0),
      penaltyPoints: this.number(0),
      orderingIndex: this.number(null),
      assignmentId: this.number(null),
      taskId: this.number(null),
      task: this.belongsTo(Task, 'taskId'),
    };
  }

  static get api() {
    return TaskAssignmentApi;
  }

  hasBonusPoints() {
    return this.bonusPoints > 0;
  }

  hasPenaltyPoints() {
    return this.penaltyPoints > 0;
  }

  isPractice() {
    return this.pointValue === 0 && this.bonusPoints === 0 && this.penaltyPoints === 0;
  }

  get responses(): TaskResponse[] {
    return this.task ? this.task.getResponsesForAssignment(this.assignmentId) : [];
  }

  get grades(): Grade[] {
    return this.task ? this.task.getGradesForAssignment(this.assignmentId) : [];
  }

  get effectiveGrade() {
    const effectiveGrades = this.grades.filter((grade) => grade.isEffective);
    return effectiveGrades[0] ? effectiveGrades[0] : null;
  }

  effectiveGradeForUser(userId: number) {
    const effectiveGrades = this.grades.filter((grade: Grade) => {
      return grade.userId == userId && grade.isEffective;
    });
    return effectiveGrades[0] ? effectiveGrades[0] : null;
  }

  effectiveResponseGradeForUser(responseId: number | undefined, userId: number) {
    const effectiveGrades = this.grades.filter((grade: Grade) => {
      return (
        grade.userId == userId &&
        grade.isEffective &&
        (!grade.responseId || grade.responseId === responseId)
      );
    });
    return effectiveGrades[0] ? effectiveGrades[0] : null;
  }

  get responseGrades() {
    return this.responses.map((response) => (response.grade ? response.grade : null));
  }
}

export default TaskAssignment;
