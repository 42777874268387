import {HTTP} from '@/common/api/http';
import {API_BASE_URL} from '@/common/api/base';
import {IStembleAxiosPromise} from '@/common/api/types/IStembleAxiosPromise';
import {IGetCourseLikeAssignmentParams} from '@/assignments/types/IGetCourseLikeAssignmentParams';
import {IGetCourseLikeAssignmentsParams} from '@/assignments/types/IGetCourseLikeAssignmentsParams';
import {RawCourseLikeAssignment} from '@/assignments/types/RawCourseLikeAssignment';
import {ASSIGNMENTS_BASE_URL} from '@/assignments/api/assignments';
import {Changed} from '@/common/utils/getAllChanged';
import CourseLikeAssignment from '@/assignments/models/CourseLikeAssignment';
import {BatchRequestDto} from '@/batch/types/BatchRequestDto';
import {BatchRequestBuilder} from '@/batch/builders/BatchRequestBuilder';
import {inject} from '@/container';
import {ContentNestingService} from '@/orm/services/ContentNestingService';
import {CreateCourseLikeAssignmentData} from '@/assignments/types/CreateCourseLikeAssignmentData';

export const COURSE_LIKE_ASSIGNMENTS_BASE_URL = `${API_BASE_URL}/course-like-assignments`;

const {flatten} = inject(ContentNestingService);

/**
 * Fetch one or more CourseLikeAssignments
 * @returns {IStembleAxiosPromise}
 * @param params
 */
export const getCourseLikeAssignments = (
  params: IGetCourseLikeAssignmentsParams = {}
): IStembleAxiosPromise<RawCourseLikeAssignment[]> => {
  return HTTP.get(COURSE_LIKE_ASSIGNMENTS_BASE_URL, {params});
};

export const getCourseLikeAssignmentsForAssignment = (
  assignmentId: number
): IStembleAxiosPromise<RawCourseLikeAssignment[]> => {
  return HTTP.get(`${ASSIGNMENTS_BASE_URL}/${assignmentId}/course-like-assignments`);
};

/**
 * Get a CourseLikeAssignment by ID
 * @param {Number} id
 * @param params
 * @returns {IStembleAxiosPromise}
 */
export const getCourseLikeAssignment = (
  id: number,
  params: IGetCourseLikeAssignmentParams = {}
): IStembleAxiosPromise<RawCourseLikeAssignment> => {
  return HTTP.get(`${COURSE_LIKE_ASSIGNMENTS_BASE_URL}/${id}`, {params});
};

export const batchCourseLikeAssignment = async (
  batch: BatchRequestDto<CourseLikeAssignment>,
  params: IGetCourseLikeAssignmentParams = {}
) => {
  return HTTP.post(`${COURSE_LIKE_ASSIGNMENTS_BASE_URL}/batch`, batch, {params});
};

export const buildBatch = <
  ID extends keyof CourseLikeAssignment,
  K extends keyof Omit<CourseLikeAssignment, ID>,
>(
  changed: Changed<CreateCourseLikeAssignmentData>,
  idProperty: ID,
  keyProperty: K
) => {
  for (const cla of changed.added.concat(changed.kept)) {
    cla.assignmentPolicies = cla.assignmentPolicies?.map((policy) => flatten(policy));
  }
  return new BatchRequestBuilder<Partial<CourseLikeAssignment>, ID, K>(
    COURSE_LIKE_ASSIGNMENTS_BASE_URL,
    idProperty,
    keyProperty
  )
    .withChanges(changed)
    .build();
};

export default {
  getCourseLikeAssignment,
  getCourseLikeAssignments,
};
