import {ActionFactory} from '@/orm/api/actions';
import {
  createCourse,
  deleteCourse,
  getCourse,
  getCourses,
  getCoursesForUser,
  patchCourse,
  putCourse,
} from '@/courses/api/courses';
import {Course} from '@/courses/models';

const factory = new ActionFactory(() => Course);

export const fetch = factory.insertAction(getCourses);
export const fetchForUser = factory.insertAction(getCoursesForUser);
export const get = factory.insertAction(getCourse);
export const create = factory.insertAction(createCourse);
export const post = factory.insertAction(createCourse);
export const put = factory.insertAction(putCourse);
export const update = factory.insertAction(putCourse);
export const patch = factory.insertAction(patchCourse);
export const remove = factory.deleteAction(deleteCourse);
