import {RawIntegerValue} from '@/task-states/types/RawTaskVariableValue';
import {IntegerValue} from '@/task-values/models/IntegerValue';

/**
 * IntegerValue class
 */
export class LegacyIntegerValue extends IntegerValue<number> implements RawIntegerValue {
  static entity = 'LegacyIntegerValue';

  static fields() {
    return {
      ...super.fields(),
      id: this.number(null),
    };
  }
}

export default LegacyIntegerValue;
