import {
  getPaymentRequest,
  getPaymentRequests,
  payWithAccessCode as apiPayWithAccessCode,
  updatePaymentRequest,
} from '@/payments/api';
import {ActionFactory} from '@/orm/api/actions';
import {PaymentRequest} from '@/payments/models/PaymentRequest';

const factory = new ActionFactory(() => PaymentRequest);

export const fetch = factory.insertAction(getPaymentRequests);
export const get = factory.insertAction(getPaymentRequest);
export const payWithAccessCode = factory.insertAction(apiPayWithAccessCode);
export const patch = factory.insertAction(updatePaymentRequest);
