

























import {defineComponent, PropType} from '@vue/composition-api';
import {ConsentCategory} from '@/cookie-consent/types/ConsentCategory';

export enum CookieConsentCategoryEvents {
  OptInToConsentCategory = 'OptInToConsentCategory',
  OptOutOfConsentCategory = 'optOutOfConsentCategory',
}

export default defineComponent({
  name: 'CookieConsentCategory',
  emits: Object.values(CookieConsentCategoryEvents),
  props: {
    initialValue: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    category: {
      type: String as PropType<ConsentCategory>,
      required: true,
    },
    text: {
      type: String as PropType<string>,
      default: '',
    },
  },
  setup(props, {emit}) {
    function updateConsent(payload: boolean) {
      emit(
        payload
          ? CookieConsentCategoryEvents.OptInToConsentCategory
          : CookieConsentCategoryEvents.OptOutOfConsentCategory,
        props.category
      );
    }

    return {updateConsent};
  },
});
