import {Database} from '@vuex-orm/core';

import User from '../users/models/User';
import Institution from '../institutions/models/Institution';
import Task from '@/tasks/models/Task';
import TaskResponse from '@/tasks/models/TaskResponse';
import Assignment from '@/assignments/models/Assignment';
import TaskAssignment from '@/tasks/models/TaskAssignment';
import MultipleChoiceQuestion from '@/tasks/models/MultipleChoiceQuestion';
import {Course, CourseLike, CourseSection} from '@/courses/models';

import MultipleChoiceResponse from '@/tasks/models/MultipleChoiceResponse';
import Grade from '@/grades/models/Grade';
import CalculationQuestion from '@/tasks/models/CalculationQuestion';
import CalculationResponse from '@/tasks/models/CalculationResponse';
import AssignmentExtension from '@/assignments/models/AssignmentExtension';
import Role from '../users/models/Role';
import CourseRole from '../users/models/CourseRole';
import AssignmentGrade from '@/grades/models/AssignmentGrade';
import SectionGrade from '@/grades/models/SectionGrade';
import GradeMatrix from '@/grades/models/GradeMatrix';
import AssignmentAverageGrade from '@/grades/models/AssignmentAverageGrade';
import SectionAverageGrade from '@/grades/models/SectionAverageGrade';
import SectionEvent from '../events/models/SectionEvent';
import EventSchedule from '../events/models/EventSchedule';
import ScheduleMetaEvent from '../events/models/ScheduleMetaEvent';
import InstitutionLocation from '@/locations/models/InstitutionLocation';
import TaskContent from '../tasks/models/content/TaskContent';
import TextContent from '../tasks/models/content/TextContent';
import TaskVariableValue from '@/task-states/models/TaskVariableValue';
import LegacyIntegerValue from '@/task-values/models/legacy/LegacyIntegerValue';
import TaskState from '@/task-states/models/TaskState';
import VariableContent from '../tasks/models/content/VariableContent';
import MathJaxContent from '../tasks/models/content/MathJaxContent';
import TaskFeedback from '@/tasks/models/TaskFeedback';
import BlockContent from '../tasks/models/content/BlockContent';
import LegacyNumberValue from '@/task-values/models/legacy/LegacyNumberValue';
import DynamicQuestion from '@/tasks/models/DynamicQuestion';
import DynamicResponse from '@/tasks/models/DynamicResponse';
import LegacyChemicalElementValue from '@/task-values/models/legacy/LegacyChemicalElementValue';
import LegacyChemicalIsotopeValue from '@/task-values/models/legacy/LegacyChemicalIsotopeValue';
import WatchVideoResponse from '@/tasks/models/WatchVideoResponse';
import WatchVideoTask from '@/tasks/models/WatchVideoTask';
import BooleanTask from '@/tasks/models/BooleanTask';
import BooleanResponse from '@/tasks/models/BooleanResponse';
import ManuallyGradedTask from '@/tasks/models/ManuallyGradedTask';
import CourseLikeAssignment from '../assignments/models/CourseLikeAssignment';
import IssuedTaskState from '@/task-states/models/IssuedTaskState';
import AssignmentPolicy from '../assignment-policies/models/AssignmentPolicy';
import PaymentRequest from '@/payments/models/PaymentRequest';
import TaskResponseTaskFeedback from '@/tasks/models/TaskResponseTaskFeedback';
import TaskTopic from '@/tasks/models/TaskTopic';
import TaskType from '@/tasks/models/TaskType';
import FileUpload from '@/tasks/models/FileUpload';
import ResponseFileUpload from '@/tasks/models/ResponseFileUpload';
import {GradeAdjustment} from '@/grades/models/GradeAdjustment';
import {LateResponsePenaltyPolicy} from '@/assignment-policies/models/LateResponsePenaltyPolicy';
import {StringValue} from '@/task-values/models/StringValue';
import {DateTimeValue} from '@/task-values/models/DateTimeValue';
import {BooleanValue} from '@/task-values/models/BooleanValue';
import {BooleanDistribution} from '@/distributions/models/BooleanDistribution';
import {ChoiceDistribution} from '@/distributions/models/ChoiceDistribution';
import {UniformNumberDistribution} from '@/distributions/models/UniformNumberDistribution';
import {ChemicalElementDistribution} from '@/distributions/models/ChemicalElementDistribution';
import {ChemicalIsotopeDistribution} from '@/distributions/models/ChemicalIsotopeDistribution';
import {DistributionContent} from '@/distributions/models/DistributionContent';
import {TaskValueContent} from '@/task-values/models/TaskValueContent';
import {ChemicalElementValue} from '@/task-values/models/ChemicalElementValue';
import {ChemicalIsotopeValue} from '@/task-values/models/ChemicalIsotopeValue';
import {NumberValue} from '@/task-values/models/NumberValue';
import {IntegerValue} from '@/task-values/models/IntegerValue';
import {TaskScopeContent} from '@/tasks/models/TaskScopeContent';
import {InstitutionScope} from '@/tasks/models/InstitutionScope';
import {DataDrivenTask} from '@/tasks/models/DataDrivenTask';
import {DataDrivenResponse} from '@/tasks/models/DataDrivenResponse';

const database = new Database();

// Register models and their respective modules
// Signature is (Model, module)

// Users and institutions
database.register(User);
database.register(Institution);

database.register(Role);

// Courses and sections
database.register(Course);
database.register(CourseSection);
database.register(CourseRole);
database.register(CourseLikeAssignment);

// Assignments
database.register(Assignment);
database.register(TaskAssignment);
database.register(AssignmentExtension);

// AssignmentPolicies
database.register(AssignmentPolicy);
database.register(LateResponsePenaltyPolicy);

// Tasks
database.register(Task);

// Task sub-types
database.register(MultipleChoiceQuestion);
database.register(CalculationQuestion);
database.register(DynamicQuestion);
database.register(DataDrivenTask);
database.register(BooleanTask);
database.register(WatchVideoTask);
database.register(ManuallyGradedTask);

// Responses
database.register(TaskResponse);

// Response sub-types
database.register(MultipleChoiceResponse);
database.register(CalculationResponse);
database.register(DynamicResponse);
database.register(DataDrivenResponse);
database.register(BooleanResponse);
database.register(WatchVideoResponse);

// Feedback
database.register(TaskFeedback);
database.register(TaskResponseTaskFeedback);

// Question Content
database.register(TaskContent);

// Question Content sub-types
database.register(TextContent);
database.register(VariableContent);
database.register(MathJaxContent);
database.register(BlockContent);

// Legacy Variable Values
database.register(TaskVariableValue);
database.register(LegacyIntegerValue);
database.register(LegacyNumberValue);
database.register(LegacyChemicalElementValue);
database.register(LegacyChemicalIsotopeValue);

// Question State
database.register(TaskState);
database.register(IssuedTaskState);

// Grades
database.register(Grade);
database.register(AssignmentGrade);
database.register(SectionGrade);
database.register(GradeMatrix);
database.register(AssignmentAverageGrade);
database.register(SectionAverageGrade);
database.register(GradeAdjustment);

// Events
database.register(SectionEvent);

database.register(EventSchedule);
database.register(ScheduleMetaEvent);

// Locations
database.register(InstitutionLocation);

// Payments
database.register(PaymentRequest);

// Task Topics
database.register(TaskTopic);

// Task Types
database.register(TaskType);

// File Uploads
database.register(FileUpload);
database.register(ResponseFileUpload);

// Task Values
database.register(BooleanValue);
database.register(ChemicalElementValue);
database.register(ChemicalIsotopeValue);
database.register(DateTimeValue);
database.register(NumberValue);
database.register(IntegerValue);
database.register(StringValue);
database.register(TaskValueContent);

// Distributions
database.register(BooleanDistribution);
database.register(ChoiceDistribution);
database.register(UniformNumberDistribution);
database.register(ChemicalElementDistribution);
database.register(ChemicalIsotopeDistribution);
database.register(DistributionContent);

// Task Scopes
database.register(TaskScopeContent);
database.register(InstitutionScope);

export default database;
