import {Fields} from '@vuex-orm/core';
import * as TaskTypeApi from '@/tasks/api/orm/TaskTypeApi';
import {RawTaskType} from '@/tasks/types/RawTaskType';
import {StembleModel} from '@/common/models/StembleModel';

/**
 * TaskTopic class
 */
export class TaskType extends StembleModel implements RawTaskType {
  static entity = 'TaskType';

  id!: number;
  description!: string;

  static fields(): Fields {
    return {
      id: this.number(null),
      description: this.string(null),
    };
  }

  static get api() {
    return TaskTypeApi;
  }
}

export default TaskType;
