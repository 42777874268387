import {Fields, Model, Query} from '@vuex-orm/core';
import TaskState from './TaskState';
import {Joined} from '@/orm/types/Joined';
import * as IssuedTaskStateApi from '@/task-states/api/orm/IssuedTaskStateApi';
import {IIssuedTaskStateQueryParameters} from '@/task-states/types/IIssuedTaskStateQueryParameters';
import {IVariableMapping} from '@/task-states/types/IVariableMapping';
import {RawIssuedTaskState} from '@/task-states/types/RawIssuedTaskState';

/**
 * IssuedTaskState class
 */
export class IssuedTaskState<VariableMapping extends IVariableMapping = IVariableMapping>
  extends Model
  implements Omit<RawIssuedTaskState, 'taskState'>
{
  static entity = 'IssuedTaskState';
  static mapping: {
    [userId: string]: {[assignmentId: string]: {[taskId: string]: number}};
  } = {};

  id!: number;
  createdAt!: string;
  userId!: number;
  taskId!: number;
  assignmentId!: number;
  taskStateId!: number;
  taskState!: Joined<TaskState<VariableMapping>>;

  static fields(): Fields {
    return {
      id: this.number(null),
      createdAt: this.string(null),
      userId: this.number(null),
      taskId: this.number(null),
      assignmentId: this.number(null),
      taskStateId: this.number(null),
      taskState: this.belongsTo(TaskState, 'taskStateId'),
    };
  }

  static get api() {
    return IssuedTaskStateApi;
  }

  /**
   * Full query method
   * @param options
   * @returns {Query<IssuedTaskState>}
   */
  static fullQuery(options: IIssuedTaskStateQueryParameters = {}) {
    let q = options.query ?? this.query();

    if (options.taskState ?? true) {
      q = q.with('taskState', (query2) =>
        TaskState.fullQuery({
          ...options.taskStateParams,
          query: query2 as Query<TaskState>,
        })
      );
    }

    return q;
  }

  /**
   * Builds a query pre-populated with where and orderBy
   * @param userId
   * @param assignmentId
   * @param taskId
   * @returns {Query<Grade>}
   */
  static queryByUserAssignmentTask(userId: number, assignmentId: number, taskId: number) {
    const query = this.query().whereId(this.getMapValue(userId, assignmentId, taskId));
    return query.orderBy('createdAt', 'desc');
  }

  /**
   * Gets an ID mapping, guaranteeing a return value of the ID or undefined.
   *
   * @param userId
   * @param assignmentId
   * @param taskId
   * @returns {*}
   */
  static getMapValue(userId: number, assignmentId: number, taskId: number) {
    const userObj = this.mapping[userId] || {};
    const assignmentIdString = assignmentId.toString() || 'null';
    const assignmentObj = userObj[assignmentIdString] || {};
    return assignmentObj[taskId];
  }

  static beforeCreate(model: IssuedTaskState) {
    IssuedTaskState.updateMap(model);
  }

  static beforeUpdate(model: IssuedTaskState) {
    IssuedTaskState.updateMap(model);
  }

  // Lifecycle hooks to maintain the mapping
  static updateMap(model: IssuedTaskState) {
    this.mapping[model.userId] = this.mapping[model.userId] || {};

    const assignmentId = model.assignmentId || 'null';
    this.mapping[model.userId][assignmentId] = this.mapping[model.userId][assignmentId] || {};

    this.mapping[model.userId][assignmentId][model.taskId] = model.id;
  }

  static deleteMapEntry(model: any) {
    const assignmentId = model.assignmentId || 'null';
    const userLevel = this.mapping[model.userId];
    if (userLevel) {
      const assignmentLevel = userLevel[assignmentId];
      if (assignmentLevel) {
        delete this.mapping[model.userId][assignmentId][model.taskId];
      }
    }
  }
}

export default IssuedTaskState;
