import BaseGrade from './BaseGrade';
import {CourseSection} from '@/courses/models';
import {Joined} from '@/orm/types/Joined';
import {Fields} from '@vuex-orm/core';

/**
 * SectionAverageGrade class
 *
 * Represents an average grade for a section
 */
export class SectionAverageGrade extends BaseGrade {
  static entity = 'SectionAverageGrade';
  static mapping: {[sectionId: number]: number} = {};

  id!: number;
  sectionId!: number;
  section!: Joined<CourseSection>;

  static fields(): Fields {
    return {
      ...super.fields(),
      id: this.increment(),
      sectionId: this.number(null),
      section: this.belongsTo(CourseSection, 'sectionId'),
    };
  }

  /**
   * Builds a query pre-populated with the correct sectionId
   * @param sectionId
   * @returns {Query<SectionAverageGrade>}
   */
  static queryBySection(sectionId: number) {
    return this.query().whereId(this.getMapValue(sectionId));
  }

  /**
   * Gets an ID mapping, guaranteeing a return value of the ID or undefined.
   *
   * @param sectionId
   * @returns {*}
   */
  static getMapValue(sectionId: number) {
    return this.mapping[sectionId];
  }

  // Lifecycle hooks to maintain the mapping
  static updateMap(model: SectionAverageGrade) {
    this.mapping[model.sectionId] = model.id;
  }

  static deleteMapEntry(model: SectionAverageGrade) {
    delete this.mapping[model.sectionId];
  }
}

export default SectionAverageGrade;
