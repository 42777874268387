import Vue from 'vue';
import {Store} from 'vuex';
import {makeGlobalSingleton} from '@/container';
import {LoadingFlagsState} from '@/loading/types/LoadingFlagsState';
import StembleStore from '@/store/store';
import {LoadingFlag} from '@/loading/types/LoadingFlags';
import {FlagStates} from '@/loading/types/FlagStates';

export const LoadingFlagsService = makeGlobalSingleton(
  (store: Store<LoadingFlagsState> = StembleStore, moduleName: string = 'loadingFlags') => {
    return new LoadingFlagsServiceClass(store, moduleName);
  }
);

export class LoadingFlagsServiceClass {
  protected store: Store<LoadingFlagsState>;
  protected moduleName: string;

  constructor(store: Store<LoadingFlagsState>, moduleName: string = 'loadingFlags') {
    this.store = store;
    this.moduleName = moduleName;
    this.registerModule();
  }

  protected registerModule() {
    this.store.registerModule(this.moduleName, {
      namespaced: true,
      state: {
        loadingFlags: {},
      },
      mutations: {
        setFlag(
          state: LoadingFlagsState,
          options: {name: string; type: FlagStates; value: boolean | null}
        ) {
          const {name, type, value} = options;
          state.loadingFlags = {
            [name]: {},
            ...state.loadingFlags,
          };
          state.loadingFlags[name][type] = value;
        },
        clearFlag(state: LoadingFlagsState, options: {name: string; type: FlagStates}) {
          const {name, type} = options;
          if (
            state.loadingFlags?.[name] !== undefined &&
            state.loadingFlags?.[name][type] !== undefined
          ) {
            Vue.delete(state.loadingFlags[name], type);
          }
        },
        clearFlags(state: LoadingFlagsState, options: {name: string}) {
          const {name} = options;
          if (state.loadingFlags?.[name] !== undefined) {
            Vue.delete(state.loadingFlags, name);
          }
        },
        clearAllFlags(state: LoadingFlagsState) {
          if (state.loadingFlags === undefined) {
            return;
          }
          for (const key of Object.keys(state.loadingFlags)) {
            Vue.delete(state.loadingFlags, key);
          }
        },
      },
      getters: {
        getFlag:
          (state: LoadingFlagsState) =>
          (name: string, type: FlagStates = FlagStates.Loaded) => {
            if (
              name &&
              type &&
              state.loadingFlags?.[name] !== undefined &&
              state.loadingFlags[name][type] !== undefined
            ) {
              return state.loadingFlags[name][type];
            }
            return null;
          },
      },
    });
  }

  protected getFlag(name: LoadingFlag, type: FlagStates = FlagStates.Loaded) {
    return this.store.getters[`${this.moduleName}/getFlag`](name.toString(), type);
  }

  protected setFlag(name: LoadingFlag, type: FlagStates, value: boolean | null = true) {
    this.store.commit(`${this.moduleName}/setFlag`, {
      name: name.toString(),
      type,
      value,
    });
  }

  clearFlag(name: LoadingFlag, type: FlagStates) {
    this.store.commit(`${this.moduleName}/clearFlags`, {
      name: name.toString(),
      type,
    });
  }

  clearFlags(name: LoadingFlag) {
    this.store.commit(`${this.moduleName}/clearFlags`, {name: name.toString()});
  }

  clearAllFlags() {
    this.store.commit(`${this.moduleName}/clearAllFlags`);
  }

  isLoading(name: LoadingFlag) {
    return this.getFlag(name, FlagStates.Loading);
  }

  setLoading(name: LoadingFlag, value = true) {
    this.setFlag(name, FlagStates.Loading, value);
  }

  isLoaded(name: LoadingFlag) {
    return this.getFlag(name, FlagStates.Loaded);
  }

  setLoaded(name: LoadingFlag, value: boolean = true) {
    return this.setFlag(name, FlagStates.Loaded, value);
  }

  isError(name: LoadingFlag) {
    return this.getFlag(name, FlagStates.Error);
  }

  setError(name: LoadingFlag, value = true) {
    return this.setFlag(name, FlagStates.Error, value);
  }

  isResolved(name: LoadingFlag) {
    return this.isLoaded(name) || this.isError(name);
  }

  /**
   * Build a handler for a successful response to set a flag to loaded
   */
  protected thenHandler(name: LoadingFlag) {
    return (response: any) => {
      this.setLoading(name, false);
      this.setLoaded(name, true);
      return response;
    };
  }

  /**
   * Build a catch handler to set the error state and loading flags
   */
  protected catchHandler(name: LoadingFlag) {
    return (err: Error) => {
      this.setLoading(name, false);
      this.setError(name, true);
      throw err;
    };
  }

  /**
   * Build both handlers for an api request promise
   */
  protected handlers(name: LoadingFlag) {
    return [this.thenHandler(name), this.catchHandler(name)];
  }

  /**
   * Builds a loading handler to handle the full flow.
   */
  async loadingHandler(name: LoadingFlag, promise: Promise<any> | (() => Promise<any>)) {
    this.clearFlags(name);
    this.setLoading(name, true);

    if (promise instanceof Function) {
      try {
        promise = promise();
      } catch (e) {
        this.setLoading(name, false);
        throw e;
      }
    }
    return promise.then(...this.handlers(name));
  }
}
