import {Fields} from '@vuex-orm/core';
import {StembleModel} from '@/common/models/StembleModel';

/**
 * Location class
 */
export class Location extends StembleModel {
  static entity = 'Location';

  id!: number;
  room!: string;
  capacity!: number;
  building!: string | null;
  campus!: string | null;

  static fields(): Fields {
    return {
      id: this.number(null),
      room: this.string(null),
      capacity: this.number(null),
      building: this.string(''),
      campus: this.string(null),
    };
  }
}

export default Location;
