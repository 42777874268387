import {HTTP} from '@/common/api/http';
import {API_BASE_URL} from '@/common/api/base';
import {IStembleAxiosPromise} from '@/common/api/types/IStembleAxiosPromise';
import {IGetTaskTopicsParams} from '@/tasks/types/IGetTaskTopicsParams';

export const TASK_TOPICS_BASE_URL = API_BASE_URL + '/task-topics';

/**
 * Get many task topics
 * @returns {IStembleAxiosPromise}
 * @param params
 */
export const getTopics = (params: IGetTaskTopicsParams = {}): IStembleAxiosPromise => {
  return HTTP.get(TASK_TOPICS_BASE_URL, {params});
};

export default {
  getTopics,
};
