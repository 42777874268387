import {FileUpload} from '@/tasks/models/FileUpload';
import {Fields} from '@vuex-orm/core';
import TaskResponse from '@/tasks/models/TaskResponse';
import {Joined} from '@/orm/types/Joined';

export class ResponseFileUpload extends FileUpload {
  static entity = 'ResponseFileUpload';

  taskResponseId!: number;
  part!: string | null;
  taskResponse!: Joined<TaskResponse>;

  static fields(): Fields {
    return {
      ...super.fields(),
      taskResponseId: this.number(null),
      part: this.string(null).nullable(),
      taskResponse: this.belongsTo(TaskResponse, 'taskResponseId'),
    };
  }
}

export default ResponseFileUpload;
