import {Fields, Model} from '@vuex-orm/core';
import * as FileUploadApi from '@/uploads/api/orm/FileUploadApi';
import {RawFileUpload} from '@/tasks/types/RawFileUpload';
import {FileUploadLinkedEntity} from '@/tasks/types/FileUploadLinkedEntity';

/**
 * FileUpload class
 */
export class FileUpload<LinkedType extends FileUploadLinkedEntity = FileUploadLinkedEntity>
  extends Model
  implements RawFileUpload
{
  static entity = 'FileUpload';

  id!: number;
  filename!: string;
  ownerId!: number;
  owner!: string;
  createdAt!: string;
  linkedEntityType!: LinkedType;
  linkedEntityId!: number;

  static fields(): Fields {
    return {
      id: this.number(null),
      filename: this.string(''),
      ownerId: this.number(null),
      owner: this.string(null),
      createdAt: this.string(null),
      linkedEntityType: this.string(null),
      linkedEntityId: this.number(null),
    };
  }

  static get api() {
    return FileUploadApi;
  }
}

export default FileUpload;
