import {HTTP} from '@/common/api/http';
import {API_BASE_URL} from '@/common/api/base';

export const SCHEDULES_BASE_URL = API_BASE_URL + '/event-schedules';

/**
 * Get all event schedules
 *
 * Courses and/or sections can be provided as a single int or array of ints
 *
 * @param {Number|Array} course filter by course ID
 * @param {Number|Array} section filter by section ID
 * @param {Boolean} includeEvents if true, include the schedule's events
 * @returns {Promise<AxiosPromise<any>>}
 */
export const getEventSchedules = async ({course, section, includeEvents = true} = {}) => {
  const params = {course, section, includeEvents};
  return HTTP.get(`${SCHEDULES_BASE_URL}`, {params});
};

/**
 * Get an event schedule by ID
 * @param {Number} id
 * @param {Boolean} includeEvents if true, include the schedule's events
 * @returns {Promise<AxiosPromise<any>>}
 */
export const getEventSchedule = async (id, {includeEvents = true} = {}) => {
  const params = {includeEvents};
  return HTTP.get(`${SCHEDULES_BASE_URL}/${id}`, {params});
};

/**
 * Create a new event schedule
 * @param {Object} data
 * @returns {Promise<AxiosPromise<any>>}
 */
export const createEventSchedule = async (data) => HTTP.post(`${SCHEDULES_BASE_URL}`, data);

/**
 * Overwrite an existing event schedule
 * @param {Number} id
 * @param {Object} data
 * @returns {Promise<AxiosPromise<any>>}
 */
export const putEventSchedule = async (id, data) => {
  return HTTP.put(`${SCHEDULES_BASE_URL}/${id}`, data);
};

/**
 * Patch over an existing event schedule
 * @param {Number} id
 * @param {Object} data
 * @returns {Promise<AxiosPromise<any>>}
 */
export const patchEventSchedule = async (id, data) => {
  return HTTP.patch(`${SCHEDULES_BASE_URL}/${id}`, data);
};

/**
 * Delete an event schedule and its meta-events
 * @param {Number} id
 * @returns {Promise<AxiosPromise>}
 */
export const deleteEventSchedule = async (id) => {
  return HTTP.delete(`${SCHEDULES_BASE_URL}/${id}`);
};

export default {
  getEventSchedule,
  getEventSchedules,
  createEventSchedule,
  putEventSchedule,
  patchEventSchedule,
  deleteEventSchedule,
};
