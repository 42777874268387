




















































import {defineComponent, PropType, ref} from '@vue/composition-api';
import {ConsentCategory} from '@/cookie-consent/types/ConsentCategory';
import CookieConsentCategory, {
  CookieConsentCategoryEvents,
} from '@/cookie-consent/components/CookieConsentCategory.vue';

export enum CookeConsentSettingsEvents {
  SubmitCookieConsentPreferences = 'SubmitCookieConsentPreferences',
}

export default defineComponent({
  name: 'CookieConsentSettings',
  components: {CookieConsentCategory},
  emits: Object.values(CookeConsentSettingsEvents),
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  props: {
    modelValue: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  setup(props, {emit}) {
    const categories = ref([ConsentCategory.Necessary]);

    const consentableCategories = [
      {
        category: ConsentCategory.Functional,
        textKey: `cookieConsent.descriptions.${ConsentCategory.Functional}`,
      },
      {
        category: ConsentCategory.Analytics,
        textKey: `cookieConsent.descriptions.${ConsentCategory.Analytics}`,
      },
      {
        category: ConsentCategory.Support,
        textKey: `cookieConsent.descriptions.${ConsentCategory.Support}`,
      },
    ];

    function optIn(category: ConsentCategory) {
      categories.value.push(category);
    }

    function optOut(category: ConsentCategory) {
      categories.value = categories.value.filter((c) => c !== category);
    }

    function acceptAll() {
      emit(CookeConsentSettingsEvents.SubmitCookieConsentPreferences, [ConsentCategory.All]);
      emit('update:modelValue');
    }

    function submitPreferences() {
      emit(
        CookeConsentSettingsEvents.SubmitCookieConsentPreferences,
        categories.value.length === 0 ? [ConsentCategory.Necessary] : categories.value
      );
      emit('update:modelValue');
    }

    return {
      ...CookieConsentCategoryEvents,
      ConsentCategory,
      categories,
      consentableCategories,
      acceptAll,
      optIn,
      optOut,
      submitPreferences,
    };
  },
});
