import {LateResponsePenaltyPolicyContent} from '@/assignment-policies/types/RawLateResponsePenaltyPolicy';
import {Fields} from '@vuex-orm/core';
import {PolicyType} from '@/assignment-policies/types/RawAssignmentPolicy';
import {RawFrequency} from '@/assignment-policies/types/RawFrequency';
import {RawAdjustment} from '@/assignment-policies/types/RawAdjustment';
import {GradeAdjustmentPolicy} from '@/assignment-policies/models/abstract/GradeAdjustmentPolicy';

export class LateResponsePenaltyPolicy
  extends GradeAdjustmentPolicy
  implements LateResponsePenaltyPolicyContent
{
  static entity: string = PolicyType.LateResponsePenaltyPolicy;

  frequency!: RawFrequency;
  penalty!: RawAdjustment;
  submissionCutoff!: string | null;

  static fields(): Fields {
    return {
      frequency: this.attr({unit: null, value: null}),
      penalty: this.attr({type: null, value: null}),
      submissionCutoff: this.string(null).nullable(),
    };
  }
}
