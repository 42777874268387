import {HTTP} from '@/common/api/http';
import {SCHEDULES_BASE_URL} from './event-schedules';

/**
 * Get all of the events currently in an event schedule
 * @param {int} scheduleId
 * @returns {AxiosPromise<any>}
 */
export const getEventsFor = (scheduleId) => HTTP.get(`${SCHEDULES_BASE_URL}/${scheduleId}/events`);

/**
 * Add events to an event schedule.
 *
 * eventIds and metaEventIds can be both integers, or both equal-length
 * arrays, where each eventId is paired with a metaEventId.
 *
 * @param {Number} scheduleId
 * @param {Number|Array} eventIds
 * @param {Number|Array} metaEventIds
 * @returns {AxiosPromise<any>}
 */
export const addEventsTo = (scheduleId, eventIds, metaEventIds) => {
  return HTTP.post(`${SCHEDULES_BASE_URL}/${scheduleId}/events`, {
    id: eventIds,
    metaEventId: metaEventIds,
  });
};

/**
 * Patch events in the event schedule.
 *
 * Events can be patched across the entire schedule, sections, metaEvents, or
 * a single event.
 *
 * If neither sectionId nor metaEventId are provided, patch the entire schedule.
 * If one of sectionId or metaEventId are provided, patch for each event along
 *   that row/column.
 * If both sectionId and metaEventId are provided, patch only the event at that
 *   position in the schedule.
 *
 * @param {Number} scheduleId
 * @param {Object} data
 * @param {Number|Undefined} sectionId
 * @param {Number|Undefined} metaEventId
 * @returns {AxiosPromise<any>}
 */
export const patchEventsIn = (scheduleId, data, {sectionId, metaEventId} = {}) => {
  const params = {section: sectionId, metaEventId: metaEventId};
  return HTTP.patch(`${SCHEDULES_BASE_URL}/${scheduleId}/events`, data, {
    params,
  });
};

/**
 * Remove one or more events from an event schedule.
 * @param {Number} scheduleId
 * @param {Number|Array} eventIds
 * @returns {AxiosPromise}
 */
export const removeEventsFrom = (scheduleId, eventIds) => {
  const params = {id: eventIds};
  return HTTP.delete(`${SCHEDULES_BASE_URL}/${scheduleId}/events`, {params});
};

/**
 * Remove a single event from the event schedule
 * @param {Number} scheduleId
 * @param {Number} eventId
 * @returns {AxiosPromise}
 */
export const removeEventFrom = (scheduleId, eventId) =>
  HTTP.delete(`${SCHEDULES_BASE_URL}/${scheduleId}/events/${eventId}`);

export default {
  getEventsFor,
  addEventsTo,
  patchEventsIn,
  removeEventsFrom,
  removeEventFrom,
};
