import {inject, makeGlobalSingleton} from '@/container';
import {CookieConsentService} from '@/cookie-consent/types/CookieConsentService';
import {ConsentCategory} from '@/cookie-consent/types/ConsentCategory';
import {
  CookieConsentCallback,
  CookieConsentCallbacks,
} from '@/cookie-consent/types/CookieConsentCallbacks';
import {DependencyType} from '@/container/types/DependencyType';
import {FeatureFlag, FeatureFlagService} from '@/feature-flags';

export const CONSENT_KEY = 'tracker-consent';

export const CONSENT_EVENT = 'consentDialogAnswered';

export const UseCookieConsentService = makeGlobalSingleton(() =>
  makeCookieConsentService(inject(FeatureFlagService))
);

export function makeCookieConsentService(
  featureFlagService: DependencyType<typeof FeatureFlagService>,
  eventType: string = CONSENT_EVENT,
  storageKey: string = CONSENT_KEY
): CookieConsentService {
  const callbacks: CookieConsentCallbacks = [];

  function getConsent(): ConsentCategory | null {
    const value = window.localStorage.getItem(storageKey);
    if (value !== null) {
      return JSON.parse(value);
    } else {
      return null;
    }
  }

  function setConsent(value: ConsentCategory[]) {
    window.localStorage.setItem(storageKey, JSON.stringify(value));
  }

  const answeredDialog = () => getConsent() !== null;

  function consentGiven(category: ConsentCategory) {
    const consent = getConsent(); // inefficient, but keeps service simple

    return consent ? consent.includes(ConsentCategory.All) || consent.includes(category) : false;
  }

  function setListener() {
    window.addEventListener(eventType, (event) => {
      const _event = event as CustomEvent<ConsentCategory[]>;
      setConsent(_event.detail);
      runCallbacks();
    });
  }

  function emitConsentEvent(payload: ConsentCategory[]) {
    window.dispatchEvent(new CustomEvent(eventType, {detail: payload}));
  }

  function addCallback(category: ConsentCategory, callback: CookieConsentCallback) {
    callbacks.push({category, callback});
  }

  function runCallbacks() {
    if (!answeredDialog() && featureFlagService.isEnabled(FeatureFlag.CookieConsent)) {
      setListener();
    } else {
      callbacks.map(({category, callback}) => {
        if (
          !featureFlagService.isEnabled(FeatureFlag.CookieConsent) ||
          consentGiven(category as ConsentCategory)
        ) {
          callback();
        }
      });
    }
  }

  return {
    addCallback,
    runCallbacks,
    answeredDialog,
    consentGiven,
    emitConsentEvent,
    setConsent,
    getConsent,
  };
}
