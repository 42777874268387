var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"conversation-page"},[_c('div',{staticClass:"conversation-page__inner"},[_c('h2',{staticClass:"page-title"},[_vm._v("Conversations")]),_c('div',{staticClass:"conversation-list"},[(_vm.conversations.length > 0)?_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.conversations,"item-key":"id","items-per-page":5,"item-class":_vm.unreadMessageCheck},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var items = ref.items;
return [_c('tbody',_vm._l((items),function(item){return _c('tr',{key:item.id,staticClass:"chatTable",on:{"click":function($event){return _vm.onRowClick(item)}}},[_c('td',{},[_c('span',{class:item.unreadMessage ? 'name-wrapper name-wrapper--bold' : 'name-wrapper'},[(item.unreadMessage)?_c('span',{staticClass:"notification-light"}):_vm._e(),_vm._v(" "+_vm._s(_vm.otherUser(item).firstName + ' ' + _vm.otherUser(item).lastName))])])])}),0)]}}],null,false,1655195781)}):(!_vm.isConversationsLoading)?_c('div',{staticClass:"conversations-placeholder"},[_vm._v(" You don't have any conversations. ")]):_vm._e(),_c('v-dialog',{attrs:{"max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var props = ref.props;
return [_c('v-btn',_vm._b({directives:[{name:"show",rawName:"v-show",value:(!_vm.$gate.isFaculty()),expression:"!$gate.isFaculty()"}],staticClass:"add-button",attrs:{"rounded":"","small":"","color":"primary"},on:{"click":function($event){_vm.dialog = true}}},'v-btn',props,false),[_c('v-icon',{staticClass:"add-button__icon"},[_vm._v("mdi-plus")]),_vm._v(" New Conversation ")],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Instructors")]),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.instructors,"item-key":"user_id","items-per-page":5,"item-class":_vm.unreadMessageCheck},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var items = ref.items;
return [_c('tbody',_vm._l((items),function(item){return _c('tr',{key:item.id,staticClass:"chatTable",style:(item.unreadMessage ? 'font-weight: bold; background-color: aliceblue;' : ''),on:{"click":function($event){return _vm.addConversation(item)}}},[_c('td',[_vm._v(_vm._s(item.first_name + ' ' + item.last_name))])])}),0)]}}])})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"block":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("Close Dialog")])],1)],1)],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }