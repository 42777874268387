import {
  createMetaEvent as apiCreateMetaEvent,
  deleteMetaEvent as apiDeleteMetaEvent,
  getMetaEvent as apiGetMetaEvent,
  getMetaEventsFor as apiGetMetaEventsFor,
  patchMetaEvent as apiPatchMetaEvent,
  putMetaEvent as apiPutMetaEvent,
} from '@/events/api/meta-events';
import {ActionFactory} from '@/orm/api/actions';
import ScheduleMetaEvent from '@/events/models/ScheduleMetaEvent';

const factory = new ActionFactory(() => ScheduleMetaEvent);

export const getMetaEvent = factory.insertAction(apiGetMetaEvent);
export const getMetaEventsFor = factory.insertAction(apiGetMetaEventsFor);
export const createMetaEvent = factory.insertAction(apiCreateMetaEvent);
export const putMetaEvent = factory.insertAction(apiPutMetaEvent);
export const patchMetaEvent = factory.insertAction(apiPatchMetaEvent);
export const deleteMetaEvent = factory.deleteAction(apiDeleteMetaEvent);
