



















import {defineComponent, ref, watch} from '@vue/composition-api';
import {useVersionCheckService} from '@/versioning/services/VersionCheckService';

export default defineComponent({
  name: 'SVersionSnackbar',
  setup() {
    const versionService = useVersionCheckService();
    const isOpen = ref(false);
    const refreshWindow = () => {
      window.location.reload();
    };
    watch(
      () => versionService.isUpToDate,
      (newValue) => {
        // Check for false because newValue may be null
        if (newValue === false) {
          isOpen.value = true;
        }
      }
    );

    return {isOpen, refreshWindow};
  },
});
