import {Fields} from '@vuex-orm/core';
import {
  AdjustmentDirection,
  GradeAdjustmentType,
  RawGradeAdjustment,
} from '@/grades/types/RawGradeAdjustment';
import {Joined} from '@/orm/types/Joined';
import Grade from '@/grades/models/Grade';
import AssignmentPolicy from '@/assignment-policies/models/AssignmentPolicy';
import {GradeAdjustmentQueryParameters} from '@/grades/types/GradeAdjustmentQueryParameters';
import {StembleModel} from '@/common/models/StembleModel';

export class GradeAdjustment extends StembleModel implements RawGradeAdjustment {
  static entity: string = 'GradeAdjustment';

  id!: number;
  adjustmentType!: GradeAdjustmentType;
  value!: number;
  grade!: Joined<Grade>;
  gradeId!: number;
  policy!: Joined<AssignmentPolicy>;
  policyId!: number | null;
  reason!: string | null;
  submittedById!: number | null;
  submittedByName!: string | null;

  static fields(): Fields {
    return {
      adjustmentType: this.string(GradeAdjustmentType.Relative),
      value: this.number(0),
      grade: this.belongsTo(Grade, 'gradeId'),
      gradeId: this.number(null),
      policy: this.belongsTo(AssignmentPolicy, 'policyId'),
      policyId: this.number(null).nullable(),
      reason: this.string(null).nullable(),
      submittedById: this.number(0).nullable(),
      submittedByName: this.string(null).nullable(),
    };
  }

  static fullQuery({query, policy = true, grade = false}: GradeAdjustmentQueryParameters) {
    let q = query ?? this.query();

    if (policy) {
      q = q.with('policy');
    }

    if (grade) {
      q = q.with('grade');
    }

    return q;
  }

  penaltyOrBonus(): AdjustmentDirection {
    return this.value >= 0 ? AdjustmentDirection.Bonus : AdjustmentDirection.Penalty;
  }

  isPenalty() {
    return this.penaltyOrBonus() === AdjustmentDirection.Penalty;
  }

  isBonus() {
    return !this.isPenalty();
  }

  isRelative() {
    return this.adjustmentType === GradeAdjustmentType.Relative;
  }

  isAbsolute() {
    return !this.isRelative();
  }
}
