import {HTTP} from '@/common/api/http';
import {SCHEDULES_BASE_URL} from './event-schedules';

/**
 * Get the sections currently in an event schedule
 * @param {Number} scheduleId
 * @returns {AxiosPromise<any>}
 */
export const getSectionsIn = (scheduleId) =>
  HTTP.get(`${SCHEDULES_BASE_URL}/${scheduleId}/sections`);

/**
 * Add one or more sections to an event schedule
 * @param {Number} scheduleId
 * @param {Number|Array} sectionIds
 * @returns {AxiosPromise<any>}
 */
export const addSectionsTo = (scheduleId, sectionIds) =>
  HTTP.post(`${SCHEDULES_BASE_URL}/${scheduleId}`, {
    id: sectionIds,
  });

/**
 * Remove many or all sections from an event schedule.
 * @param {Number} scheduleId
 * @param {Number|Array} sectionIds
 * @returns {AxiosPromise}
 */
export const removeSectionsFrom = (scheduleId, sectionIds) => {
  const params = {id: sectionIds};
  return HTTP.delete(`${SCHEDULES_BASE_URL}/${scheduleId}`, {params});
};

/**
 * Remove a single section from an event schedule
 * @param {Number} scheduleId
 * @param {Number} sectionId
 * @returns {AxiosPromise}
 */
export const removeSectionFrom = (scheduleId, sectionId) =>
  HTTP.delete(`${SCHEDULES_BASE_URL}/${scheduleId}/sections/${sectionId}`);

export default {
  getSectionsIn,
  addSectionsTo,
  removeSectionFrom,
  removeSectionsFrom,
};
