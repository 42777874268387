import {HTTP} from '@/common/api/http';
import {COURSES_BASE_URL} from './courses';
import {IGetSectionParams} from '@/courses/types/IGetSectionParams';
import {IGetSectionsParams} from '@/courses/types/IGetSectionsParams';
import {IStembleAxiosPromise} from '@/common/api/types/IStembleAxiosPromise';
import {IGetParticipantsForSectionParams} from '@/courses/types/IGetParticipantsForSectionParams';
import {CreateCourseSectionData} from '@/courses/types/CreateCourseSectionData';

export function remapSection(data: Partial<CreateCourseSectionData>) {
  // TODO: once API is fixed, remove remap
  return {
    ...data,
    title: data.name,
  };
}

/**
 * Get sections for a course
 * @param {Number} courseId
 * @param params
 * @returns {IStembleAxiosPromise}
 */
export const getSections = async (
  courseId: number,
  params: IGetSectionsParams
): IStembleAxiosPromise => {
  return HTTP.get(`${COURSES_BASE_URL}/${courseId}/sections`, {params});
};

/**
 * Get a section
 * @param {Number} courseId
 * @param {Number} sectionId
 * @param params
 * @returns {IStembleAxiosPromise}
 */
export const getSection = async (
  courseId: number,
  sectionId: number,
  params: IGetSectionParams = {}
): IStembleAxiosPromise => {
  return HTTP.get(`${COURSES_BASE_URL}/${courseId}/sections/${sectionId}`, {
    params,
  });
};

/**
 * Create a new section within a course
 * @param {Number} courseId
 * @param {Object} data
 * @return {IStembleAxiosPromise}
 */
export const createSection = async (
  courseId: number,
  data: CreateCourseSectionData
): IStembleAxiosPromise => {
  // TODO: once API is fixed, remove remap
  return HTTP.post(`${COURSES_BASE_URL}/${courseId}/sections`, remapSection(data));
};

/**
 * Patch a section within a course
 * @param {number} courseId
 * @param {number} sectionId
 * @param {any} data
 * @return {IStembleAxiosPromise}
 */
export const patchSection = async (
  courseId: number,
  sectionId: number,
  data: Partial<CreateCourseSectionData>
): IStembleAxiosPromise => {
  // TODO: once API is fixed, remove remap
  return HTTP.patch(`${COURSES_BASE_URL}/${courseId}/sections/${sectionId}`, remapSection(data));
};

/**
 * Delete a section from a course
 * @param {number} courseId
 * @param {number} sectionId
 * @return {IStembleAxiosPromise}
 */
export const deleteSection = async (courseId: number, sectionId: number) => {
  return HTTP.delete(`${COURSES_BASE_URL}/${courseId}/sections/${sectionId}`);
};

/**
 * Gets the participants in a section
 * @param {Number} courseId
 * @param {Number} sectionId
 * @param params
 * @returns {IStembleAxiosPromise}
 */
export const getParticipantsFor = async (
  courseId: number,
  sectionId: number,
  params: IGetParticipantsForSectionParams = {}
) => {
  return HTTP.get(`${COURSES_BASE_URL}/${courseId}/sections/${sectionId}/participants`, {
    params: {
      includeRoles: true,
      ...params,
    },
  });
};

export default {
  getSection,
  getSections,
  createSection,
  getParticipantsFor,
  patchSection,
  deleteSection,
};
