import {Policy} from '@/permissions/Policy';
import {IsOrIsAnyParentOf} from '@/permissions/services/permissions-service/specifications/IsOrIsAnyParentOf';
import {getInstance as getAssignablesService} from '@/assignments/plugins/assignables';
import CourseLikeAssignment from '@/assignments/models/CourseLikeAssignment';
import Assignment from '@/assignments/models/Assignment';
import User from '@/users/models/User';
import {
  IsInheritedOrSectionWithin,
  Specification,
} from '@/permissions/services/permissions-service/specifications';
import {Ability} from '@/permissions/types/Ability';
import {PermissionsService} from '@/permissions/services/PermissionsService';
import AssignablesService from '@/assignments/services/AssignablesService';

export class AssignmentPolicy extends Policy {
  protected assignablesService: AssignablesService;

  constructor(permissionService: PermissionsService) {
    super(permissionService);
    this.assignablesService = getAssignablesService();
  }

  protected createImplementation(user: User, ownerId: number | null = null, checkChildren = false) {
    let spec: Specification | null = null;
    if (ownerId !== null) {
      if (checkChildren) {
        spec = new IsInheritedOrSectionWithin(ownerId);
      } else {
        spec = new IsOrIsAnyParentOf(ownerId);
      }
    }
    return this.permissionService.checkAnyCoursePermission(user, Ability.CreateAssignments, spec);
  }

  /**
   * Permission to create an assignment, optionally in a specific CourseLike
   * @param user
   * @param ownerId
   */
  create(user: User, ownerId: number | null = null) {
    return this.createImplementation(user, ownerId, false);
  }

  /**
   * Permission to create an assignment, optionally in a CourseLike or somewhere within (i.e. sections)
   * @param user
   * @param ownerId
   */
  createIn(user: User, ownerId: number | null = null) {
    return this.createImplementation(user, ownerId, true);
  }

  /**
   * Permission to assign a given assignment to sections they
   * @param user
   * @param assignment
   * @param courseLikeId
   */
  assign(user: User, assignment: Assignment, courseLikeId: number) {
    return user.can('createFor', 'CourseLikeAssignment', assignment, courseLikeId);
  }

  /**
   * Permission to assign somewhere within a CourseLike
   * @param user
   * @param assignment
   * @param courseLikeId
   */
  assignIn(user: User, assignment: Assignment, courseLikeId: number) {
    return user.can('createIn', 'CourseLikeAssignment', assignment, courseLikeId);
  }

  protected checkAssigners(
    user: User,
    assignment: Assignment,
    assignedPredicate?: null | ((cla: CourseLikeAssignment) => boolean),
    unassignedPredicate?: null | (() => boolean),
    alwaysPredicate?: null | (() => boolean)
  ) {
    assignedPredicate = assignedPredicate ?? (() => false);
    unassignedPredicate = unassignedPredicate ?? (() => false);
    alwaysPredicate = alwaysPredicate ?? (() => false);

    if (alwaysPredicate()) {
      return true;
    }

    const validAssigners = this.assignablesService.getAssignersAssignedTo(
      user,
      assignment.courseLikeAssignments
    );
    if (validAssigners.length === 0) {
      return unassignedPredicate();
    }
    for (const validAssigner of validAssigners) {
      if (assignedPredicate(validAssigner)) {
        return true;
      }
    }
    return false;
  }

  view(user: User, assignment: Assignment) {
    return this.checkAssigners(
      user,
      assignment,
      (cla) => user.can('view', 'CourseLikeAssignment', cla),
      null,
      () => this.update(user, assignment) || this.test(user, assignment)
    );
  }

  viewTaskAssignmentsFor(user: User, assignment: Assignment) {
    return this.checkAssigners(
      user,
      assignment,
      (cla) => user.can('viewTaskAssignmentsFor', 'CourseLikeAssignment', cla),
      null,
      () => this.update(user, assignment) || this.test(user, assignment)
    );
  }

  viewGradesFor(user: User, assignment: Assignment) {
    const {ownerId} = assignment;
    return this.checkAssigners(
      user,
      assignment,
      (cla) => user.can('viewGradesFor', 'CourseLikeAssignment', cla),
      () => !!ownerId && user.can('viewGradesFor', 'CourseLike', ownerId)
    );
  }

  submitGradesFor(user: User, assignment: Assignment) {
    const {ownerId} = assignment;
    return this.checkAssigners(
      user,
      assignment,
      (cla) => user.can('submitGradesFor', 'CourseLikeAssignment', cla),
      () => !!ownerId && user.can('submitGradesFor', 'CourseLike', ownerId)
    );
  }

  grantExtensionsFor(user: User, assignment: Assignment) {
    const {ownerId} = assignment;
    return this.checkAssigners(
      user,
      assignment,
      (cla) => user.can('grantExtensionsFor', 'CourseLikeAssignment', cla),
      () => !!ownerId && user.can('grantExtensionsFor', 'CourseLike', ownerId)
    );
  }

  test(user: User, assignment: Assignment) {
    const spec = new IsInheritedOrSectionWithin(
      assignment.assignedCourseLikeIds.concat(assignment.ownerId)
    );
    return this.permissionService.checkAnyCoursePermission(user, Ability.TestAssignments, spec);
  }

  update(user: User, assignment: Assignment) {
    return this.permissionService.checkAnyCoursePermission(
      user,
      Ability.UpdateAssignments,
      new IsOrIsAnyParentOf(assignment.ownerId)
    );
  }
}

export default AssignmentPolicy;
