import {HTTP} from '@/common/api/http';
import {ASSIGNMENTS_BASE_URL} from './assignments';
import {IStembleAxiosPromise} from '@/common/api/types/IStembleAxiosPromise';

function baseUrl(assignmentId: number) {
  return `${ASSIGNMENTS_BASE_URL}/${assignmentId}/task-assignments`;
}

/**
 * Get all task assignments for an assignment
 * @param {number} assignmentId
 * @returns {IStembleAxiosPromise}
 */
export const getTaskAssignments = async (assignmentId: number): IStembleAxiosPromise => {
  return HTTP.get(baseUrl(assignmentId));
};

/**
 * Get a task assignment from an assignment
 * @param {number} assignmentId
 * @param {number} taskAssignmentId
 * @returns {IStembleAxiosPromise}
 */
export const getTaskAssignment = async (
  assignmentId: number,
  taskAssignmentId: number
): IStembleAxiosPromise => {
  return HTTP.get(`${baseUrl(assignmentId)}/${taskAssignmentId}`);
};

/**
 * Add a task to an assignment
 * @param {Number} assignmentId
 * @param data
 * @returns {IStembleAxiosPromise}
 */
export const createTaskAssignment = async (
  assignmentId: number,
  data: any
): IStembleAxiosPromise => {
  return HTTP.post(baseUrl(assignmentId), data);
};

/**
 * Update a task assignment
 * @param {Number} assignmentId
 * @param {Number} taskAssignmentId
 * @param data
 * @returns {IStembleAxiosPromise}
 */
export const putTaskAssignment = async (
  assignmentId: number,
  taskAssignmentId: number,
  data: any
): IStembleAxiosPromise => {
  return HTTP.put(`${baseUrl(assignmentId)}/${taskAssignmentId}`, data);
};

/**
 * Patch a task assignment
 * @param {Number} assignmentId
 * @param {Number} taskAssignmentId
 * @param data
 * @returns {IStembleAxiosPromise}
 */
export const patchTaskAssignment = async (
  assignmentId: number,
  taskAssignmentId: number,
  data: any
): IStembleAxiosPromise => {
  return HTTP.patch(`${baseUrl(assignmentId)}/${taskAssignmentId}`, data);
};

/**
 * Delete a task assignment
 * @param {Number} assignmentId
 * @param {Number} taskAssignmentId
 * @returns {IStembleAxiosPromise}
 */
export const deleteTaskAssignment = async (
  assignmentId: number,
  taskAssignmentId: number
): IStembleAxiosPromise => {
  return HTTP.delete(`${baseUrl(assignmentId)}/${taskAssignmentId}`);
};

export default {
  getTaskAssignment,
  getTaskAssignments,
  createTaskAssignment,
  putTaskAssignment,
  patchTaskAssignment,
  deleteTaskAssignment,
};
