import Question from './Question';
import {Fields} from '@vuex-orm/core';
import {RawDynamicQuestion} from '@/tasks/types/RawTask';
import {AssistedGradingMap} from '@/tasks/models/AssistedGradingMap';

/**
 * DynamicQuestion class
 */
export class DynamicQuestion extends Question implements RawDynamicQuestion {
  static entity = 'DynamicQuestion';

  questionId!: string;
  hasGrader!: boolean;
  assistedGradingMap!: AssistedGradingMap;
  config!: any;

  static fields(): Fields {
    return {
      ...super.fields(),
      questionId: this.string(''),
      component: this.string(''),
      hasGrader: this.boolean(true),
      assistedGradingMap: this.attr(null),
      config: this.attr({}),
    };
  }
}

export default DynamicQuestion;
