import {ConsoleReporter} from './console-reporter';
import Vue from 'vue';
import ErrorReporter from '@/errors/services/error-reporting/error-reporter';
import {ErrorDialogOptions} from '@/errors/types/ErrorDialogOptions';
import {ErrorHandler} from '@/errors/types/ErrorHandler';
import {LooseError} from '@/errors/types/LooseError';
import {ErrorReportingServiceOptions} from '@/errors/types/ErrorReportingServiceOptions';
import {CustomErrorData} from '@/errors/types/CustomErrorData';
import {ErrorFilter} from '@/errors/types/ErrorFilter';
import {CustomError} from '@/errors/types/CustomError';
import {InjectionKey} from '@/container/types/InjectionKey';
import {isDevelopment} from '@/environment';

export class ErrorReportingService {
  static symbol: InjectionKey<ErrorReportingService> = Symbol();
  static global = true;
  static singleton = true;

  private vue: {errors: CustomError[]};

  protected errorReporters: ErrorReporter[];
  protected errorFilters: ErrorFilter[];
  public catchHandler: ErrorHandler;
  public errorDialogHandler: ErrorHandler;

  constructor(options: ErrorReportingServiceOptions = {}) {
    // By storing errors in a Vue instance, we get reactivity
    this.vue = Vue.observable({
      errors: [] as CustomError[],
    });
    this.errorReporters = options.reporters ?? [new ConsoleReporter()];
    this.errorFilters = options.filters ?? [];

    this.catchHandler = async (err: any) => {
      return this.catchError(err);
    };
    this.errorDialogHandler = async (err: any) => {
      return this.showErrorDialog(err);
    };
  }

  public addErrorReporter(reporter: ErrorReporter) {
    this.errorReporters.push(reporter);
  }

  static factory(options?: ErrorReportingServiceOptions): ErrorReportingService {
    return new ErrorReportingService(options);
  }

  get errors(): CustomError[] {
    return this.vue.errors;
  }

  set errors(value: CustomError[]) {
    this.vue.errors = value;
  }

  get hasErrors(): boolean {
    return this.errors.length > 0;
  }

  /**
   * Flush errors to all reporters
   */
  async flush(): Promise<any[]> {
    return Promise.all(
      this.errorReporters.map((reporter) => {
        return reporter.reportErrors(this.errors);
      })
    )
      .catch((err) => {
        console.error(err);
        return err;
      })
      .then((results) => {
        this.errors = [];
        return results;
      });
  }

  flushToConsole(): void {
    for (const err of this.errors) {
      console.error(err);
    }
  }

  /**
   * Ensures the error is actually of type Error, wrapping if necessary
   * @param err
   * @param customData
   * @protected
   */
  protected convertError(err: any, customData: CustomErrorData | null = null): CustomError {
    try {
      if (err === null) {
        err = new Error('"null" error');
      } else if (err === undefined) {
        err = new Error('"undefined" error');
      }
      if (!(err instanceof Error)) {
        err = new Error(err.toString());
      }
    } catch (e) {
      return e;
    }
    err.customData = customData;
    return err;
  }

  /**
   * Process an error
   * @param err
   */
  protected pushError(err: CustomError): void {
    // Check filters first when in production mode
    for (const filter of this.errorFilters) {
      if (!filter(err)) {
        if (isDevelopment()) {
          console.error('The following error would have been filtered in production: \n', err);
          break;
        } else {
          return;
        }
      }
    }

    // Avoid duplicate errors
    if (!this.errors.includes(err)) {
      this.errors.push(err);
    }
  }

  /**
   * Catch an error without re-throwing it
   * @param err
   * @param customData
   */
  async catchError(err: LooseError, customData: CustomErrorData | null = null): Promise<any[]> {
    err = this.convertError(err, customData);
    this.pushError(err);
    return this.flush();
  }

  /**
   * Catches an error so that it can be logged, but then rethrows it
   * @param err
   * @param customData
   */
  async throwError(err: LooseError, customData: CustomErrorData | null = null): Promise<never> {
    await this.catchError(err, customData);
    throw err;
  }

  /**
   * Shows an error modal where users can flush the error logs
   * @param {Error|null} err
   * @param dialogOptions
   * @returns {Promise<null>}
   */
  async showErrorDialog(err: LooseError, dialogOptions: ErrorDialogOptions = {}) {
    const {title, text, options} = dialogOptions;

    // @ts-ignore until errorDialog plugin types are written
    Vue.errorDialog.open({
      title: title || 'Uh Oh',
      message:
        text ||
        'An unexpected error occurred. The Stemble team has been notified and will do their best to resolve the problem. Please email support if the issue persists.',
      options: {
        width: 500,
        showEmailSupport: true,
        ...options,
      },
    });

    throw this.convertError(err);
  }

  /**
   * Flushes error logs interactively (with modals guiding the user)
   * @returns {Promise<SweetAlertResult>}
   */
  async interactiveFlush() {
    return this.flush()
      .then((results) => {
        // Look for a UUID returned by the backend
        const uuidObj = results.find((item) => item.data && item.data.data && item.data.data.uuid);
        const uuid = uuidObj ? uuidObj.data.data.uuid : null;

        let message = 'Error logs have been sent. Thank you!';
        if (uuid) {
          message +=
            '<br><br>Include the ID below if you email support:<br><span class="text-bold">' +
            uuid +
            '</span>';
        }
        // @ts-ignore until errorDialog plugin types are written
        Vue.errorDialog.open({
          title: 'Thanks!',
          message: message,
          options: {
            enableSendingLogs: false,
            width: 500,
            showEmailSupport: true,
            buttonText: 'Close',
          },
        });
      })
      .catch(() =>
        // @ts-ignore until errorDialog plugin types are written
        Vue.errorDialog.open({
          title: 'Uh oh!',
          message: 'There was an error sending the logs.',
          options: {
            enableSendingLogs: true,
            width: 500,
            showEmailSupport: true,
            buttonText: 'Try Again',
          },
        })
      );
  }
}
