import Question from './Question';
import {Fields} from '@vuex-orm/core';
import {RawCalculationQuestion} from '@/tasks/types/RawTask';

/**
 * CalculationQuestion class
 */
export class CalculationQuestion extends Question implements RawCalculationQuestion {
  static entity = 'CalculationQuestion';

  correctAnswer!: string;
  absTolerance!: string;
  prependText!: string | null;
  appendText!: string | null;

  static fields(): Fields {
    return {
      ...super.fields(),
      correctAnswer: this.string(null),
      absTolerance: this.string(null).nullable(),
      prependText: this.string(null).nullable(),
      appendText: this.string(null).nullable(),
    };
  }
}

export default CalculationQuestion;
