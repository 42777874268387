import {HTTP} from '@/common/api/http';
import {IStembleAxiosPromise} from '@/common/api/types/IStembleAxiosPromise';
import {CONVERSATIONS_BASE_URL} from '@/living-syllabus/api/conversations';

/**
 * Gets the current user
 * @param {boolean} loadRoles
 * @returns {IStembleAxiosPromise}
 */
export const getConversationMessages = async (conversationId: number): IStembleAxiosPromise => {
  return HTTP.get(CONVERSATIONS_BASE_URL + '/' + conversationId + '/messages');
};

export const createConversationMessage = async (
  conversationId: number,
  content: string,
  userTimezone: string,
  courseId: number
): IStembleAxiosPromise => {
  return HTTP.post(CONVERSATIONS_BASE_URL + '/' + conversationId + '/messages', {
    content,
    userTimezone,
    courseId,
  });
};

export const smartResponse = async (
  conversationId: number,
  content: any,
  userTimezone: string
): IStembleAxiosPromise => {
  return HTTP.post(CONVERSATIONS_BASE_URL + '/' + conversationId + '/smartResponse', {
    content,
    userTimezone,
  });
};

export default {
  getConversationMessages,
};
