import Location from './Location';
import Institution from '../../institutions/models/Institution';
import {Joined} from '@/orm/types/Joined';
import * as InstitutionLocationApi from '@/locations/api/orm/InstitutionLocationApi';
import {Fields} from '@vuex-orm/core';

/**
 * InstitutionLocation class
 */
export class InstitutionLocation extends Location {
  static entity = 'InstitutionLocation';

  institutionId!: number | null;
  institution!: Joined<Institution>;

  static fields(): Fields {
    return {
      ...super.fields(),
      institutionId: this.number(null),
      institution: this.belongsTo(Institution, 'institutionId'),
    };
  }

  static get api() {
    return InstitutionLocationApi;
  }
}

export default InstitutionLocation;
