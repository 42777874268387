import {StembleModel} from '@/common/models/StembleModel';
import {AbstractTaskValue} from '@/task-values/models/AbstractTaskValue';
import {DistributionDto} from '@/distributions/dtos/DistributionDto';
import {Fields} from '@vuex-orm/core';

export class AbstractDistribution<T extends AbstractTaskValue>
  extends StembleModel
  implements DistributionDto<T>
{
  id!: number;

  static fields(): Fields {
    return {
      id: this.number(null),
    };
  }
}
