import {
  createTaskAssignment,
  deleteTaskAssignment,
  getTaskAssignment,
  getTaskAssignments,
  patchTaskAssignment,
  putTaskAssignment,
} from '@/assignments/api/task-assignments';
import {ActionFactory} from '@/orm/api/actions';
import TaskAssignment from '@/tasks/models/TaskAssignment';

const factory = new ActionFactory(() => TaskAssignment);

export const fetch = factory.insertAction(getTaskAssignments);
export const get = factory.insertAction(getTaskAssignment);
export const create = factory.insertAction(createTaskAssignment);
export const post = factory.insertAction(createTaskAssignment);
export const update = factory.insertAction(putTaskAssignment);
export const put = factory.insertAction(putTaskAssignment);
export const patch = factory.insertAction(patchTaskAssignment);
export const remove = factory.deleteAction(deleteTaskAssignment);
