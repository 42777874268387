import {RawNumberValue} from '@/task-states/types/RawTaskVariableValue';
import {NumberValue} from '@/task-values/models/NumberValue';

/**
 * NumberValue class
 */
export class LegacyNumberValue extends NumberValue<number> implements RawNumberValue {
  static entity = 'LegacyNumberValue';

  static fields() {
    return {
      ...super.fields(),
      id: this.number(null),
    };
  }
}

export default LegacyNumberValue;
