import ErrorHandlingDialog from '../components/ErrorHandlingDialog';

const Dialog = {
  install(Vue) {
    this.EventBus = new Vue();

    // making our modal component global
    Vue.component('error-dialog', ErrorHandlingDialog);

    Vue.prototype.$errorDialog = {
      open(params) {
        Dialog.EventBus.$emit('open', params);
      },
    };
    Vue.errorDialog = {
      open(params) {
        Dialog.EventBus.$emit('open', params);
      },
    };
  },
};

export default Dialog;
