/**
 * Filters an array of entities, eliminating any duplicates by the ID property.
 * @param entityArray the array of entities
 * @param id the property on the object to use as ID
 * @returns Array
 */
export function filterUniqueEntities(entityArray, id = 'id') {
  let entityIds = {};
  return entityArray.filter((entity) => {
    if (!Object.prototype.hasOwnProperty.call(entityIds, entity[id])) {
      entityIds[entity[id]] = entity;
      return true;
    }
    return false;
  });
}

/**
 * Shuffles the elements of an array in-place using the Fisher-Yates (aka Knuth) Shuffle
 * @param {Array} array
 * @returns {Array}
 */
export function shuffle(array) {
  let currentIndex = array.length;
  let temporaryValue = null;
  let randomIndex = null;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}
