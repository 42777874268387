import assignment from '../../assignments/icons/assignment';
import common from './common';
import assignmentExtensions from '../../assignments/icons/assignment-extensions';
import tasks from '@/tasks/icons/tasks';
import feedback from '@/tasks/icons/feedback';
import grades from '../../grades/utils/gradesIcon';
import course from '@/courses/icons';
import {IconsObject} from '@/icons/services/IconService';

export const icons: IconsObject = {
  assignment,
  course,
  common,
  assignmentExtensions,
  tasks,
  feedback,
  grades,
};
