import moment from 'moment';

export const FRIENDLY_DATE_FORMAT = 'ddd, MMM DD';
export const FRIENDLY_DATE_YEAR_FORMAT = 'ddd, MMM DD, YYYY';
export const FRIENDLY_HOUR_FORMAT = ' h:mm a';
export const FRIENDLY_DATE_AND_FORMAT = `${FRIENDLY_DATE_FORMAT} [at] ${FRIENDLY_HOUR_FORMAT}`;

export function formatTime(time: string, format: string = 'HH:mm'): string {
  return moment(time).format(format);
}

export function formatComponentDate(date: string, format: string = 'YYYY-MM-DD'): string {
  return moment(date).format(format);
}

/**
 * Note: This function assumes the dateString in the format of yyyy-MM-dd HH:mm:ss (i.e. 2023-08-02 19:28:19)
 * @param dateString
 */
export function convertToUTCDate(dateString: string) {
  const parts = dateString.split(' ');
  const datePart = parts[0];
  const timePart = parts[1];

  const [year, month, day] = datePart.split('-').map(Number);
  const [hour, minute, second] = timePart.split(':').map(Number);

  return new Date(Date.UTC(year, month - 1, day, hour, minute, second));
}
