/*
 * Constants for route names so they can be shared throughout the app
 */
export const LOGIN = 'login';
export const DASHBOARD = 'Dashboard';
export const VERIFY_EMAIL = 'Verify Email';
export const ASSIGNMENT_OVERVIEW = 'Assignment Overview';
export const ASSIGNMENT_SHOW = 'assignments.show';
export const COURSE_HOME = 'CourseHome';
export const COURSE_SETTINGS = 'CourseSettings';
export const COURSE_ASSIGNMENTS = 'Assignments';
export const QUIZ = 'Assignment';
export const USER_PROFILE = 'User Profile';
export const SPECIFIC_USER_PROFILE = 'Specific User Profile';
export const CHANGE_PASSWORD = 'Change Password';
export const PROFILE = 'Profile';
export const RESET_PASSWORD = 'Reset Password';
export const NOT_FOUND = 'Not Found';
export const FEEDBACK = 'Feedback';
export const SUPER_ADMIN_ROOT = 'Super Admin';
export const ACT_AS_USER = 'Act as User';
export const INVITE_A_USER = 'Invite a User';
export const COURSE_ASSIGNMENTS_COPY = 'Copy Course Assignments';
export const SEARCH_USER = 'User Search';
export const CREATE_COURSE = 'Create a Course';
export const MARKING_TASKS = 'Marking Tasks';
export const TASK_EDITOR = 'Task Editor';
export const MESSAGES = 'Messages';
export const CONVERSATION_PAGE = 'Conversation Page';
export const DRAWING_COMPONENT = 'Drawing Component';

export const INERTIA_LEGACY_TASK = 'Inertia Legacy Task';
