import {makeDependency} from '@/container';
import {QUOTES} from '@/loading/utils/quotes';
import {computed} from '@vue/composition-api';

export const UseQuotes = makeDependency(selectQuote);

function selectQuote(index?: number) {
  const quote = QUOTES[index ?? Math.floor(Math.random() * QUOTES.length)];
  const text = computed(() => quote.text);
  const author = computed(() => quote.author);
  return {text, author};
}
