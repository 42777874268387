<template>
  <v-dialog
    v-model="dialog"
    :max-width="options.width"
    :style="{zIndex: options.zIndex}"
    data-test="error-dialog"
    :data-test-dialog-state="dialog"
    @keydown.esc="cancel"
  >
    <v-card>
      <v-toolbar dark :color="options.color" dense flat>
        <v-toolbar-title class="white--text">
          {{ title }}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text
        v-show="!!message"
        class="pa-4"
        data-test="error-dialog-message"
        v-html="message"
      />
      <v-card-actions class="pt-0">
        <v-btn v-if="options.showEmailSupport" color="grey" text href="mailto:support@stemble.com">
          Email Support
        </v-btn>
        <v-spacer />
        <v-btn color="primary darken-1" text @click="agree">
          {{ options.buttonText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Dialog from '@/errors/plugins/error-dialog';

export default {
  name: 'ErrorHandlingDialog',
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    message: null,
    title: null,
    options: {
      buttonText: 'Okay',
      color: 'secondary',
      enableSendingLogs: false,
      width: 290,
      zIndex: 200,
      showEmailSupport: false,
    },
  }),
  beforeMount() {
    Dialog.EventBus.$on('open', (params) => {
      this.open(params.title, params.message, params.options);
    });
  },
  methods: {
    open(title, message, options) {
      this.dialog = true;
      this.title = title;
      this.message = message;
      this.options = Object.assign(this.options, options);
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      if (this.options.enableSendingLogs) {
        this.$errorReporting.interactiveFlush();
      } else {
        this.dialog = false;
      }
      this.resolve(true);
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    },
  },
};
</script>
