import {Fields, Query} from '@vuex-orm/core';
import * as RoleApi from '@/users/api/orm/RoleApi';
import {RawRole} from '@/users/types/RawRole';
import {RoleName} from '@/users/types/RoleName';
import {StembleModel} from '@/common/models/StembleModel';

// Role names that are part of a course "team"
const TEAM_ROLES = ['Course Instructor', 'Lab Instructor', 'Teaching Assistant'];
const ROLE_HIERARCHY = [
  RoleName.Student,
  RoleName.TeachingAssistant,
  RoleName.LabInstructorReadOnly,
  RoleName.CourseInstructorReadOnly,
  RoleName.LabInstructor,
  RoleName.CourseInstructor,
  RoleName.LabAdministrator,
  RoleName.CourseAdministrator,
  RoleName.SuperUser,
];

/**
 * Role class
 */
export default class Role extends StembleModel implements RawRole {
  static ROLE_HIERARCHY = ROLE_HIERARCHY;
  static entity = 'Role';
  static TEAM_ROLES = TEAM_ROLES;
  static nameMap: Record<string, number> = {};

  id!: number;
  name!: RoleName;
  permissions!: string[];

  static fields(): Fields {
    return {
      id: this.number(null),
      name: this.string(''),
      permissions: this.attr(null),
    };
  }

  static get api() {
    return RoleApi;
  }

  /**
   * Checks to see if this role has permission to do something
   *
   * @param {string} ability the permission to check
   * @returns {boolean}
   */
  hasPermissionTo(ability: string): boolean {
    for (const permission of this.permissions) {
      if (ability === permission) {
        return true;
      }
    }
    return false;
  }

  /**
   * Checks to see if this role is a valid team role
   * @returns {boolean}
   */
  isTeamRole(): boolean {
    return Role.isTeamRole(this);
  }

  /**
   * Build a query for a role by name
   * @param {string} name
   * @returns {Query<Role>}
   */
  static queryByName(name: string): Query<Role> {
    return this.query().whereId(this.nameMap[name]);
  }

  /**
   * Get a role by name
   * @param {string} name
   * @returns {Role|null}
   */
  static getByName(name: string): Role | null {
    return this.queryByName(name).first();
  }

  /**
   * Gets the roles that can be part of a "team"
   * @returns {Role[]}
   */
  static getTeamRoles(): Role[] {
    return this.TEAM_ROLES.map((name: string) => this.getByName(name)).filter(
      (role: Role | null) => role !== null
    ) as Role[];
  }

  /**
   * Checks to see if a role is a valid team role
   * @returns {boolean}
   */
  static isTeamRole(role: Role): boolean {
    return this.TEAM_ROLES.indexOf(role.name) !== -1;
  }

  // Lifecycle hooks to maintain the mapping
  static afterCreate(model: Role) {
    // TODO: this is undefined. Likely a bug in VuexORM, so work around by getting prototype
    const cls = Object.getPrototypeOf(model).constructor;
    const f = cls.updateMap.bind(cls);
    return f(model);
  }

  static afterUpdate(model: Role) {
    // See above comment about potential Vuex ORM bug
    const cls = Object.getPrototypeOf(model).constructor;
    const f = cls.updateMap.bind(cls);
    return f(model);
  }

  static updateMap(model: Role) {
    return (this.nameMap[model.name] = model.id);
  }

  static afterDelete(model: Role) {
    // See above comment about potential Vuex ORM bug
    const cls = Object.getPrototypeOf(model).constructor;
    const f = cls.deleteMapEntry.bind(cls);
    return f(model);
  }

  static deleteMapEntry(model: Role) {
    delete this.nameMap[model.name];
  }
}
