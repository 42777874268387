import {getAppVersion} from '@/versioning/api/versioning';
import {ref, Ref} from '@vue/composition-api';
import {getEnvironment, isDevelopment} from '@/environment';

let instance: VersionCheckService;

const DEFAULT_INITIAL_POLL = 5;
const DEFAULT_POLLING_INTERVAL = 60;

export const useVersionCheckService = (pollInterval?: number, initialPollTimeout?: number) => {
  if (!instance) {
    instance = new VersionCheckService(pollInterval, initialPollTimeout);
  }
  return instance;
};

export class VersionCheckService {
  protected timeoutId: number | null;
  protected latestVersion: Ref<string | null>;
  protected currentVersion: Ref<string>;

  /** Poll interval in seconds **/
  pollInterval: number;

  /** Initial poll timeout in seconds **/
  initialPollTimeout: number;

  constructor(pollInterval?: number, initialPollTimeout?: number) {
    this.initialPollTimeout = initialPollTimeout || DEFAULT_INITIAL_POLL;
    this.pollInterval = isDevelopment()
      ? DEFAULT_POLLING_INTERVAL
      : pollInterval || DEFAULT_POLLING_INTERVAL;
    this.timeoutId = null;
    this.latestVersion = ref(null);
    this.currentVersion = ref<string>(getEnvironment().buildSha);
  }

  boot() {
    this.setTimer(this.initialPollTimeout);
  }

  /**
   * @param {number} timeout the timeout in seconds
   * @protected
   */
  protected setTimer(timeout: number) {
    this.timeoutId = setTimeout(() => {
      this.updateVersion();
    }, timeout * 1000);
  }

  cancelRequest() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

  get isUpToDate() {
    if (this.latestVersion.value === null) {
      return null;
    }
    return this.latestVersion.value === this.version;
  }

  get version() {
    return this.currentVersion.value;
  }

  protected async updateVersion() {
    if (isDevelopment()) {
      if (this.latestVersion.value === null) {
        // If this is the initial poll, set the full poll and next time it will stop
        this.setTimer(this.pollInterval);
      }
      this.latestVersion.value = getEnvironment().buildSha ?? '';
    } else {
      return getAppVersion()
        .then((versionPayload) => {
          this.latestVersion.value = versionPayload.version;
          this.pollInterval = versionPayload.pollInterval ?? this.pollInterval;
        })
        .catch(() => {})
        .finally(() => {
          this.setTimer(this.pollInterval);
        });
    }
  }
}
