





















































































































































































































































import {computed, defineComponent, PropType, ref} from '@vue/composition-api';
import StembleCard from '@/common/components/StembleCard.vue';
import {useAsyncState, useClipboard} from '@vueuse/core';
import {getCourse, getCoursesForUser} from '@/courses/api/courses';
import {useAuthService} from '@/auth/services/authService';
import {getUser, getUserActivity} from '@/users/api/users';
import {getCourseRoles} from '@/users/api/course-roles';
import {getAssignmentGrades} from '@/grades/api/assignment-grades';
import {getCourseLikeAssignments} from '@/assignments/api/course-like-assignments';
import {inject} from '@/container';
import {ErrorReportingService} from '@/errors/services/error-reporting';
import {convertToUTCDate} from '@/datetime/utils/datetime';
import {isArray} from 'lodash';
import {CONVERSATION_PAGE} from '@/router/route-names';
import {addNewConversation} from '@/living-syllabus/api/conversations';
import {useRouter} from '@/router/composables';
import AssignmentGradeComponent from '@/assignments/components/AssignmentGrade.vue';
import AssignmentGrade from '@/grades/models/AssignmentGrade';
import {RawAssignmentGrade} from '@/grades/types/RawAssignmentGrade';
import {RawGradeAdjustment} from '@/grades/types/RawGradeAdjustment';

interface UserData {
  avatarUrl: string;
  email: string;
  firstName: string;
  hasVerifiedEmail: boolean;
  id: number;
  institutionId: number | null;
  isActive: boolean;
  isFaculty: boolean;
  lastName: string;
  roleIds: number[];
  studentId: string;
}

interface CourseData {
  name: string;
  section: string;
}

type PartialIntegerAssignmentGrade = Pick<
  AssignmentGrade,
  'integerGrade' | 'originalIntegerGrade'
> & {gradeAdjustments?: RawGradeAdjustment[]};

interface AssignmentData {
  grade: RawAssignmentGrade;
  name: string;
  dueDate: string;
}

export default defineComponent({
  name: 'StudentInformation',
  components: {StembleCard, AssignmentGrade: AssignmentGradeComponent},
  props: {
    studentId: {
      type: Number,
      required: true,
    },
    courseLikeId: {
      type: [Number, Array] as PropType<number | number[]>,
      required: true,
    },
    inPage: {
      type: Boolean,
      default: false,
    },
    canStartConversation: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup(props) {
    const $errorReporting = inject(ErrorReportingService);
    const router = useRouter();
    const auth = useAuthService();
    const user = computed(() => auth.user);

    /** Get the profile data of the student */
    const {state: studentProfile} = useAsyncState<UserData>(
      getUser(props.studentId).then((res) => res.data.data),
      {
        avatarUrl: '',
        email: '',
        firstName: '',
        hasVerifiedEmail: false,
        id: -1,
        institutionId: -1,
        isActive: false,
        isFaculty: false,
        lastName: '',
        roleIds: [],
        studentId: '',
      },
      {
        onError: () => showErrorDialog(),
      }
    );

    const {text, copy, copied, isSupported} = useClipboard();

    const userPath = ref(`/users/${props.studentId}`);

    const todayDate = new Date();

    function showErrorDialog() {
      $errorReporting.showErrorDialog('', {
        text: 'There was an error fetching your data. Try refreshing the page. If that does not work, please let us know.',
      });
    }

    function computeChipColor(grade: any) {
      const numericGrade = typeof grade === 'string' ? parseInt(grade, 10) : grade;

      if (numericGrade >= 85) {
        return 'grade-chip good';
      }

      if (numericGrade <= 50) {
        return 'grade-chip bad';
      }

      return 'grade-chip';
    }

    async function getStudentLastLogin() {
      const {lastLogin} = await getUserActivity(props.studentId).then((res) => res.data);

      if (!lastLogin) {
        return 'No Login Recorded';
      }

      const utcDate = convertToUTCDate(lastLogin);

      return new Date(utcDate).toLocaleString('en-US', {
        weekday: 'long',
        month: 'long',
        day: 'numeric',
        minute: '2-digit',
        hour: 'numeric',
      });
    }

    const {state: studentLastLogin} = useAsyncState(getStudentLastLogin(), '', {
      onError: () => showErrorDialog(),
    });

    /**
     * Get the shared courses of the student being viewed
     * Reasoning: Get the instructor's courses and query all the students' courses with the instructor's courseIds.
     * This will return any courses that are shared b/w the student and the instructor
     */
    async function getSharedActiveCourses() {
      const courses = await getCoursesForUser(user.value.id, {
        checkSections: true,
        active: true,
      }).then((res) => res.data.data);

      const courseIds = courses?.map((c) => c.courseId);

      return getCoursesForUser(props.studentId, {
        checkSections: true,
        active: true,
        id: courseIds,
      }).then((res) => res.data.data);
    }

    /**
     * Get the course sections for the student
     */
    async function getCourseSections(): Promise<{sectionId: number}[]> {
      const courseRoles = await getCourseRoles({
        user: props.studentId,
        active: true,
      }).then((res) => res.data.data);

      return courseRoles?.map(({courseLikeId}: {courseLikeId: number}) => ({
        sectionId: courseLikeId,
      }));
    }

    /**
     * Mapping function that gets takes the section ID and maps it to the section that the user is enrolled in
     */
    async function getCoursesAndSectionsForStudent() {
      const courseSections = await getCourseSections();
      const sharedActiveCourses = await getSharedActiveCourses();

      return courseSections
        ?.map((section) => {
          const matchedCourse = sharedActiveCourses?.find((course) =>
            course.sections.some((s) => s.id === section.sectionId)
          );

          if (matchedCourse) {
            const matchedSection = matchedCourse.sections.find((s) => s.id === section.sectionId)
              ?.name;

            return {
              name: matchedCourse.name,
              section: matchedSection,
            };
          }

          return null;
        })
        .filter((result) => result !== null);
    }

    const {state: courseData, isLoading: isCourseDataLoading} = useAsyncState(
      getCoursesAndSectionsForStudent(),
      [],
      {
        onError: () => showErrorDialog(),
      }
    );

    function addConversation() {
      addNewConversation(props.studentId).then((t) => {
        router.push({
          name: CONVERSATION_PAGE,
          params: {id: t.data.data.id, userId: props.studentId.toString()},
        });
      });
    }

    /**
     * Get assignment data for student
     */

    async function getAssignmentDataForStudent() {
      const assignmentGrades: RawAssignmentGrade[] = await getAssignmentGrades({
        user: props.studentId,
        courseLike: isArray(props.courseLikeId) ? props.courseLikeId : [props.courseLikeId],
      }).then((res) => res.data.data);

      const assignmentWithGrades = await Promise.all(
        assignmentGrades.map(async (grade) => {
          const {assignment, dueDate} = await getCourseLikeAssignments({
            assignment: grade.assignmentId,
          }).then((res) => res.data.data![0]);

          const courseName = await getCourse(assignment.courseId).then(
            (res) => res.data.data!.name
          );

          const courseSection = courseData.value.find((course) => course!.name === courseName);

          const sectionName = courseSection ? courseSection.section : '';

          return {name: assignment.name, dueDate, grade, courseName, sectionName};
        })
      );

      return (
        assignmentWithGrades
          // Only show assignments that are due today or before
          .filter(({dueDate}) => new Date(dueDate) <= todayDate)
          .sort(
            (a: AssignmentData, b: AssignmentData) =>
              Number(new Date(b.dueDate)) - Number(new Date(a.dueDate))
          )
          .slice(0, 4)
      );
    }

    const {state: assignmentData, isLoading: isAssignmentDataLoading} = useAsyncState(
      getAssignmentDataForStudent(),
      [],
      {
        onError: (e) => console.error(e),
      }
    );

    const isDataLoading = computed(
      () => isCourseDataLoading.value && isAssignmentDataLoading.value
    );

    return {
      user,
      studentProfile,
      computeChipColor,
      courseData,
      isAssignmentDataLoading,
      assignmentData,
      isDataLoading,
      userPath,
      text,
      copy,
      copied,
      isSupported,
      studentLastLogin,
      addConversation,
    };
  },
});
