import {Fields} from '@vuex-orm/core';
import {StembleModel} from '@/common/models/StembleModel';

export abstract class GradeAdjustmentPolicy extends StembleModel {
  id!: number;

  static fields(): Fields {
    return {
      id: this.number(null),
    };
  }
}
