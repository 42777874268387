import {Policy} from '@/permissions/Policy';
import IsOrIsAnyParentOf from '@/permissions/services/permissions-service/specifications/IsOrIsAnyParentOf';
import {User} from '@/users/models/User';
import {Ability} from '@/permissions/types/Ability';

export class CourseRolePolicy extends Policy {
  /**
   * See if a user can create assignments
   * @param {User} user
   * @param {Number} courseLikeId
   * @returns {*}
   */
  createIn(user: User, courseLikeId: number) {
    return user.checkAnyCoursePermission(
      Ability.UpdateCourseRosters,
      new IsOrIsAnyParentOf(courseLikeId)
    );
  }
}

export default CourseRolePolicy;
