import {makeDependency} from '@/container';
import {ContentObject, FlattenContent, NestedContentObject} from '@/orm/types/ContentObject';

export const ContentNestingService = makeDependency(() => {
  function nest<T extends object, Keys extends keyof T = keyof T, RetainKeys extends Keys = Keys>(
    obj: T,
    keys: Keys[],
    keysToRetain: RetainKeys[] = []
  ) {
    const newObj = {...obj, content: {} as any};
    keys.map((key) => {
      newObj.content[key] = obj[key as keyof T];
      if (!keysToRetain.includes(key as RetainKeys)) {
        delete newObj[key as keyof T];
      }
    });

    // TS incapable of understanding how 'delete' works (https://github.com/microsoft/TypeScript/issues/13783)
    return newObj as NestedContentObject<T, Keys>;
  }

  function flatten<T extends ContentObject<any>>(obj: T): FlattenContent<T> {
    const newObj = {...obj, ...obj.content};
    delete newObj.content;
    return newObj;
  }

  return {
    nest,
    flatten,
  };
});
