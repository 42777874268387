import User from '@/users/models/User';
import {PolicyMethodArgs} from '@/permissions/types/PolicyMethodArgs';
import {PolicyMap} from '@/permissions/types/PolicyMap';
import {GateOptions} from '@/permissions/types/GateOptions';
import {GateAction} from '@/permissions/types/GateAction';
import {inject, makeGlobalSingleton} from '@/container';
import {DefaultPolicies} from '@/permissions/policies';
import {DefaultPolicyMap} from '@/permissions/types/DefaultPolicyMap';
import {Ability} from '@/permissions/types/Ability';

/**
 * Gate class that manages authorization of actions using policies
 */
export default class Gate<PM extends PolicyMap> {
  static injectable = makeGlobalSingleton(
    (options?: Partial<GateOptions<DefaultPolicyMap>>) =>
      new Gate({
        policies: inject(DefaultPolicies),
        ...options,
      })
  );

  protected defaultAction: GateAction;
  // FIXME: type this properly when UserStorage interface is defined
  protected userStorage?: any;
  protected _user?: User;
  protected policies: PM;

  constructor({userStorage, user, defaultAction = GateAction.Deny, policies}: GateOptions<PM>) {
    this.userStorage = userStorage;
    this._user = user;
    this.defaultAction = defaultAction;
    this.policies = policies;
  }

  get user(): User | null {
    return this._user || this.userStorage?.get() || null;
  }

  /**
   * Create a new gate with the same parameters, but with the provided user bound in
   * @param user
   */
  withUser(user: User): Gate<PM> {
    return new Gate({
      userStorage: this.userStorage,
      user,
      defaultAction: this.defaultAction,
      policies: this.policies,
    });
  }

  /**
   * Check to see if a user is a faculty member (can see a course admin page for a course)
   */
  isFaculty(): boolean | null {
    return this.user && this.user.isFaculty();
  }

  /**
   * Check to see if a user is a super-user
   */
  isSuperUser(): boolean | null {
    return this.user && this.user.isSuperUser();
  }

  /**
   * Checks to see if a user is logged in
   */
  isLoggedIn() {
    return this.user !== null;
  }

  /**
   * Get the fallback return value of this gate
   */
  getDefaultReturn(): boolean {
    return this.defaultAction === GateAction.Allow;
  }

  /**
   * Alias for allow()
   * @param action
   * @param policyKey
   * @param args
   * @returns {*}
   */
  can<K extends keyof PM, MK extends keyof PM[K]>(
    action: MK | Ability,
    policyKey?: K,
    ...args: PolicyMethodArgs<PM[K][MK]>
  ) {
    return this.allow(action, policyKey, ...args);
  }

  /**
   * Checks permission for the logged in user. See check().
   * @param action
   * @param policyKey
   * @param args
   * @returns {Boolean}
   */
  allow<K extends keyof PM, MK extends keyof PM[K]>(
    action: MK | Ability,
    policyKey?: K,
    ...args: PolicyMethodArgs<PM[K][MK]>
  ) {
    return this.check(this.user, action, policyKey, ...args);
  }

  /**
   * Alias for deny()
   * @param action
   * @param policyKey
   * @param args
   * @returns {boolean}
   */
  cannot<K extends keyof PM, MK extends keyof PM[K]>(
    action: MK | Ability,
    policyKey?: K,
    ...args: PolicyMethodArgs<PM[K][MK]>
  ) {
    return this.deny(action, policyKey, ...args);
  }

  /**
   * Checks to see if a user cannot do something. Opposite of allow()
   * @param action
   * @param policyKey
   * @param args
   * @returns {boolean}
   */
  deny<K extends keyof PM, MK extends keyof PM[K]>(
    action: MK | Ability,
    policyKey?: K,
    ...args: PolicyMethodArgs<PM[K][MK]>
  ) {
    return !this.allow(action, policyKey, ...args);
  }

  /**
   * Checks to see if a user is allowed to perform an action.
   *
   * If no user is logged in, returns the default.
   * If a user is logged in:
   *   - If only action is provided, treats this as a permission and checks
   *     to see if a user has that permission
   *   - If a policy key is provied, call the policy method
   *   - If a policy is not found or the action is not defined on the
   *     policy, the default action is taken.
   * @param user
   * @param action
   * @param policyKey
   * @param args
   * @returns {Boolean}
   */
  protected check<K extends keyof PM, MK extends keyof PM[K]>(
    user: User | null,
    action: MK | Ability,
    policyKey?: K,
    ...args: PolicyMethodArgs<PM[K][MK]>
  ) {
    // When user is null,
    if (user === null) {
      user = this.user;
      if (user === null) {
        return this.getDefaultReturn();
      }
    }

    // If no model is given, then just check for permission directly on User
    if (policyKey === undefined) {
      return this.checkUser(user, action as Ability);
    }

    const policyResult = this.checkPolicy(user, action as MK, policyKey, ...args);
    if (policyResult !== null) {
      return policyResult;
    }

    return this.getDefaultReturn();
  }

  /**
   * Checks to see if a user has permission to do an action
   * @param user
   * @param action
   * @returns {boolean}
   */
  protected checkUser(user: User, action: Ability) {
    return user.hasPermissionTo(action);
  }

  /**
   * Checks to see if a user can do an action on a Model
   * @param user
   * @param action
   * @param policyKey
   * @param args
   * @returns {*}
   */
  protected checkPolicy<K extends keyof PM, MK extends keyof PM[K]>(
    user: User,
    action: MK,
    policyKey: K,
    ...args: PolicyMethodArgs<PM[K][MK]>
  ): boolean | null {
    const policy = this.policies[policyKey];

    if (policy) {
      const beforeResult = policy.before(user);
      if (beforeResult !== null) {
        return beforeResult;
      }

      let actionMethod = policy[action];
      // @ts-ignore TS doesn't like the bind call
      actionMethod = actionMethod.bind(policy);
      return !!actionMethod(user, ...args);
    }
    return null;
  }
}
