import {AuthService, useAuthService} from '@/auth/services/authService';
import {VuexORMUserStorage} from '@/users/services/user-storage/vuex-orm';

let instance = null;

export function getInstance() {
  // FIXME: move this to auth service once all services are moved
  return instance;
}

/**
 * Installs the auth service plugin
 * API requests
 * @param Vue
 * @param HTTP the HTTP (axios) instance
 * @param store the Vuex store
 * @param promiseManager the promise manager
 * @param router the Vue router instance
 * @param analytics the Analytics instance
 * @param hierarchyService the CourseHierarchyService instance
 */
export default function install(Vue, {HTTP, store, promiseManager, router, hierarchyService}) {
  instance = new AuthService(
    HTTP,
    new VuexORMUserStorage({store}),
    promiseManager,
    router,
    store,
    hierarchyService
  );
  Vue.prototype.$auth = useAuthService();
}
