import {CourseSection} from '@/courses/models';
import InstitutionLocation from '@/locations/models/InstitutionLocation';
import {Joined} from '@/orm/types/Joined';
import Fields from '@vuex-orm/core/lib/model/contracts/Fields';
import {StembleModel} from '@/common/models/StembleModel';

/**
 * Event class
 */
export default class Event extends StembleModel {
  static entity = 'Event';

  id!: number;
  title!: string;
  start!: string;
  end!: string;
  notes!: string;
  locationId!: number;
  location!: Joined<InstitutionLocation>;
  ownerId!: number;
  owner!: Joined<CourseSection>;
  participants!: any;

  static fields(): Fields {
    return {
      ...super.fields(),
      id: this.number(null),
      title: this.string(''),
      start: this.string(''),
      end: this.string(''),
      notes: this.string(''),

      locationId: this.number(null),
      location: this.belongsTo(InstitutionLocation, 'locationId'),
      ownerId: this.number(null),
      owner: this.belongsTo(CourseSection, 'ownerId'),
      // TODO: this should be relationships
      participants: this.attr(null),
    };
  }

  get capacity() {
    return this.location?.capacity;
  }
}
