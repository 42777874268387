import {inject, makeDependency, makeGlobalSingleton} from '@/container';
import {StembleTheme, StembleThemes} from '@/theme/types';
import {DependencyType} from '@/container/types/DependencyType';
import {VuetifyService} from '@/vuetify/services';
import {customRef, ref} from '@vue/composition-api';

export const ThemeService = makeGlobalSingleton(
  (vuetify?: DependencyType<typeof VuetifyService>) => {
    vuetify = vuetify ?? inject(VuetifyService);
    return makeThemeService(vuetify);
  }
);

export const Theme = makeDependency(() => {
  return inject(ThemeService).getTheme();
});

function makeThemeService(vuetify: DependencyType<typeof VuetifyService>) {
  let track: () => void;
  let trigger: () => void;

  // Custom ref allows us get reactivity before Vuetify is instantiated, so long
  // as the theme is accessed through this service
  customRef((tra, tri) => {
    track = tra;
    trigger = tri;

    return {
      get: () => null,
      set: () => {},
    };
  });

  function isDark(): boolean {
    track();
    return vuetify.framework.theme.dark;
  }

  function setDark(darkMode: boolean = true): void {
    trigger();
    vuetify.framework.theme.dark = darkMode;
  }

  function getTheme() {
    track();
    return vuetify.framework.theme.currentTheme as StembleTheme;
  }

  function changeThemes(themes: StembleThemes) {
    trigger();
    vuetify.framework.theme.themes = themes;
  }

  return {isDark, setDark, getTheme, changeThemes};
}
