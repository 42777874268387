import {loadStripe} from '@stripe/stripe-js';
import {getCheckoutSession} from '../api';

export class StripeService {
  constructor(stripeKey) {
    this.stripe = null;
    this.stripeKey = stripeKey;
  }

  async _loadStripe() {
    if (this.stripe === null) {
      this.stripe = await loadStripe(this.stripeKey);
    }
    return this.stripe;
  }

  /**
   * Redirect the user to Stripe's checkout page
   * @param {String} sessionId the stripe checkout ID
   * @return {Promise<StripeError>}
   */
  async redirectToCheckout(sessionId) {
    await this._loadStripe();

    const {error} = await this.stripe.redirectToCheckout({
      sessionId,
    });
    return error;
  }

  /**
   * Redirect the user to a Stripe checkout for a PaymentRequest
   * @param {Number} paymentRequestId the PaymentRequest ID
   * @return {Promise<*>}
   */
  async redirectToPaymentRequestCheckout(paymentRequestId) {
    const response = await getCheckoutSession(paymentRequestId);
    const sessionId = response.data.data.sessionId;
    return this.redirectToCheckout(sessionId);
  }
}
