import Question from './Question';
import {Fields} from '@vuex-orm/core';

/**
 * ManuallyGradedTask class
 */
export class ManuallyGradedTask extends Question {
  static entity = 'ManuallyGradedTask';

  static fields(): Fields {
    return {
      ...super.fields(),
    };
  }
}

export default ManuallyGradedTask;
