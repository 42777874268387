import {Fields, Model} from '@vuex-orm/core';
import * as TaskTopicApi from '@/tasks/api/orm/TaskTopicApi';
import {StembleModel} from '@/common/models/StembleModel';

/**
 * TaskTopic class
 */
export class TaskTopic extends StembleModel {
  static entity = 'TaskTopic';

  id!: number;
  description!: string;
  isRootNode!: boolean;
  children!: null | number[];

  static fields(): Fields {
    return {
      id: this.number(null),
      description: this.string(null),
      isRootNode: this.boolean(false),
      children: this.attr(null),
    };
  }

  static get api() {
    return TaskTopicApi;
  }
}

export default TaskTopic;
