import {Fields} from '@vuex-orm/core';
import * as TaskFeedbackApi from '@/tasks/api/orm/TaskFeedbackApi';
import {RawTaskFeedback} from '@/tasks/types/RawTaskFeedback';
import {StembleModel} from '@/common/models/StembleModel';

/**
 * TaskFeedback class
 */
export class TaskFeedback extends StembleModel implements RawTaskFeedback {
  static entity = 'TaskFeedback';

  id!: number;
  name!: string;
  content!: string;
  taskId!: number | null;
  shortContent!: string;

  static fields(): Fields {
    return {
      id: this.number(null),
      name: this.string(null),
      taskId: this.number(null).nullable(),
      content: this.string(null),
      shortContent: this.string(null),
    };
  }

  static get api() {
    return TaskFeedbackApi;
  }
}

export default TaskFeedback;
