import AssignablesService from '@/assignments/services/AssignablesService';

let instance = null;

export const getInstance = () => instance;

/**
 * Installs the stripe service plugin
 * @param Vue
 * @param {CourseHierarchyService} hierarchyService
 */
export default function install(Vue, {hierarchyService}) {
  instance = new AssignablesService(hierarchyService);
  Vue.prototype.$assignables = instance;
}
