import QuestionResponse from './QuestionResponse';
import {Fields} from '@vuex-orm/core';

/**
 * BooleanResponse class
 */
export class BooleanResponse extends QuestionResponse {
  static entity = 'BooleanResponse';

  set!: boolean;

  static fields(): Fields {
    return {
      ...super.fields(),
      set: this.boolean(false),
    };
  }
}

export default BooleanResponse;
