import common from '@/icons/utils/common';
import {IconsObject} from '@/icons/services/IconService';

const assignment: IconsObject = {
  previewAssignment: 'mdi-eye',
  editAssignment: common.edit,
  extensions: 'mdi-calendar-arrow-right',
  overview: 'mdi-book-open-variant',
  addTask: common.add,
  removeTask: common.remove,
  lockedGrade: 'mdi-lock',
};

export default assignment;
