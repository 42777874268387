import OrSpecification from './OrSpecification';
import Is from '@/permissions/services/permissions-service/specifications/Is';
import IsAnyParentOf from '@/permissions/services/permissions-service/specifications/IsAnyParentOf';

export class IsOrIsAnyParentOf extends OrSpecification {
  constructor(courseLikes) {
    super(new Is(courseLikes), new IsAnyParentOf(courseLikes));
  }
}

export default IsOrIsAnyParentOf;
