import {HTTP} from '@/common/api/http';
import {API_BASE_URL} from '@/common/api/base';
import {IGetLocationsParams} from '@/locations/types/IGetLocationsParams';
import {IStembleAxiosPromise} from '@/common/api/types/IStembleAxiosPromise';
import {IGetLocationParams} from '@/locations/types/IGetLocationParams';

export const LOCATIONS_BASE_URL = API_BASE_URL + '/locations';

/**
 * Get all locations
 *
 * @returns {IStembleAxiosPromise}
 * @param params
 */
export const getLocations = async (params: IGetLocationsParams = {}): IStembleAxiosPromise => {
  return HTTP.get(LOCATIONS_BASE_URL, {
    params: {
      includeInstitution: false,
      ...params,
    },
  });
};

/**
 * Get a location by ID
 * @param {Number} id
 * @param params
 * @returns {IStembleAxiosPromise}
 */
export const getLocation = async (
  id: number,
  params: IGetLocationParams = {}
): IStembleAxiosPromise => {
  return HTTP.get(`${LOCATIONS_BASE_URL}/${id}`, {
    params: {
      includeInstitution: false,
      ...params,
    },
  });
};

/**
 * Create a new location
 * @param {Object} data
 * @returns {IStembleAxiosPromise}
 */
export const createLocation = async (data: any): IStembleAxiosPromise =>
  HTTP.post(LOCATIONS_BASE_URL, data);

/**
 * Overwrite an existing location
 * @param {Number} id
 * @param {Object} data
 * @returns {IStembleAxiosPromise}
 */
export const putLocation = async (id: number, data: any): IStembleAxiosPromise => {
  return HTTP.put(`${LOCATIONS_BASE_URL}/${id}`, data);
};

/**
 * Patch over an existing location
 * @param {Number} id
 * @param {Object} data
 * @returns {IStembleAxiosPromise}
 */
export const patchLocation = async (id: number, data: any): IStembleAxiosPromise => {
  return HTTP.patch(`${LOCATIONS_BASE_URL}/${id}`, data);
};

/**
 * Delete a location
 * @param {Number} id
 * @returns {IStembleAxiosPromise}
 */
export const deleteLocation = async (id: number): IStembleAxiosPromise => {
  return HTTP.delete(`${LOCATIONS_BASE_URL}/${id}`);
};

export default {
  getLocation,
  getLocations,
  createLocation,
  putLocation,
  patchLocation,
  deleteLocation,
};
