import {DirectiveOptions} from 'vue';
import {App} from '@vue/composition-api';

export type FormatterFunction = (newValue: string, oldValue: string | null) => string;

export type FormatInputDirectiveOptions =
  | FormatterFunction
  | {
      event?: string;
      formatter: FormatterFunction;
    };

/**
 * Make a new instance of an input handler that keeps track of the previous value and runs the formatter function
 * @param formatter
 * @param eventName
 */
function makeHandler(el: HTMLElement, formatter: FormatterFunction, eventName: string) {
  let oldValue: string | null = null;
  return function (e: any) {
    if (e.target.value) {
      e.target.value = formatter(e.target.value, oldValue);
      oldValue = e.target.value;
      if (e.target !== el) {
        e.target.dispatchEvent(new Event(eventName));
      }
    }
  };
}

/**
 * Bind the formatter
 * @param el
 * @param options
 */
export const bindFormatter = (el: HTMLElement, options: FormatInputDirectiveOptions) => {
  let eventName: string = 'input';
  let formatter: FormatterFunction;
  if (typeof options === 'object') {
    if (options.event) {
      eventName = options.event;
    }
    formatter = options.formatter;
  } else {
    formatter = options;
  }
  el.addEventListener(eventName, makeHandler(el, formatter, eventName));
};

const directiveOptions: DirectiveOptions = {
  bind(el, bindings) {
    bindFormatter(el, bindings.value as FormatInputDirectiveOptions);
  },
};

export function installDirective(app: App) {
  app.directive('format-input', directiveOptions);
}
