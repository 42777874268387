import {
  getAssignmentGradesForUser,
  getGradeForUserOnAssignment as getGradeForUserOnAssignmentApi,
} from '@/grades/api/task-grades';
import {getAssignmentGrades} from '@/grades/api/assignment-grades';
import {ActionFactory} from '@/orm/api/actions';
import AssignmentGrade from '@/grades/models/AssignmentGrade';

const factory = new ActionFactory(() => AssignmentGrade);

export const getGradeForUserOnAssignment = factory.insertAction(getGradeForUserOnAssignmentApi);
export const fetchForUser = factory.insertAction(getAssignmentGradesForUser);
export const fetch = factory.insertAction(getAssignmentGrades);
