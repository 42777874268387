import {StembleModel} from '@/common/models/StembleModel';
import {TaskScopeDto} from '@/tasks/dtos/TaskScopeDto';
import {Fields} from '@vuex-orm/core';
import Task from '@/tasks/models/Task';
import {Joined} from '@/orm/types/Joined';

export abstract class TaskScope extends StembleModel implements Omit<TaskScopeDto, 'type'> {
  id!: number;
  taskId!: number;
  task!: Joined<Task>;

  static fields(): Fields {
    return {
      id: this.number(null),
      taskId: this.number(null),
      task: this.belongsTo(Task, 'taskId'),
    };
  }
}
