import Vue from 'vue';
import Vuex from 'vuex';

import ORMPlugin from '@/orm/plugins/orm';
import {StembleState} from '@/store/types/StembleState';
import {getEnvironment, isDevelopment} from '@/environment';

Vue.use(Vuex);

export default new Vuex.Store<StembleState>({
  strict: !isDevelopment(),
  plugins: [ORMPlugin],
  state: {
    barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
    drawer: null,
    appEnvironment: {
      environment: process.env.NODE_ENV,
      mode: getEnvironment().mode,
    },
  },
  mutations: {
    SET_DRAWER(state, payload) {
      state.drawer = payload;
    },
    SET_SCRIM(state, payload) {
      state.barColor = payload;
    },
    RESET_APP_MODE(state) {
      state.appEnvironment.mode = getEnvironment().mode;
    },
    SET_APP_MODE(state, payload) {
      state.appEnvironment.mode = payload;
    },
  },
});
