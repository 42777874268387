import Institution from '@/institutions/models/Institution';
import {CourseLike} from '.';
import {filterUniqueEntities} from '@/courses/utils/arrays';
import moment from 'moment';
import {Fields, Query} from '@vuex-orm/core';
import {Joined} from '@/orm/types/Joined';
import * as CourseApi from '@/courses/api/orm/CourseApi';
import {ICourseQueryParameters} from '@/courses/types/ICourseQueryParameters';
import {RawCourse} from '@/courses/types/RawCourse';

type NoRelationshipsCourse = Omit<
  RawCourse,
  'courseLikeAssignments' | 'sections' | 'subCourse' | 'courseRoles'
>;

/**
 * Course class
 */
export class Course extends CourseLike implements NoRelationshipsCourse {
  static entity = 'Course';

  startDate!: string;
  endDate!: string;
  courseCode!: string;
  institutionId!: number;
  institution!: Joined<Institution>;
  parentCourseId!: number | null;
  subCourseId!: number | null;
  subCourse!: Joined<Course>;
  parent!: Joined<Course>;
  topLevelCourse!: Joined<Course>;
  priceId!: string | null;
  paymentDueDate!: string | null;
  effectivePaymentDueDate!: string;
  gradeDisplay!: string;
  draftsEnabled!: boolean;
  inAppPaymentEnabled!: boolean;

  static fields(): Fields {
    return {
      ...super.fields(),
      startDate: this.string(null),
      endDate: this.string(null),
      courseCode: this.string(null),
      institutionId: this.number(0),
      institution: this.belongsTo(Institution, 'institutionId'),
      parentCourseId: this.number(null).nullable(),
      subCourseId: this.number(null).nullable(),
      subCourse: this.hasOne(Course, 'parentCourseId'),
      parent: this.belongsTo(Course, 'parentId'),
      priceId: this.string('').nullable(),
      paymentDueDate: this.string('').nullable(),
      effectivePaymentDueDate: this.string(''),
      gradeDisplay: this.string('percentage'),
      inAppPaymentEnabled: this.boolean(false),
      draftsEnabled: this.boolean(true),
    };
  }

  static get api() {
    return CourseApi;
  }

  /**
   * @deprecated
   */
  get title(): string {
    return this.name;
  }

  /**
   * Builds a Query object that will get all courses the current user is in
   * @param userId
   * @param checkSections
   * @param {boolean|null} active
   * @param queryParams
   * @returns {Query<Course>}
   */
  static queryCoursesForUser(
    userId: number,
    checkSections: boolean,
    active = true,
    queryParams = {}
  ) {
    const tmpQueryParams: ICourseQueryParameters = {
      ...queryParams,
      courseRoles: true,
      sections: checkSections,
    };
    const allCourses = this.fullQuery(tmpQueryParams).all();

    const courseIds = allCourses
      .filter((course) => {
        for (const cr of course.courseRoles) {
          if (cr.userId === userId && (active === null || cr.isActive === active)) {
            return true;
          }
        }
        if (checkSections) {
          for (const s of course.sections) {
            for (const cr of s.courseRoles) {
              if (cr.userId === userId && (active === null || cr.isActive === active)) {
                return true;
              }
            }
          }
        }
        return false;
      })
      .map((course) => course.id);
    return this.fullQuery(queryParams).whereIdIn(courseIds);
  }

  static fullQuery(options: ICourseQueryParameters = {}): Query<Course> {
    let q = super.fullQuery(options);

    if (options.subcourse ?? true) {
      q = q.with('subCourse', (q2) =>
        this.fullQuery({
          query: q2 as Query<Course>,
          participants: options.participants,
          courseRoles: options.courseRoles,
          sections: options.sections,
        })
      );
    }

    return q as Query<Course>;
  }

  get fullTeam() {
    let members = [...this.team];
    for (const section of this.sections) {
      members = [...members, ...section.team];
    }
    return filterUniqueEntities(members);
  }

  get childrenIds() {
    const result = [];
    for (const sectionId of this.sectionIds) {
      result.push(sectionId);
    }
    if (this.subCourseId) {
      result.push(this.subCourseId);
    }
    return result;
  }

  /**
   * Whether this course is in the future
   * @param now
   */
  isUpcoming(now?: string) {
    return !this.hasStarted(now) && !this.hasEnded(now);
  }

  /**
   * Whether this course has started
   * @param now
   */
  hasStarted(now?: string) {
    const momentNow = moment(now);
    return momentNow.isSameOrAfter(moment(this.startDate));
  }

  /**
   * Whether this course is currently active
   * @param now
   */
  isActive(now?: string) {
    return this.hasStarted(now) && !this.hasEnded(now);
  }

  /**
   * Whether this course has ended or not
   * @param now
   */
  hasEnded(now?: string) {
    const momentNow = moment(now);
    return momentNow.isSameOrAfter(moment(this.endDate));
  }
}

export default Course;
