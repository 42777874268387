import {Fields} from '@vuex-orm/core';
import {Course, CourseSection} from '@/courses/models';

import ScheduleMetaEvent from './ScheduleMetaEvent';
import SectionEvent from './SectionEvent';
import * as EventSceduleApi from '@/events/api/orm/EventScheduleApi';
import {StembleModel} from '@/common/models/StembleModel';

/**
 * EventSchedule class
 */
export default class EventSchedule extends StembleModel {
  static entity = 'EventSchedule';

  static fields(): Fields {
    return {
      id: this.number(null),
      title: this.string(''),
      rowMapping: this.attr({}),
      courseLikeOrdering: this.attr([]),
      courseLikeIds: this.attr(null),
      courseLikes: this.hasManyBy(CourseSection, 'courseLikeIds'),
      metaEventIds: this.attr(null),
      metaEvents: this.hasManyBy(ScheduleMetaEvent, 'metaEventIds'),
      courseId: this.number(null),
      course: this.belongsTo(Course, 'courseId'),
      eventIds: this.attr(null),
      events: this.hasManyBy(SectionEvent, 'eventIds'),
    };
  }

  static get api() {
    return EventSceduleApi;
  }
}
