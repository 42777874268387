import {Policy} from '@/permissions/Policy';
import {User} from '@/users/models/User';
import {Ability} from '@/permissions/types/Ability';

export class GradePolicy extends Policy {
  /**
   * Check to see if a user can view the full gradebook
   * @param {User} user
   * @returns {*}
   */
  submit(user: User) {
    return user.hasCoursePermissionTo(Ability.SubmitGrades);
  }
}
export default GradePolicy;
