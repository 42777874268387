import {
  getIssuedStateForTask,
  getIssuedTaskState,
  getIssuedTaskStates,
  exportTaskStateCsv,
} from '@/task-states/api/issued-task-state';
import {ActionFactory} from '@/orm/api/actions';
import IssuedTaskState from '../../models/IssuedTaskState';

const factory = new ActionFactory(() => IssuedTaskState);

export const get = factory.insertAction(getIssuedTaskState);
export const fetch = factory.insertAction(getIssuedTaskStates);
export const getForTask = factory.insertAction(getIssuedStateForTask);
export const exportCsv = exportTaskStateCsv;
