import {HTTP} from '@/common/api/http';
import {API_BASE_URL} from '@/common/api/base';
import {IStembleAxiosPromise} from '@/common/api/types/IStembleAxiosPromise';
import {IGetTaskTopicsParams} from '@/tasks/types/IGetTaskTopicsParams';
import {IGetTaskTypesParams} from '@/tasks/types/IGetTaskTypesParams';

export const TASK_TYPES_BASE_URL = API_BASE_URL + '/task-types';

/**
 * Get many task types
 * @returns {IStembleAxiosPromise}
 * @param params
 */
export const getTaskTypes = (params: IGetTaskTypesParams = {}): IStembleAxiosPromise => {
  return HTTP.get(TASK_TYPES_BASE_URL, {params});
};

export default {
  getTaskTypes,
};
