import {AbstractTaskValue} from '@/task-values/models/AbstractTaskValue';
import {DateTimeValueDto} from '@/task-values/dtos/DateTimeValueDto';
import {Fields} from '@vuex-orm/core';

export class DateTimeValue extends AbstractTaskValue<string> implements DateTimeValueDto {
  static entity = 'DateTimeValue';

  value!: string;

  static fields(): Fields {
    return {
      ...super.fields(),
      value: this.string(null),
    };
  }

  getValue(): string {
    return this.value;
  }
}
