import {Model, Query} from '@vuex-orm/core';
import User from '../../users/models/User';
import CourseRole from '../../users/models/CourseRole';
import Assignment from '@/assignments/models/Assignment';
import CourseLikeAssignment from '@/assignments/models/CourseLikeAssignment';
import {ModelFields} from '@/orm/types/ModelFields';
import Fields from '@vuex-orm/core/lib/model/contracts/Fields';
import {Joined} from '@/orm/types/Joined';
import {RawCourseLike} from '@/courses/types/RawCourseLike';
import {CourseSection, Course} from '@/courses/models';
import {ICourseLikeQueryParameters} from '@/courses/types/ICourseLikeQueryParameters';

export type CourseLikeFields = ModelFields<CourseLike>;

/**
 * CourseLike class
 */
export class CourseLike
  extends Model
  implements Omit<RawCourseLike, 'courseRoles' | 'courseLikeAssignments' | 'sections'>
{
  static entity = 'CourseLike';

  id!: number;
  name!: string;
  description!: string | null;
  color!: string | null;
  courseId!: number;
  course!: Joined<Course>;
  parentId!: number | null;
  sectionIds!: number[];
  sections!: Joined<CourseSection[]>;
  topLevelCourseId!: number;
  participants!: Joined<User[]>;
  courseRoles!: Joined<CourseRole[]>;
  courseLikeAssignments!: Joined<CourseLikeAssignment[]>;
  invitationsSent!: boolean;
  gradeDisplay!: string;
  draftsEnabled!: boolean;
  inAppPaymentEnabled!: boolean;

  static fields(): Fields {
    return {
      id: this.number(null),
      name: this.string(''),
      description: this.string(null).nullable(),
      color: this.string(null).nullable(),
      topLevelCourseId: this.number(null),
      topLevelCourse: this.belongsTo(Course, 'topLevelCourseId'),
      courseId: this.number(0),
      course: this.belongsTo(Course, 'courseId'),
      parentId: this.number(null).nullable(),
      sectionIds: this.attr([]),
      sections: this.hasManyBy(CourseSection, 'sectionIds'),
      participants: this.belongsToMany(User, CourseRole, 'courseLikeId', 'userId'),
      courseRoles: this.hasMany(CourseRole, 'courseLikeId'),
      courseLikeAssignments: this.hasMany(CourseLikeAssignment, 'courseLikeId'),
      invitationsSent: this.boolean(false),
      gradeDisplay: this.string('percentage'),
      inAppPaymentEnabled: this.boolean(false),
      draftsEnabled: this.boolean(true),
    };
  }

  get isTopLevelCourse() {
    return this.topLevelCourseId === this.id;
  }

  get isCourse() {
    return this.courseId === this.id;
  }

  get isSection() {
    return this.courseId !== this.id;
  }

  /**
   * Gets the students (users with "Student" role) in this course.
   * @returns {*}
   */
  get students(): User[] {
    return this.participants.filter((user) => {
      return user.hasCourseRoleByName('Student', this.id);
    });
  }

  get team(): User[] {
    return this.participants.filter((user) => {
      const roles = user.getCourseRolesFor(this.id);
      for (const role of roles) {
        if (role && role.isTeamRole()) {
          return true;
        }
      }
      return false;
    });
  }

  get childrenIds(): number[] {
    return [];
  }

  get assignments(): Assignment[] {
    return this.courseLikeAssignments.map((cla) => cla.assignment);
  }

  static fullQuery(options: ICourseLikeQueryParameters = {}) {
    let q = options.query ?? this.query();

    if (options.courseRoles) {
      q = q.with('courseRoles.role');
    }
    if (options.participants) {
      q = q.with('participants', (q2) => {
        if (options.courseRoles) {
          q2 = q2.with('courseRoles.role');
        }
        if (options.roles) {
          q2 = q2.with('roles');
        }
        return q2;
      });
    }

    if (options.course) {
      q = q.with('course');
    }

    if (options.topLevelCourse) {
      q = q.with('topLevelCourse', (q2) =>
        this.fullQuery({
          query: q2 as Query<Course>,
          participants: options.participants,
          courseRoles: options.courseRoles,
          sections: options.sections,
        })
      );
    }

    if (options.sections ?? true) {
      q = q.with('sections', (q2) =>
        CourseSection.fullQuery({
          query: q2 as Query<CourseSection>,
          participants: options.participants,
          courseRoles: options.courseRoles,
        })
      );
    }

    return q;
  }
}

export default CourseLike;
