import IsOrIsAnyParentOf from '@/permissions/services/permissions-service/specifications/IsOrIsAnyParentOf';
import {CourseLikePolicy} from '@/courses/permissions/CourseLikePolicy';
import {User} from '@/users/models/User';
import {Ability} from '@/permissions/types/Ability';

export class CoursePolicy extends CourseLikePolicy {
  /**
   * Determine whether a user can update the course
   * @param {User} user
   * @param {Number} courseId
   * @returns {boolean}
   */
  update(user: User, courseId: number) {
    return this.permissionService.checkAnyCoursePermission(
      user,
      Ability.UpdateCourses,
      new IsOrIsAnyParentOf(courseId)
    );
  }

  view(user: User, courseId: number) {
    return this.permissionService.checkAnyCoursePermission(
      user,
      Ability.ViewCourses,
      new IsOrIsAnyParentOf(courseId)
    );
  }
}

export default CoursePolicy;
