import {HTTP} from '@/common/api/http';
import {API_BASE_URL} from '@/common/api/base';
import {COURSES_BASE_URL} from '@/courses/api/courses';
import {IStembleAxiosPromise} from '@/common/api/types/IStembleAxiosPromise';
import {IGetAssignmentsParams} from '@/assignments/types/IGetAssignmentsParams';
import {IGetAssignmentParams} from '@/assignments/types/IGetAssignmentParams';
import {ICopyAssignmentsForCourseParams} from '@/assignments/types/ICopyAssignmentsForCourseParams';
import {CreateAssignmentData} from '@/assignments/types/CreateAssignmentData';
import {RawAssignment} from '@/assignments/types/RawAssignment';
import {AxiosPromise, AxiosRequestConfig} from 'axios';

export const ASSIGNMENTS_BASE_URL = API_BASE_URL + '/assignments';

/**
 * Replace an assignment in the database
 * @param {number} id
 * @param {any} data
 * @returns {IStembleAxiosPromise}
 */
export const putAssignment = (
  id: number,
  data: CreateAssignmentData
): IStembleAxiosPromise<RawAssignment> => {
  return HTTP.put(`${ASSIGNMENTS_BASE_URL}/${id}`, data);
};

/**
 * Patch an assignment with the given data
 * @param id
 * @param data
 * @returns {IStembleAxiosPromise}
 */
export const patchAssignment = (
  id: number,
  data: CreateAssignmentData
): IStembleAxiosPromise<RawAssignment> => {
  return HTTP.patch(`${ASSIGNMENTS_BASE_URL}/${id}`, data);
};

/**
 * Copy an assignment. Parameters are the same as patch, except that a new owner
 * @param id
 * @param data
 * @returns {IStembleAxiosPromise}
 */
export const copyAssignment = (id: number, data: any): IStembleAxiosPromise => {
  return HTTP.post(`${ASSIGNMENTS_BASE_URL}/${id}/copy`, data);
};

/**
 *
 * @param {number} courseId
 * @param data
 * @returns {IStembleAxiosPromise}
 */
export const copyAssignmentsForCourse = (
  courseId: number,
  data: ICopyAssignmentsForCourseParams
): IStembleAxiosPromise => {
  return HTTP.post(`${COURSES_BASE_URL}/${courseId}/assignments/copy`, data);
};

/**
 * Create a new assignment
 * @param data
 * @returns {IStembleAxiosPromise}
 */
export const createAssignment = (
  data: CreateAssignmentData
): IStembleAxiosPromise<RawAssignment> => {
  return HTTP.post(ASSIGNMENTS_BASE_URL, data);
};

/**
 * Fetch one or more courses
 * @returns {IStembleAxiosPromise}
 * @param params
 */
export const getAssignments = (
  params: IGetAssignmentsParams = {}
): IStembleAxiosPromise<RawAssignment[]> => {
  return HTTP.get(ASSIGNMENTS_BASE_URL, {params});
};

export const hasResponses = (id: number): IStembleAxiosPromise<{hasResponses: boolean}> => {
  return HTTP.get(`${ASSIGNMENTS_BASE_URL}/${id}/has-responses`);
};

/**
 * Get a single assignment
 * @param id
 * @param params
 * @returns {IStembleAxiosPromise}
 */
export const getAssignment = (
  id: number,
  params: IGetAssignmentParams = {}
): IStembleAxiosPromise => {
  return HTTP.get(`${ASSIGNMENTS_BASE_URL}/${id}`, {params});
};

/**
 * Delete an assignment
 * @param {number} id
 * @returns {IStembleAxiosPromise}
 */
export const deleteAssignment = (id: number): IStembleAxiosPromise => {
  return HTTP.delete(`${ASSIGNMENTS_BASE_URL}/${id}`);
};

export const getAssignmentParticipants = (id: number, sectionId: number | null): AxiosPromise => {
  const config: AxiosRequestConfig = {};
  config.params = {perPage: 5000, sectionId: sectionId};
  return HTTP.get(`${ASSIGNMENTS_BASE_URL}/${id}/participants`, config);
};

export default {
  putAssignment,
  patchAssignment,
  getAssignment,
  createAssignment,
  getAssignments,
  deleteAssignment,
};
