import {AbstractDistribution} from '@/distributions/models/AbstractDistribution';
import {ChemicalIsotopeDistributionDto} from '@/distributions/dtos/ChemicalIsotopeDistributionDto';
import {ChemicalIsotopeValue} from '@/task-values/models/ChemicalIsotopeValue';

export class ChemicalIsotopeDistribution
  extends AbstractDistribution<ChemicalIsotopeValue>
  implements ChemicalIsotopeDistributionDto
{
  static entity = 'ChemicalIsotopeDistribution';
}
