import {ActionFactory} from '@/orm/api/actions';
import {
  createInstitution,
  deleteInstitution,
  getInstitution,
  getInstitutions,
  patchInstitution,
  updateInstitution,
} from '@/institutions/api/institutions';
import Institution from '@/institutions/models/Institution';

const factory = new ActionFactory(() => Institution);

export const fetch = factory.insertAction(getInstitutions);
export const get = factory.insertAction(getInstitution);
export const create = factory.insertAction(createInstitution);
export const post = factory.insertAction(createInstitution);
export const update = factory.insertAction(updateInstitution);
export const put = factory.insertAction(updateInstitution);
export const patch = factory.insertAction(patchInstitution);
export const remove = factory.deleteAction(deleteInstitution);
