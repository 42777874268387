import {AbstractDistribution} from '@/distributions/models/AbstractDistribution';
import {AbstractTaskValue} from '@/task-values/models/AbstractTaskValue';
import {ChoiceDistributionDto} from '@/distributions/dtos/ChoiceDistributionDto';
import {Fields} from '@vuex-orm/core';
import TaskVariableValue from '@/task-states/models/TaskVariableValue';

export class ChoiceDistribution<T extends AbstractTaskValue>
  extends AbstractDistribution<T>
  implements ChoiceDistributionDto<T>
{
  static entity = 'ChoiceDistribution';

  choiceIds!: number[];

  static fields(): Fields {
    return {
      ...super.fields(),
      choiceIds: this.attr([]),
      choices: this.hasManyBy(TaskVariableValue, 'choiceIds'),
    };
  }
}
