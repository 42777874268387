import get from 'lodash.get';
import {makeGlobalSingleton} from '@/container';
import {icons as globalIcons} from '@/icons/utils';

export interface IconFunctionParams {
  [key: string]: any;
}

export interface IconsObject {
  [key: string]: IconDefinition;
}

export type IconFunction = (params?: IconFunctionParams) => string;
export type IconResult = string | IconsObject;
export type IconDefinition = IconResult | IconFunction;

export class IconService {
  static injectable = makeGlobalSingleton(() => {
    return new IconService(globalIcons);
  });

  protected icons: IconsObject;

  private constructor(icons: IconsObject) {
    this.icons = icons;
  }

  resolve(iconKey: string, params?: IconFunctionParams): IconResult {
    const iconResolver: IconDefinition = get(this.icons, iconKey);
    if (typeof iconResolver === 'function') {
      return iconResolver(params);
    }
    return iconResolver;
  }
}
