import Specification from './Specification';

export class IsActive extends Specification {
  constructor(isActive = true, strict = false) {
    super();
    this.isActive = isActive;
    this.strict = strict;
  }

  isSatisfiedBy(courseRole) {
    return this.strict
      ? courseRole.isActive === this.isActive
      : !this.isActive || courseRole.isActive;
  }
}

export default IsActive;
