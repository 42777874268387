import {makeGlobalSingleton} from '@/container';
import User from '@/users/models/User';
import {getVisitorToken} from '@/onboarding/api/hubspot';
import {AuthService, useAuthService} from '@/auth/services/authService';
import {loadScriptOnce} from '@/loading/utils/dynamic-loading';
import {getEnvironment} from '@/environment';

export const HubspotService = makeGlobalSingleton(() => makeHubspotService(useAuthService()));

function makeHubspotService(authService: AuthService) {
  const hubspotId = getEnvironment().hubspotId;

  if (hubspotId) {
    window.hsConversationsOnReady = [identifyUser];
    window.hsConversationsSettings = {
      loadImmediately: false,
    };
    loadScriptOnce(`//js.hs-scripts.com/${hubspotId}.js`, {}, true);
  }

  async function identifyUser(user?: User) {
    try {
      user = user ?? (await authService.fetchCurrentUser().catch(() => {}));

      if (user) {
        const response = await getVisitorToken(user);
        setVisitorIdentification(user, response.data.data?.visitorToken);
      }
    } finally {
      refreshWidget();
    }
    return user;
  }

  function resetUser() {
    window.hsConversationsSettings = null;
    if (window.HubSpotConversations) {
      // TODO: This is not properly clearing cookies associated with the contact.  As a result any subsequent visitors
      // will all be associated with the same contact.  I currently have a support issue open with Hubspot to see if
      // there's a workaround.  This would only be an issue with multiple users sharing the same machine/browser.
      window.HubSpotConversations.clear({resetWidget: true});
    }
  }

  function refreshWidget() {
    if (window.HubSpotConversations) {
      const status = window.HubSpotConversations.widget.status();

      if (status.loaded) {
        window.HubSpotConversations.widget.refresh();
      } else {
        window.HubSpotConversations.widget.load();
      }
    }
  }

  function setVisitorIdentification(user: User, visitorToken?: string) {
    window.hsConversationsSettings = {
      identificationEmail: user.email,
      identificationToken: visitorToken,
    };
  }

  return {
    refreshWidget,
    identifyUser,
    resetUser,
  };
}
