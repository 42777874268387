import Question from './Question';
import {Fields} from '@vuex-orm/core';
import {RawBooleanTask} from '@/tasks/types/RawTask';

/**
 * BooleanTask class
 */
export class BooleanTask extends Question implements RawBooleanTask {
  static entity = 'BooleanTask';

  static fields(): Fields {
    return {
      ...super.fields(),
    };
  }
}

export default BooleanTask;
