import {inject} from '@/container';
import {AuthService} from '@/auth/services/authService';
import User from '@/users/models/User';
import {DependencyType} from '@/container/types/DependencyType';
import {HubspotService} from '@/onboarding/services/HubspotService';
import {getEnvironment} from '@/environment';
import {IVueAnalyticsPluginOptions} from '@/telemetry/plugins/vue-analytics/IVueAnalyticsPluginOptions';

let hubspotService: DependencyType<typeof HubspotService> | null = null;

export function install(Vue: Vue, {auth}: IVueAnalyticsPluginOptions) {
  const hubspotId = getEnvironment().hubspotId;
  const isInertiaPage = window.location.pathname.startsWith('/inertia/task');

  if (hubspotId && !isInertiaPage) {
    hubspotService = inject(HubspotService);
    setupAuth(auth);
  }
}

/**
 * Sets up identify and reset calls when auth events happen
 * @param authService
 */
const setupAuth = (authService: AuthService) => {
  authService.postUserLoad((user: User) => {
    hubspotService?.identifyUser(user);
  });
  authService.postLogout(() => {
    hubspotService?.resetUser();
  });
};
