import {Fields} from '@vuex-orm/core';
import User from '@/users/models/User';
import {Joined} from '@/orm/types/Joined';
import {RawGrade} from '@/grades/types/RawGrade';
import {GradeAdjustment} from '@/grades/models/GradeAdjustment';
import {BaseGradeQueryParameters} from '@/grades/types/BaseGradeQueryParameters';
import {StembleModel} from '@/common/models/StembleModel';

/**
 * BaseGrade class
 *
 * Abstract class from which all types of grades will inherit.
 */
export default class BaseGrade
  extends StembleModel
  implements Omit<RawGrade, 'id' | 'gradeAdjustments'>
{
  pointsReceived!: number;
  pointsAvailable!: number;
  exactNumerator!: number;
  exactDenominator!: number;
  originalNumerator!: number;
  originalDenominator!: number;
  isEffective!: boolean;
  createdAt!: string;
  submittedById!: number;
  submittedBy!: Joined<User>;
  submittedByName!: string;
  overrideReason!: string | null;
  gradeAdjustments!: Joined<GradeAdjustment[]>;
  isManuallyEntered!: boolean;

  static fields(): Fields {
    return {
      pointsReceived: this.number(null),
      pointsAvailable: this.number(null),
      exactNumerator: this.number(null),
      exactDenominator: this.number(null),
      originalNumerator: this.number(null),
      originalDenominator: this.number(null),
      isEffective: this.boolean(false),
      createdAt: this.string('').nullable(),
      submittedById: this.number(null).nullable(),
      submittedBy: this.belongsTo(User, 'submittedById'),
      submittedByName: this.string(null),
      overrideReason: this.string(null).nullable(),
      gradeAdjustments: this.hasMany(GradeAdjustment, 'gradeId'),
      isManuallyEntered: this.boolean(false),
    };
  }

  static fullQuery({query, gradeAdjustments = true}: BaseGradeQueryParameters) {
    let q = query ?? this.query();
    if (gradeAdjustments) {
      q = q.with('gradeAdjustments');
    }
    return q;
  }

  /**
   * Gets the grade as a number between zero and one.
   * @returns {number}
   */
  get decimalGrade(): number {
    return this.exactNumerator / this.exactDenominator;
  }

  get originalGrade(): number {
    return this.originalNumerator / this.originalDenominator;
  }

  get integerGrade(): number {
    return parseInt((100 * this.decimalGrade).toFixed(0));
  }

  get originalIntegerGrade(): number {
    return parseInt((100 * this.originalGrade).toFixed(0));
  }

  private static _correct(num: number, denom: number): boolean {
    return denom !== 0 && num === denom;
  }

  isCorrect(): boolean {
    return BaseGrade._correct(this.exactNumerator, this.exactDenominator);
  }

  isOriginalCorrect(): boolean {
    return BaseGrade._correct(this.originalNumerator, this.originalDenominator);
  }

  private static _partiallyCorrect(num: number, denom: number): boolean {
    return denom !== 0 && num > 0 && num < denom;
  }

  isPartiallyCorrect(): boolean {
    return BaseGrade._partiallyCorrect(this.exactNumerator, this.exactDenominator);
  }

  isOriginalPartiallyCorrect(): boolean {
    return BaseGrade._partiallyCorrect(this.originalNumerator, this.originalDenominator);
  }

  isManuallySubmitted(): boolean {
    return this.submittedById !== null;
  }

  makeIneffective(): void {
    this.$update({
      isEffective: false,
    });
  }

  // Lifecycle hooks to maintain the mapping
  static afterCreate(model: BaseGrade) {
    // TODO: this is undefined. Likely a bug in VuexORM, so work around by getting prototype
    const cls = Object.getPrototypeOf(model).constructor;
    const f = cls.updateMap.bind(cls);
    return f(model);
  }

  static afterUpdate(model: BaseGrade) {
    // See above comment about potential Vuex ORM bug
    const cls = Object.getPrototypeOf(model).constructor;
    const f = cls.updateMap.bind(cls);
    if (model) {
      return f(model);
    }
    return null;
  }

  // eslint-disable-next-line no-unused-vars
  static updateMap(model: BaseGrade): void {}

  static afterDelete(model: BaseGrade) {
    // See above comment about potential Vuex ORM bug
    const cls = Object.getPrototypeOf(model).constructor;
    const f = cls.deleteMapEntry.bind(cls);
    if (model) {
      return f(model);
    }
    return null;
  }

  // eslint-disable-next-line no-unused-vars
  static deleteMapEntry(model: BaseGrade): void {}
}
