import {StringValueDto} from '@/task-values/dtos/StringValueDto';
import {Fields} from '@vuex-orm/core';
import {AbstractTaskValue} from '@/task-values/models/AbstractTaskValue';

export class StringValue extends AbstractTaskValue<string> implements StringValueDto {
  static entity = 'StringValue';

  value!: string;

  static fields(): Fields {
    return {
      ...super.fields(),
      value: this.string(null),
    };
  }

  getValue(): string {
    return this.value;
  }
}
