



















import EducationalQuote from '@/loading/components/EducationalQuote.vue';
import LoginPagesCard from '@/auth/components/LoginPagesCard.vue';
import {defineComponent, PropType} from '@vue/composition-api';

export default defineComponent({
  name: 'LoadingOverlay',
  components: {
    LoginPagesCard,
    EducationalQuote,
  },
  props: {
    opacity: {
      type: [String, Number] as PropType<string | number>,
      default: 0.7,
    },
    color: {
      type: String as PropType<string>,
      default: 'grey darken-4',
    },
    progressValue: {
      type: Number as PropType<number>,
      default: null,
    },
    message: {
      type: String as PropType<string>,
      default: null,
    },
  },
});
