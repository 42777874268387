import {AbstractDistribution} from '@/distributions/models/AbstractDistribution';
import LegacyNumberValue from '@/task-values/models/legacy/LegacyNumberValue';
import {UniformNumberDistributionDto} from '@/distributions/dtos/UniformNumberDistributionDto';
import {Fields} from '@vuex-orm/core';
import {NumberValue} from '@/task-values/models/NumberValue';

export class UniformNumberDistribution
  extends AbstractDistribution<NumberValue>
  implements UniformNumberDistributionDto
{
  static entity = 'UniformNumberDistribution';

  minimum!: number;
  maximum!: number;
  step!: number;
  numSigFigs!: number;
  numDecimals!: number;
  offset!: number;

  static fields(): Fields {
    return {
      ...super.fields(),
      minimum: this.number(null),
      maximum: this.number(null),
      step: this.number(null),
      numSigFigs: this.number(null),
      numDecimals: this.number(null),
      offset: this.number(0),
    };
  }
}
