<template>
  <v-card v-bind="$attrs" class="v-card--material">
    <div v-if="hasHeading" class="d-flex grow flex-wrap">
      <v-sheet
        :class="{
          'pa-5': !hasImage,
          'px-3': !hasImage,
          'py-2': !hasImage,
          'mx-auto': center,
        }"
        :color="color"
        width="100%"
        class="text-start d-flex rounded-bl-0 rounded-br-0"
        dark
      >
        <v-icon v-if="icon" :size="iconSize" class="mr-2" v-text="icon" />

        <slot v-if="$slots.heading" name="heading" />

        <slot v-else-if="hasImage" name="image">
          <v-img :src="image" />
        </slot>
      </v-sheet>

      <div v-if="$slots['after-heading']" class="ml-6">
        <slot name="after-heading" />
      </div>

      <v-col v-if="hoverReveal" cols="12" class="text-center py-0 mt-n12">
        <slot name="reveal-actions" />
      </v-col>
    </div>

    <div :class="cardPadding">
      <slot />
    </div>

    <template v-if="$slots.actions">
      <v-divider class="mt-2" />

      <v-card-actions class="pb-0">
        <slot name="actions" />
      </v-card-actions>
    </template>

    <template v-if="$slots.progress">
      <slot name="progress" />
    </template>
  </v-card>
</template>

<script>
export default {
  name: 'StembleCard',

  props: {
    avatar: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: undefined,
    },
    hoverReveal: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: undefined,
    },
    iconSize: {
      type: Number,
      default: 16,
    },
    image: {
      type: String,
      default: null,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    center: {
      type: Boolean,
      default: false,
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
    padding: {
      type: String,
      default: '',
    },
    spacer: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    hasIcon() {
      return this.icon;
    },
    hasImage() {
      return this.image || !!this.$slots.image;
    },
    hasHeading() {
      return Boolean(this.icon || this.hasImage || this.$slots.heading);
    },
    cardPadding() {
      if (this.noPadding) {
        return '';
      }
      return `pa-${this.padding || '5'}`;
    },
  },
};
</script>

<style scoped lang="sass">
.v-card--material
  position: relative

#app .v-card--material
  &__heading
    position: relative
    transition: .3s ease
    z-index: 1
</style>
