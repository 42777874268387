import Specification from './Specification';

export class Is extends Specification {
  constructor(courseLikes) {
    super();
    this.courseLikes = Specification.getIds(courseLikes);
  }

  isSatisfiedBy(courseRole) {
    return this.courseLikes.includes(courseRole.courseLikeId);
  }
}

export default Is;
