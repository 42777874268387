import {BooleanValueDto} from '@/task-values/dtos/BooleanValueDto';
import {Fields} from '@vuex-orm/core';
import {AbstractTaskValue} from '@/task-values/models/AbstractTaskValue';

export class BooleanValue extends AbstractTaskValue<boolean> implements BooleanValueDto {
  static entity = 'BooleanValue';

  value!: boolean;

  static fields(): Fields {
    return {
      ...super.fields(),
      value: this.boolean(false),
    };
  }

  getValue(): boolean {
    return this.value;
  }
}
