import {VueConstructor} from 'vue';
import TextHighlight from 'vue-text-highlight';

const TextHighlightPlugin = {
  install(Vue: VueConstructor) {
    // making our modal component global
    Vue.component('text-highlight', TextHighlight);
  },
};

export default TextHighlightPlugin;
