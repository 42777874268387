import {ActionFactory} from '@/orm/api/actions';
import {
  batchCourseLikeAssignment,
  getCourseLikeAssignment,
  getCourseLikeAssignments,
  getCourseLikeAssignmentsForAssignment,
} from '@/assignments/api/course-like-assignments';
import CourseLikeAssignment from '@/assignments/models/CourseLikeAssignment';
import {RawCourseLikeAssignment} from '@/assignments/types/RawCourseLikeAssignment';
import {
  ASSIGNMENT_POLICY_TYPE_CLASS_MAP,
  AssignmentPolicy,
  nestAssignmentPolicy,
} from '@/assignment-policies/models/AssignmentPolicy';

const factory = new ActionFactory(() => CourseLikeAssignment);

/**
 * Remap a single CourseLikeAssignment's policies, mutating in place
 * @param cla
 */
const remapRawCourseLikeAssignment = (cla: RawCourseLikeAssignment): RawCourseLikeAssignment => {
  if (cla.assignmentPolicies) {
    cla.assignmentPolicies = cla.assignmentPolicies.map(nestAssignmentPolicy);
    // FIXME: Workaround to fix supposed Vuex ORM bug updating nested content model
    const oldPolicies = AssignmentPolicy.query().where('courseLikeAssignmentId', cla.id).get();
    oldPolicies.forEach((oldPolicy) => {
      AssignmentPolicy.delete(oldPolicy.id);
      ASSIGNMENT_POLICY_TYPE_CLASS_MAP[oldPolicy.type].delete(oldPolicy.id);
    });
  }
  return cla;
};

/**
 * Wrap an API-like function that returns a single or array of CourseLikeAssignments under
 * the `data` attribute
 * @param func
 * @param wrapType the expected type of `data` in the API response
 */
const wrapCourseLikeAssignmentReturnFunction = <F extends (...args: any[]) => Promise<any>>(
  func: F,
  wrapType: 'array' | 'object' | 'either'
) => {
  return async (...args: Parameters<F>) => {
    const response = await func(...args);
    if (response.data.data) {
      if (wrapType === 'array' || (wrapType === 'either' && Array.isArray(response.data.data))) {
        response.data.data = response.data.data.map(remapRawCourseLikeAssignment);
      } else if (wrapType === 'object' || typeof response.data.data === 'object') {
        response.data.data = remapRawCourseLikeAssignment(response.data.data);
      }
    }
    return response;
  };
};

export const fetch = factory.insertAction(
  wrapCourseLikeAssignmentReturnFunction(getCourseLikeAssignments, 'array')
);

export const fetchForAssignment = factory.insertAction(
  wrapCourseLikeAssignmentReturnFunction(getCourseLikeAssignmentsForAssignment, 'array')
);
export const get = factory.insertAction(
  wrapCourseLikeAssignmentReturnFunction(getCourseLikeAssignment, 'object')
);
export const batch = factory.batchAction(batchCourseLikeAssignment);
