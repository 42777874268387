import {Fields, Model, Query} from '@vuex-orm/core';
import {Joined} from '@/orm/types/Joined';
import {ITaskVariableValueQueryParameters} from '@/task-states/types/ITaskVariableValueQueryParameters';
import {VariableValueType} from '@/task-states/types/RawTaskVariableValue';
import {TaskVariableValueMap} from '@/task-states/types/TaskVariableValueMap';

/**
 * TaskVariableValue class
 */
export class TaskVariableValue<
  ValueType extends VariableValueType = VariableValueType,
> extends Model {
  static entity = 'TaskVariableValue';

  id!: number;
  valueType!: ValueType;
  content!: Joined<TaskVariableValueMap[ValueType]>;

  static fields(): Fields {
    return {
      id: this.number(null),
      valueType: this.string(null),
      content: this.morphTo('id', 'valueType'),
    };
  }

  /**
   * @returns Query<TaskVariableValue>
   * @param options
   */
  static fullQuery(options: ITaskVariableValueQueryParameters = {}): Query<TaskVariableValue> {
    let q = options.query ?? this.query();

    if (options.content ?? true) {
      q = q.with('content');
    }

    return q;
  }
}

export default TaskVariableValue;
