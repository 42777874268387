import * as Sentry from '@sentry/vue';
import {ErrorReporter} from './error-reporter';
import {CustomError} from '@/errors/types/CustomError';
import Vue from 'vue';
import VueRouter from 'vue-router';

export class SentryReporter implements ErrorReporter {
  constructor(app: Vue, router: VueRouter, dsn: string, environment: string, release: string) {
    Sentry.init({
      Vue,
      dsn,
      integrations: [
        new Sentry.BrowserTracing({
          // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
          tracePropagationTargets: [
            'localhost',
            /^https:\/\/app.stemble\.ca\//,
            /^https:\/\/app.staging.stemble\.ca\//,
          ],
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
        new Sentry.Replay(),
      ],
      // Performance Monitoring
      tracesSampleRate: 0.2, // Capture 100% of the transactions
      // Session Replay
      replaysSessionSampleRate: 0.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 0.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      environment: environment ?? 'local',
      release: release ?? 'LATEST',
    });
  }

  async reportError(err: CustomError): Promise<void> {
    Sentry.captureException(err);
  }

  async reportErrors(errors: CustomError[]): Promise<void[]> {
    return Promise.all(errors.map((err: Error) => this.reportError(err)));
  }
}

export default SentryReporter;
