export enum GradeAdjustmentType {
  Relative = 'relative',
  Absolute = 'absolute',
}

export enum AdjustmentDirection {
  Bonus = 'bonus',
  Penalty = 'penalty',
}

export type RawGradeAdjustment = {
  id: number;
  adjustmentType: GradeAdjustmentType;
  gradeId: number;
  value: number;
  policyId: number | null;
  reason: string | null;
  submittedById: number | null;
  submittedByName: string | null;
};
