import {HTTP} from './http';
import {USERS_BASE_URL} from '@/users/api/users';
import {IStembleAxiosPromise} from '@/common/api/types/IStembleAxiosPromise';

export const updateEmail = async (
  userId: number,
  email: string,
  markVerified = false
): IStembleAxiosPromise => {
  return HTTP.put(USERS_BASE_URL + `/${userId}/email`, {
    email,
    markVerified,
  });
};
