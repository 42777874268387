/**
 * Returns a new authorization function to check a user's can() method
 * @param args
 * @returns {function(User): Boolean}
 */
export const can = (...args) => {
  return ({user}) => user.can(...args);
};

/**
 * Returns a new authorization function to check a user's cannot() method
 * @param args
 * @returns {function(User): Boolean}
 */
export const cannot = (...args) => {
  return ({user}) => user.cannot(...args);
};

/**
 * Returns a new authorization function to check if a user is a super user
 * @returns {function(User): Boolean}
 */
export const isSuperUser = () => {
  return ({user}) => user.isSuperUser();
};

/**
 * Returns a new authorization function to check if a user is faculty
 * @returns {function(User): Boolean}
 */
export const isFaculty = () => {
  return ({user}) => user.isFaculty();
};
