import {Fields, Query} from '@vuex-orm/core';
import User from './User';
import Role from '@/users/models/Role';
import {Course, CourseSection} from '@/courses/models';

import * as CourseRoleApi from '@/users/api/orm/CourseRoleApi';
import {ICourseRoleQueryParameters} from '@/users/types/ICourseRoleQueryParameters';
import {RawCourseRole} from '@/users/types/RawCourseRole';
import {CourseLikeType} from '@/courses/types/CourseLikeType';
import {Joined} from '@/orm/types/Joined';
import {StembleModel} from '@/common/models/StembleModel';
import {RoleName} from '@/users/types/RoleName';

/**
 * CourseRole model that shows a user's role in a course-like
 */
export default class CourseRole
  extends StembleModel
  implements Omit<RawCourseRole, 'user' | 'role'>
{
  static entity = 'CourseRole';

  id!: number;
  userId!: number;
  user!: Joined<User>;
  roleId!: number;
  role!: Joined<Role>;
  courseLikeId!: number;
  courseLikeType!: CourseLikeType;
  courseLike!: Joined<Course | CourseSection>;
  isActive!: boolean;

  static fields(): Fields {
    return {
      id: this.number(null),
      userId: this.number(null),
      user: this.belongsTo(User, 'userId'),
      roleId: this.number(null),
      role: this.belongsTo(Role, 'roleId'),
      courseLikeId: this.number(null),
      courseLikeType: this.string(null),
      courseLike: this.morphTo('courseLikeId', 'courseLikeType'),
      isActive: this.boolean(false),
    };
  }

  static get api() {
    return CourseRoleApi;
  }

  /**
   * Checks to see if this role has permission to do something
   *
   * @param ability the permission to check
   * @returns {boolean}
   */
  hasPermissionTo(ability: string) {
    return this.role?.hasPermissionTo(ability) || false;
  }

  /**
   * Checks to see if a this role is a valid team role
   * @returns {boolean}
   */
  isTeamRole(): boolean {
    return this.role?.isTeamRole() || false;
  }

  /**
   * Checks if the role is a read only role
   * @returns {boolean}
   */
  isReadOnlyRole(): boolean {
    return (
      this.role?.name === RoleName.CourseInstructorReadOnly ||
      this.role?.name === RoleName.LabInstructorReadOnly
    );
  }

  /**
   *
   * @returns {Query}
   * @param options
   */
  static fullQuery(options: ICourseRoleQueryParameters = {}): Query<CourseRole> {
    let q = options.query ?? this.query();

    if (options.user) {
      q = q.with('user');
    }

    if (options.role) {
      q = q.with('role');
    }

    if (options.courseLike) {
      q = q.with('courseLike');
    }

    return q;
  }

  static highestOrderRole(roles: CourseRole[]) {
    return roles.length > 0
      ? roles.reduce((prev, current) => {
          const previousRoleIndex = prev.role ? Role.ROLE_HIERARCHY.indexOf(prev.role.name) : -1;
          const currentRoleIndex = current.role
            ? Role.ROLE_HIERARCHY.indexOf(current.role.name)
            : -1;
          return previousRoleIndex > currentRoleIndex ? prev : current;
        })
      : null;
  }
}
