import {HTTP} from '@/common/api/http';
import {API_BASE_URL} from '@/common/api/base';
import {IStembleAxiosPromise} from '@/common/api/types/IStembleAxiosPromise';
import {IGetAssignmentExtensionsParams} from '@/assignments/types/IGetAssignmentExtensionsParams';

export const ASSIGNMENT_EXTENSIONS_BASE_URL = API_BASE_URL + '/assignment-extensions';

/**
 * Gets assignment extensions
 * @returns {IStembleAxiosPromise}
 * @param params
 */
export const getAssignmentExtensions = (
  params: IGetAssignmentExtensionsParams = {}
): IStembleAxiosPromise => {
  return HTTP.get(ASSIGNMENT_EXTENSIONS_BASE_URL, {params});
};
