import Specification from './Specification';

export class IsInSameCourseAs extends Specification {
  constructor(courseLikes) {
    super();
    this.courseLikes = Specification.getIds(courseLikes);
  }

  isSatisfiedBy(courseRole) {
    for (const courseLikeId of this.courseLikes) {
      if (this.isSameCourse(courseRole.courseLikeId, courseLikeId)) {
        return true;
      }
    }
    return false;
  }

  isSameCourse(candidate, courseLike) {
    const courseId = this.courseHierarchyService.getCourseId(courseLike);
    if (courseId !== null) {
      return courseId === this.courseHierarchyService.getCourseId(candidate);
    }
    return false;
  }
}

export default IsInSameCourseAs;
