/**
 * Global filters for the error reporting
 */
import {ErrorFilter} from '@/errors/types/ErrorFilter';

export const filters: ErrorFilter[] = [
  // Vue Router redundant navigation
  (err: Error) => {
    return !err.message.includes('Avoided redundant navigation to ');
  },
  // Vue Router cancelled navigation
  (err: Error) => {
    return !err.message.includes('Navigation cancelled from ');
  },
  // Aborted requests
  (err: Error) => {
    return err.message !== 'Request Aborted';
  },
];
