import VuetifyDefault from 'vuetify/lib';
import i18n from '@/locales/composables/i18n';
import {makeGlobalSingleton} from '@/container';
import {darkTheme, lightTheme} from '@/theme/themes';

export const VuetifyService = makeGlobalSingleton(
  () =>
    new VuetifyDefault({
      lang: {
        t: (key: string, ...params: any[]) => i18n.t(key, params) as string,
      },
      theme: {
        themes: {
          dark: darkTheme,
          light: lightTheme,
        },
      },
    })
);
