import {Fields} from '@vuex-orm/core';
import {ChemicalElementValueDto} from '@/task-values/dtos/ChemicalElementValueDto';
import {ModelFields} from '@/orm/types/ModelFields';
import {AbstractTaskValue} from '@/task-values/models/AbstractTaskValue';
import {RawElement} from '@/chemistry/types/RawElement';
import {ChemicalIsotopeValue} from '@/task-values/models/ChemicalIsotopeValue';
import LegacyChemicalIsotopeValue from '@/task-values/models/legacy/LegacyChemicalIsotopeValue';

export class ChemicalElementValue<
    K extends string | number = string,
    I extends LegacyChemicalIsotopeValue | ChemicalIsotopeValue = ChemicalIsotopeValue,
  >
  extends AbstractTaskValue<RawElement, K>
  implements ChemicalElementValueDto
{
  static entity = 'ChemicalElementValue';

  symbol!: string;
  name!: string;
  mass!: string;
  atomicNumber!: number;
  period!: number;
  group!: number;
  isotopes!: ModelFields<I>[];

  static fields(): Fields {
    return {
      ...super.fields(),
      symbol: this.string(''),
      name: this.string(''),
      mass: this.string(''),
      atomicNumber: this.number(null),
      period: this.number(''),
      group: this.number(''),
      isotopes: this.attr([]),
    };
  }

  getValue() {
    return this;
  }
}
