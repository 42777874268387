import {NumberValueDto} from '@/task-values/dtos/NumberValueDto';
import {AbstractTaskValue} from '@/task-values/models/AbstractTaskValue';
import {Fields} from '@vuex-orm/core';
import {RawNumber} from '@/task-values/types/RawNumber';

// FIXME: Not sure how to best deal with copied code without multi-class inheritance...
export class NumberValue<K extends string | number = string>
  extends AbstractTaskValue<RawNumber, K>
  implements NumberValueDto
{
  static entity = 'NumberValue';

  sympyValue!: string;
  number!: number;
  shift!: number;
  sigFigs!: number;
  scientificNotationNumber!: string;
  scientificNotationExponent!: number;
  isScientificNotation!: boolean;

  static fields(): Fields {
    return {
      ...super.fields(),
      sympyValue: this.string(null),
      number: this.number(null),
      shift: this.number(null),
      sigFigs: this.number(null),
      scientificNotationNumber: this.string(null),
      scientificNotationExponent: this.number(null),
      isScientificNotation: this.boolean(false),
    };
  }

  get decimalNumber(): string {
    if (this.number == 0) {
      return '0';
    }

    const strInt = this.number.toString();
    const decimalPointLoc = this.nDigits + this.shift;

    if (decimalPointLoc >= this.nDigits) {
      // Needs right-side zero padding
      return strInt + '0'.repeat(this.shift);
    } else if (decimalPointLoc > 0) {
      // Needs no zero padding
      return strInt.substr(0, decimalPointLoc) + '.' + strInt.substr(decimalPointLoc);
    } else {
      const decimal = '0.' + '0'.repeat(Math.abs(decimalPointLoc));
      return decimal + strInt;
    }
  }

  protected get nDigits(): number {
    return this.number.toString().length;
  }

  toFloat() {
    return this.number * Math.pow(10, this.shift);
  }

  toInt() {
    return this.number;
  }

  getValue(): RawNumber {
    return this;
  }
}
