export function substituteMustache(
  subject: string,
  replacements: Record<string, string | number | boolean | null> | null = null
) {
  Object.entries(replacements ?? {})
    .map(([key, value]) => [key, String(value)])
    .forEach(([key, replacement]) => {
      subject = subject.replace(new RegExp(`{{\\s*${key}\\s*}}`, 'g'), replacement);
    });

  return subject;
}
