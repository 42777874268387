import {Fields, Model} from '@vuex-orm/core';
import TaskFeedback from './TaskFeedback';
import {Joined} from '@/orm/types/Joined';
import TaskResponse from '@/tasks/models/TaskResponse';
import {ITaskResponseTaskFeedbackQueryParameters} from '@/tasks/types/ITaskResponseTaskFeedbackQueryParameters';
import {RawTaskResponseTaskFeedback} from '@/tasks/types/RawTaskResponseTaskFeedback';

export const DEFAULT_PART_NAME = 'default';

/**
 * TaskResponseTaskFeedback class
 */
export class TaskResponseTaskFeedback
  extends Model
  implements Omit<RawTaskResponseTaskFeedback, 'feedback' | 'response'>
{
  static entity = 'TaskResponseTaskFeedback';

  id!: number;
  part!: string;
  responseId!: number;
  response!: Joined<TaskResponse>;
  feedbackId!: number;
  feedback!: Joined<TaskFeedback>;
  feedbackData!: Record<string, number | string | boolean | null> | null;

  static fields(): Fields {
    return {
      id: this.number(null),
      part: this.string(null).nullable(),
      responseId: this.number(null),
      response: this.belongsTo(TaskResponse, 'responseId'),
      feedbackId: this.number(null),
      feedback: this.belongsTo(TaskFeedback, 'feedbackId'),
      feedbackData: this.attr(null),
    };
  }

  static fullQuery(options?: ITaskResponseTaskFeedbackQueryParameters) {
    let q = options?.query ?? this.query();
    if (options?.includeResponse) {
      q = q.with('response');
    }
    if (options?.includeFeedback ?? true) {
      q = q.with('feedback');
    }
    return q;
  }
}

export default TaskResponseTaskFeedback;
