import {HTTP} from '@/common/api/http';
import {USERS_BASE_URL} from '@/users/api/users';
import {COURSES_BASE_URL} from '@/courses/api/courses';
import {API_BASE_URL} from '@/common/api/base';
import {IGetTaskGradesParams} from '@/grades/types/IGetTaskGradesParams';
import {IStembleAxiosPromise} from '@/common/api/types/IStembleAxiosPromise';
import {IGetGradeMatrixForSectionParams} from '@/grades/types/IGetGradeMatrixForSectionParams';
import {ISubmitManualTaskGradeData} from '@/grades/types/ISubmitManualTaskGradeData';

export const GRADES_BASE_URL = API_BASE_URL + '/task-grades';

/**
 * Fetch many task grades
 * @returns {IStembleAxiosPromise}
 * @param params
 */
export const getTaskGrades = async (params: IGetTaskGradesParams = {}): IStembleAxiosPromise => {
  return HTTP.get(GRADES_BASE_URL, {params});
};

/**
 * Gets a user's total assignment grade for an assignment
 * @param userId
 * @param assignmentId
 * @returns {IStembleAxiosPromise}
 */
export const getGradeForUserOnAssignment = async (
  userId: number,
  assignmentId: number
): IStembleAxiosPromise => {
  const url = `${USERS_BASE_URL}/${userId}/assignments/${assignmentId}/grade`;
  return HTTP.get(url);
};

/**
 * Get the grades for a user on a single assignment
 * @param {Number} userId
 * @param {Number} assignmentId
 * @param {Boolean} effectiveOnly
 * @returns {IStembleAxiosPromise}
 */
export const getGradesForUserOnAssignment = async (
  userId: number,
  assignmentId: number,
  effectiveOnly: boolean = false
): IStembleAxiosPromise => {
  const params: any = {};
  if (effectiveOnly) {
    params['effective'] = true;
  }
  const url = `${USERS_BASE_URL}/${userId}/assignments/${assignmentId}/grades`;
  return HTTP.get(url, {params});
};

/**
 * Get the grades for a user on a specific task in an assignment
 * @param {Number} userId
 * @param {Number} assignmentId
 * @param {Number} taskId
 * @returns {IStembleAxiosPromise}
 */
export const getGradesForUserOnTask = async (
  userId: number,
  assignmentId: number,
  taskId: number
): IStembleAxiosPromise => {
  return HTTP.get(`${USERS_BASE_URL}/${userId}/assignments/${assignmentId}/tasks/${taskId}/grades`);
};

/**
 * Fetches the assignment grades for all users in a course section
 *
 * @param {Number} courseId
 * @param {Number} sectionId
 * @returns {IStembleAxiosPromise}
 */
export const getAssignmentGradesForSection = async (
  courseId: number,
  sectionId: number
): IStembleAxiosPromise => {
  const url = `${COURSES_BASE_URL}/${courseId}/sections/${sectionId}/grades`;
  return HTTP.get(url);
};

/**
 * Fetches the assignment grades for all of a user's assignments
 *
 * @param {Number} userId
 * @returns {IStembleAxiosPromise}
 */
export const getAssignmentGradesForUser = async (userId: number): IStembleAxiosPromise => {
  return HTTP.get(`${USERS_BASE_URL}/${userId}/assignment-grades`);
};

/**
 * Fetches the total grades for all users in a course section
 *
 * @param {Number} courseId
 * @param {Number} sectionId
 * @returns {IStembleAxiosPromise}
 */
export const getTotalGradesForSection = async (
  courseId: number,
  sectionId: number
): IStembleAxiosPromise => {
  const url = `${COURSES_BASE_URL}/${courseId}/sections/${sectionId}/total-grades`;
  return HTTP.get(url);
};

/**
 * Gets the grade matrix for a section.
 *
 * The options array is passed as GET parameters to the API.
 *
 * @param {Number} courseId
 * @param {Number} sectionId
 * @param params
 * @returns {IStembleAxiosPromise}
 */
export const getGradeMatrixForSection = async (
  courseId: number,
  sectionId: number,
  params: IGetGradeMatrixForSectionParams = {}
): IStembleAxiosPromise => {
  const url = `${COURSES_BASE_URL}/${courseId}/sections/${sectionId}/grade-matrix`;
  return HTTP.get(url, {
    params: {
      sectionGrades: true,
      assignmentGrades: true,
      ...params,
    },
  });
};

/**
 * Submit an overriding task grade
 * @param {ISubmitManualTaskGradeData} data
 */
export const submitManualTaskGrades = async (
  data: ISubmitManualTaskGradeData
): IStembleAxiosPromise => {
  return HTTP.post(GRADES_BASE_URL, data);
};

export default {
  getTaskGrades,
  getGradeForUserOnAssignment,
  getGradesForUserOnAssignment,
  getGradesForUserOnTask,
  getAssignmentGradesForSection,
  getTotalGradesForSection,
  getGradeMatrixForSection,
  getAssignmentGradesForUser,
  submitManualTaskGrades,
};
