import {HTTP} from '@/common/api/http';
import {SCHEDULES_BASE_URL} from './event-schedules';

/**
 * Get the meta-events for an event schedule
 * @param {Number} scheduleId
 * @returns {AxiosPromise<any>}
 */
export const getMetaEventsFor = (scheduleId) =>
  HTTP.get(`${SCHEDULES_BASE_URL}/${scheduleId}/meta-events`);

/**
 * Get a meta-event from a specific event schedule
 * @param {Number} scheduleId
 * @param {Number} metaEventId
 * @returns {AxiosPromise<any>}
 */
export const getMetaEvent = (scheduleId, metaEventId) =>
  HTTP.get(`${SCHEDULES_BASE_URL}/${scheduleId}/meta-events/${metaEventId}`);

/**
 * Create a meta-event for an event schedule
 * @param {Number} scheduleId
 * @param {Object} data
 * @returns {AxiosPromise<any>}
 */
export const createMetaEvent = (scheduleId, data) =>
  HTTP.post(`${SCHEDULES_BASE_URL}/${scheduleId}/meta-events`, data);

/**
 * Overwrite a meta-event in a schedule
 * @param {Number} scheduleId
 * @param {Number} metaEventId
 * @param {Object} data
 * @returns {AxiosPromise<any>}
 */
export const putMetaEvent = (scheduleId, metaEventId, data) =>
  HTTP.put(`${SCHEDULES_BASE_URL}/${scheduleId}/meta-events/${metaEventId}`, data);

/**
 * Patch a meta-event in a schedule
 * @param {Number} scheduleId
 * @param {Number} metaEventId
 * @param {Object} data
 * @returns {AxiosPromise<any>}
 */
export const patchMetaEvent = (scheduleId, metaEventId, data) =>
  HTTP.patch(`${SCHEDULES_BASE_URL}/${scheduleId}/meta-events/${metaEventId}`, data);

/**
 * Delete a meta event from an event-schedule
 * @param {Number} scheduleId
 * @param {Number} metaEventId
 * @returns {AxiosPromise}
 */
export const deleteMetaEvent = (scheduleId, metaEventId) =>
  HTTP.delete(`${SCHEDULES_BASE_URL}/${scheduleId}/meta-events/${metaEventId}`);

export default {
  getMetaEvent,
  getMetaEventsFor,
  createMetaEvent,
  putMetaEvent,
  patchMetaEvent,
  deleteMetaEvent,
};
