import {Policy} from '@/permissions/Policy';
import {User} from '@/users/models/User';

export class UserPolicy extends Policy {
  /**
   * See if a user can create assignments
   * @param {User} user
   * @param userId
   * @returns {*}
   */
  update(user: User, userId: number) {
    return user.id === userId || user.isSuperUser();
  }
}
