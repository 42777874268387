import {Policy} from '@/permissions/Policy';
import {User} from '@/users/models/User';

export class SuperAdminPolicy extends Policy {
  view(user: User) {
    return user.isSuperUser();
  }
}

export default SuperAdminPolicy;
