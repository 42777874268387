// Models
import {Course} from '@/courses/models';
import Assignment from '@/assignments/models/Assignment';

export const assignmentNameRenderer = ({params}) => {
  const assignment = Assignment.find(params.assignmentId);

  if (assignment) {
    return assignment.name;
  }
  return 'Assignment';
};

export const courseNameRenderer = ({params}) => {
  const course = Course.find(params.courseId);
  if (course) {
    return course.name;
  }
  return 'Course';
};
