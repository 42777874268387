import Specification from './Specification';

export class IsChildOf extends Specification {
  constructor(courseLikes, depth = 0, crossCourseBoundaries = false) {
    super();
    this.courseLikes = Specification.getIds(courseLikes);
    this.depth = depth;
    this.crossCourseBoundaries = crossCourseBoundaries;
  }

  isSatisfiedBy(courseRole) {
    for (const courseLikeId of this.courseLikes) {
      if (this.isChildOf(courseRole.courseLikeId, courseLikeId, this.depth)) {
        return true;
      }
    }
    return false;
  }

  isChildOf(candidate, courseLike, depth = 0) {
    let children;
    if (this.crossCourseBoundaries) {
      children = this.courseHierarchyService.getChildrenIds(courseLike);
    } else {
      children = this.courseHierarchyService.getSectionIds(courseLike);
    }

    if (children.includes(candidate)) {
      return true;
    }

    if (depth > 0) {
      for (const child of children) {
        if (this.isChildOf(candidate, child, depth - 1)) {
          return true;
        }
      }
    }
    return false;
  }
}

export default IsChildOf;
