export function createFormData(data: {[id: string]: string | File}): FormData {
  const formData = new FormData();
  for (const key in data) {
    if (data[key] === null) {
      // allow null values to be sent as empty strings.
      formData.append(key, ''); // Laravel middleware will convert them back to null
    } else {
      formData.append(key, data[key]);
    }
  }
  return formData;
}
