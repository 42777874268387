/*
 * Method to dynamically load scripts and stylesheets (primarily for SIMs)
 */

const loadedScripts: Record<string, Promise<HTMLScriptElement>> = {};
const loadedStyles: Record<string, Promise<HTMLLinkElement>> = {};

// Because the legacy-frontend/sims can be hosted on another domain from the main site
// we may need to prepend the base url to ensure scripts/styles load from the built app's base url (which will point to it's cloudfront/CDN domain)
const mergeWithBaseUrl = (path: string) => {
  // Trim prefix / from path
  if (path.startsWith('/')) {
    path = path.slice(1);
  }

  return process.env.BASE_URL + path;
};

/**
 * Dynamically load a script by dynamically adding a <script> tag to <head>
 * @param {String} src the source for the script
 * @param {Boolean} async whether to add the async attribute (true by default)
 * @returns {Promise<HTMLScriptElement>}
 */
async function loadScript(src: string, {async = true} = {}): Promise<HTMLScriptElement> {
  return new Promise((resolve) => {
    const el = document.createElement('script');
    el.src = src;
    el.async = async;
    el.onload = () => resolve(el);

    document.head.appendChild(el);
  });
}

/**
 * Load a script dynamically, but only once. Returns null if the script is
 * already loaded.
 * @param {String} src the source for the script
 * @param {Object} options the options for the loadScript method
 * @param {Boolean} preventBaseUrlMerge prevents the app's base url from being prepended to the href
 * @returns {Promise<HTMLScriptElement>}
 */
export async function loadScriptOnce(
  src: string,
  options = {},
  preventBaseUrlMerge = false
): Promise<HTMLScriptElement> {
  src = preventBaseUrlMerge ? src : mergeWithBaseUrl(src);

  if (loadedScripts[src] === undefined) {
    loadedScripts[src] = loadScript(src, options);
  }
  return loadedScripts[src];
}

/**
 * Dynamically load a stylesheet by adding a <link> tag to the <head>
 * @param {String} href the location of the stylesheet
 * @returns {Promise<HTMLLinkElement>}
 */
async function loadStyles(href: string): Promise<HTMLLinkElement> {
  return new Promise((resolve) => {
    const el = document.createElement('link');
    el.href = href;
    el.rel = 'stylesheet';

    el.onload = () => resolve(el);
    document.head.appendChild(el);
  });
}

/**
 * Dynamically load styles, but only once. Returns null if styles are already loaded.
 * @param {String} href the location of the stylesheet
 * @param {Boolean} preventBaseUrlMerge prevents the app's base url from being prepended to the href
 * @returns {Promise<HTMLLinkElement>}
 */
export async function loadStylesOnce(
  href: string,
  preventBaseUrlMerge: boolean = false
): Promise<HTMLLinkElement> {
  href = preventBaseUrlMerge ? href : mergeWithBaseUrl(href);

  if (loadedStyles[href] === undefined) {
    loadedStyles[href] = loadStyles(href);
  }
  return loadedStyles[href];
}
