import {
  StembleBaseTheme,
  StembleChartTheme,
  StembleExtendedTheme,
  StembleGradeTheme,
  StembleTheme,
} from '@/theme/types';
import {GradeStatus} from '@/assignments/types/GradeStatus';

const baseTheme: StembleBaseTheme = {
  primary: '#0A579B',
  secondary: '#d83d0e',
  accent: '#d83d0e',
  info: '#d83d0e',
  success: '#4CAF50',
  error: '#ff5252',
  warning: '#fb8c00',
};

const extendedTheme: StembleExtendedTheme = {
  danger: '#ff0000',
};

// FIXME: These reference color classes for the progress bar, not actual colors
const gradeTheme: StembleGradeTheme = {
  [GradeStatus.Correct]: 'green', // #4caf50
  [GradeStatus.Incorrect]: 'red', // #f44336
  [GradeStatus.PartiallyCorrect]: 'blue', // #2196f3
  [GradeStatus.Ungraded]: 'black', // #000000
  [GradeStatus.Incomplete]: 'white', // #ffffff
  [GradeStatus.Draft]: 'lightgray', // #9e9e9e
  alternateIncomplete: '#C0D0DD',
};

const chartTheme: StembleChartTheme = {
  chartPrimary: baseTheme.primary,
  chartSecondary: baseTheme.secondary,
  chartTertiary: '#ad0e25',
  chartQuaternary: baseTheme.success,
  chartQuinary: baseTheme.warning,
  chartSenary: baseTheme.error,
};

export const lightTheme: StembleTheme = {
  ...baseTheme,
  ...extendedTheme,
  ...gradeTheme,
  ...chartTheme,
};
