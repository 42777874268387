import {Course, CourseLike} from '.';
import SectionEvent from '@/events/models/SectionEvent';
import {Joined} from '@/orm/types/Joined';
import * as CourseSectionApi from '@/courses/api/orm/CourseSectionApi';
import {ICourseSectionQueryParameters} from '@/courses/types/ICourseSectionQueryParameters';
import {Fields, Query} from '@vuex-orm/core';
import {RawSection} from '@/courses/types/RawSection';

type NoRelationshipsCourseSection = Omit<
  RawSection,
  'courseLikeAssignments' | 'courseRoles' | 'sections'
>;

/**
 * CourseSection class
 */
export class CourseSection extends CourseLike implements NoRelationshipsCourseSection {
  static entity = 'CourseSection';

  parentId!: number;
  events!: Joined<SectionEvent[]>;

  static fields(): Fields {
    return {
      ...super.fields(),
      events: this.hasMany(SectionEvent, 'ownerId'),
      // TODO: this will only work so long as subsections aren't used
      parent: this.belongsTo(Course, 'parentId'),
    };
  }

  static get api() {
    return CourseSectionApi;
  }

  /**
   * @deprecated
   */
  get title(): string {
    return this.name;
  }

  static fullQuery(options: ICourseSectionQueryParameters = {}): Query<CourseSection> {
    let q = super.fullQuery(options);

    if (options.events) {
      q = q.with('events');
    }

    if (options.parent) {
      q = q.with('parent', (q2) => {
        return Course.fullQuery({
          query: q2 as Query<Course>,
        });
      });
    }

    return q as Query<CourseSection>;
  }
}

export default CourseSection;
