import {Policy} from '@/permissions/Policy';
import {User} from '@/users/models/User';

export class CourseAdminPolicy extends Policy {
  /**
   * @param {User} user
   * @par
   * am {Number} courseId
   * @returns {boolean|*}
   */
  view(user: User, courseId: number) {
    return user.can('viewCourseAdminFor', 'CourseLike', courseId);
  }
}

export default CourseAdminPolicy;
