import {HTTP} from '@/common/api/http';
import {API_BASE_URL} from '@/common/api/base';
import {IGetCourseParams} from '@/courses/types/IGetCourseParams';
import {IGetCoursesParams} from '@/courses/types/IGetCoursesParams';
import {IStembleAxiosPromise} from '@/common/api/types/IStembleAxiosPromise';
import {IGetParticipantsForCourseParams} from '@/courses/types/IGetParticipantsForCourseParams';
import {CreateCourseData} from '@/courses/types/CreateCourseData';
import {remapSection} from '@/courses/api/course-sections';
import {RawCourse} from '@/courses/types/RawCourse';
import {PatchCourseData} from '@/courses/types/PatchCourseData';
import {DeepRequired} from 'ts-essentials';
import {RawUser} from '@/users/types/RawUser';
import {AxiosRequestConfig} from 'axios';

export const COURSES_BASE_URL = API_BASE_URL + '/courses';

// TODO: when API is fixed, remove remap call
function remapSections(data: CreateCourseData | PatchCourseData) {
  return {
    ...data,
    sections: data.sections?.map(remapSection),
  };
}

/**
 * Get all courses
 * @returns {IStembleAxiosPromise}
 * @param params
 */
export const getCourses = async (
  params: IGetCoursesParams = {}
): IStembleAxiosPromise<RawCourse[]> => {
  return HTTP.get(COURSES_BASE_URL, {params}).then((r) => {
    return r as unknown as IStembleAxiosPromise<RawCourse[]>;
  });
};

/**
 * Get a course by ID
 * @param {Number} id
 * @param params
 * @returns {IStembleAxiosPromise}
 */
export const getCourse = async (
  id: RawCourse['id'],
  params: IGetCourseParams = {}
): IStembleAxiosPromise<RawCourse> => {
  return HTTP.get(`${COURSES_BASE_URL}/${id}`, {params});
};

/**
 * Get courses for a specific user
 * @param userId
 * @param options
 * @returns {IStembleAxiosPromise}
 */
export const getCoursesForUser = async (
  userId: RawUser['id'],
  options: IGetCoursesParams = {}
): IStembleAxiosPromise<RawCourse[]> => {
  return getCourses({...options, user: userId});
};

/**
 * Create a new course
 * @param data
 * @returns {IStembleAxiosPromise}
 */
export const createCourse = async (data: CreateCourseData): IStembleAxiosPromise<RawCourse> => {
  // TODO: when API is fixed, remove remap call
  return HTTP.post(COURSES_BASE_URL, remapSections(data));
};

/**
 * Update a course with new data
 * @param id
 * @param data
 * @returns {IStembleAxiosPromise}
 */
export const putCourse = async (
  id: RawCourse['id'],
  data: DeepRequired<PatchCourseData>
): IStembleAxiosPromise<RawCourse> => {
  // TODO: when API is fixed, remove remap call
  return HTTP.put(`${COURSES_BASE_URL}/${id}`, remapSections(data));
};

/**
 * Patch a course
 * @param id
 * @param data
 * @returns {IStembleAxiosPromise}
 */
export const patchCourse = async (
  id: RawCourse['id'],
  data: PatchCourseData
): IStembleAxiosPromise<RawCourse> => {
  // TODO: when API is fixed, remove remap call
  return HTTP.patch(`${COURSES_BASE_URL}/${id}`, remapSections(data));
};

/**
 * Delete a course
 * @param id
 * @returns {IStembleAxiosPromise}
 */
export const deleteCourse = async (id: RawCourse['id']): IStembleAxiosPromise => {
  return HTTP.delete(`${COURSES_BASE_URL}/${id}`);
};

/**
 * Get participants for a course
 * @param {Number} courseId
 * @param params
 * @returns {IStembleAxiosPromise}
 */
export const getParticipantsFor = async (
  courseId: RawCourse['id'],
  params: IGetParticipantsForCourseParams = {}
): IStembleAxiosPromise => {
  return HTTP.get(`${COURSES_BASE_URL}/${courseId}/participants`, {params});
};

export default {
  getCourse,
  getCoursesForUser,
  getCourses,
  createCourse,
  putCourse,
  patchCourse,
  deleteCourse,
  getParticipantsFor,
};
