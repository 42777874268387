import {ActionFactory} from '@/orm/api/actions';
import {
  addSectionsTo,
  getSectionsIn,
  removeSectionFrom,
  removeSectionsFrom,
} from '@/events/api/sections';
import {CourseSection} from '@/courses/models';

const factory = new ActionFactory(() => CourseSection);

export const fetchSectionsInSchedule = factory.insertAction(getSectionsIn);
export const addSectionsToSchedule = factory.insertAction(addSectionsTo);
export const removeSectionsFromSchedule = factory.deleteAction(removeSectionsFrom);
export const removeSectionFromSchedule = factory.deleteAction(removeSectionFrom);
