<template>
  <v-app id="404" data-test-page-name="404" style="background: rgba(167, 167, 167, 0.84)">
    <v-container class="fill-height mt-16 mb-16" tag="section">
      <v-row no-gutters justify="start" class="display-4 font-weight-light">
        {{ $t('uh-oh') }}
      </v-row>

      <v-row no-gutters justify="start" class="display-2 font-weight-light pt-lg-5 pt-md-5">
        {{ $t('no-page-text') }}
      </v-row>
      <v-row no-gutters justify="start" class="pt-5 ml-n6" dark>
        <v-btn text class="display-2 font-weight-medium" @click="$router.go(-1)">
          {{ $t('go-back') }}
          <v-icon right large class="mt-n0"> mdi-chevron-right </v-icon>
        </v-btn>
      </v-row>
      <v-row no-gutters justify="start" class="pt-5 ml-n6" dark>
        <v-btn
          text
          class="display-2 font-weight-medium"
          :href="isLoggedIn ? dashboardTo : '/login'"
        >
          {{ $t(isLoggedIn ? 'go-to-home-page' : 'go-to-login-page') }}
          <v-icon right large class="mt-n0"> mdi-chevron-right </v-icon>
        </v-btn>
        <v-row no-gutters justify="center" class="pt-n16 mb-n16">
          <v-img style="align-self: center" src="@/errors/assets/img/404.png" />
        </v-row>

        <v-row no-gutters class="mt-lg-n16 mt-md-n16" justify="start" align="start">
          <v-col cols="auto">
            <v-img
              contain
              position="left"
              width="200px"
              min-width="200px"
              src="@/common/assets/img/logo-color-large-nomargin.png"
            />
          </v-col>
        </v-row>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import {DASHBOARD, LOGIN} from '@/router/route-names';

export default {
  name: 'NotFound',
  computed: {
    dashboardTo() {
      return {name: DASHBOARD};
    },
    isLoggedIn() {
      return this.$auth.isUserLoaded;
    },
  },
};
</script>
