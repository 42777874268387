import TaskResponseTaskFeedback from '@/tasks/models/TaskResponseTaskFeedback';
import {substituteMustache} from '@/common/utils/substituteMustache';

export function getFeedbackByPart(feedbackJoiners: TaskResponseTaskFeedback[]) {
  const result: Record<string, TaskResponseTaskFeedback[]> = {};
  for (const joiner of feedbackJoiners) {
    const part = joiner.part ?? 'default';
    if (result[part] === undefined) {
      result[part] = [];
    }
    if (joiner.feedback) {
      result[part].push(joiner);
    }
  }
  return result;
}

export function getFeedbackTextByPart(
  feedbackByPart: Record<string, TaskResponseTaskFeedback[]> | null
): Record<string, string[]> {
  if (feedbackByPart === null) {
    return {};
  }
  const keys = Object.keys(feedbackByPart || {}).sort();
  const textsByPart = keys.map((key) => [
    key,
    feedbackByPart[key].map((feedback) =>
      substituteMustache(feedback.feedback?.content ?? '', feedback.feedbackData)
    ),
  ]);

  return Object.fromEntries(textsByPart);
}
