import Specification from './Specification';

export class AndSpecification extends Specification {
  constructor(spec1, spec2) {
    super();
    this.spec1 = spec1;
    this.spec2 = spec2;
  }

  isSatisfiedBy(courseRole) {
    return this.spec1.isSatisfiedBy(courseRole) && this.spec2.isSatisfiedBy(courseRole);
  }

  setServices(courseHierarchyService) {
    super.setServices(courseHierarchyService);
    this.spec1.setServices(courseHierarchyService);
    this.spec2.setServices(courseHierarchyService);
  }
}

export default AndSpecification;
