import {getTask, getTasks, nextTaskApi, patchTask, previousTaskApi} from '@/tasks/api/tasks';
import {ActionFactory} from '@/orm/api/actions';
import Task from '@/tasks/models/Task';
import {IPatchTaskParams} from '@/tasks/types/IPatchTaskParams';
import {inject} from '@/container';
import {ContentNestingService} from '@/orm/services/ContentNestingService';
import {IStembleAxiosPromise} from '@/common/api/types/IStembleAxiosPromise';
import {RawTask} from '@/tasks/types/RawTask';
import {TaskScopeContent} from '@/tasks/models/TaskScopeContent';

const factory = new ActionFactory(() => Task);

const {flatten} = inject(ContentNestingService);

const flattenTaskForPatch = (
  fn: (id: number, data: IPatchTaskParams) => IStembleAxiosPromise<RawTask>
) => {
  return (id: number, data: Omit<IPatchTaskParams, 'scopes'> & {scopes: TaskScopeContent[]}) => {
    const _data: IPatchTaskParams = {
      ...data,
      scopes: data.scopes?.map((scope) => flatten(scope)),
    };
    return fn(id, _data);
  };
};

const removeOldScopes = (task: RawTask): RawTask => {
  if (task.scopes) {
    const oldScopes = TaskScopeContent.query().where('taskId', task.id).get();
    oldScopes.forEach((oldScope) => TaskScopeContent.delete(oldScope.id));
  }

  return task;
};

const wrapTaskReturnFunction = <F extends (...args: any[]) => Promise<any>>(
  func: F,
  wrapType: 'array' | 'object' | 'either'
) => {
  return async (...args: Parameters<F>) => {
    const response = await func(...args);
    if (response.data.data) {
      if (wrapType === 'array' || (wrapType === 'either' && Array.isArray(response.data.data))) {
        response.data.data = response.data.data.map(removeOldScopes);
      } else if (wrapType === 'object' || typeof response.data.data === 'object') {
        response.data.data = removeOldScopes(response.data.data);
      }
    }
    return response;
  };
};

export const fetch = factory.insertAction(getTasks);
export const get = factory.insertAction(getTask);
export const patch = factory.insertAction(
  wrapTaskReturnFunction(flattenTaskForPatch(patchTask), 'object')
);
export const nextTask = factory.insertAction(nextTaskApi);
export const previousTask = factory.insertAction(previousTaskApi);
