import Institution from '@/institutions/models/Institution';
import Role from '@/users/models/Role';
import CourseRole from '@/users/models/CourseRole';
import {Course, CourseSection} from '@/courses/models';

import Gate from '@/permissions/Gate';
import {PermissionsService} from '@/permissions/services/PermissionsService';
import {Joined} from '@/orm/types/Joined';
import * as UserApi from '@/users/api/orm/UserApi';
import Fields from '@vuex-orm/core/lib/model/contracts/Fields';
import {inject} from '@/container';
import {RawUser} from '@/users/types/RawUser';
import {DefaultPolicyMap} from '@/permissions/types/DefaultPolicyMap';
import {PolicyMethodArgs} from '@/permissions/types/PolicyMethodArgs';
import {OmitUserArg} from '@/permissions/types/OmitUserArg';
import {Ability} from '@/permissions/types/Ability';
import {StembleModel} from '@/common/models/StembleModel';

// User active statuses
export const STATUS_INACTIVE = 'no';
export const STATUS_INACTIVE_ICON = 'fas fa-exclamation-circle text-danger';
export const STATUS_PARTIAL_REGISTRATION = 'partialRegistered';
export const STATUS_PARTIAL_ICON = 'fas fa-exclamation-triangle text-warning';
export const STATUS_FULL_REGISTRATION = 'fullyRegistered';
export const STATUS_FULL_ICON = 'fas fa-check-circle text-success';

/**
 * User class
 */
export class User extends StembleModel implements Omit<RawUser, 'courseRoles'> {
  static entity = 'User';

  private _isFaculty: boolean | null = null;

  id!: number;
  firstName!: string;
  lastName!: string;
  createdAt!: string;
  isActive!: boolean;
  hasVerifiedEmail!: boolean;
  email!: string;
  phoneNumber!: string | null;
  studentId!: string | null;
  avatarUrl!: string;
  institutionId!: number | null;
  institution!: Joined<Institution>;
  roleIds!: number[];
  roles!: Joined<Role[]>;
  courseRoles!: Joined<CourseRole[]>;
  courseSections!: Joined<CourseSection[]>;
  permissions!: string[];

  static fields(): Fields {
    return {
      id: this.number(null),
      firstName: this.string(''),
      lastName: this.string(''),
      createdAt: this.string(''),
      isActive: this.boolean(false),
      hasVerifiedEmail: this.boolean(false),
      email: this.string(''),
      phoneNumber: this.string('').nullable(),
      studentId: this.string('').nullable(),
      avatarUrl: this.string(''),
      institutionId: this.number(0).nullable(),
      institution: this.belongsTo(Institution, 'institutionId'),
      roleIds: this.attr([]),
      roles: this.hasManyBy(Role, 'roleIds'),
      courseRoles: this.hasMany(CourseRole, 'userId'),
      courseSections: this.belongsToMany(CourseSection, CourseRole, 'userId', 'courseLikeId'),
      permissions: this.attr([]),
    };
  }

  static get api() {
    return UserApi;
  }

  /**
   * Get a user's full name.
   *
   * @returns {string}
   */
  get fullName() {
    return `${this.firstName} ${this.lastName}`;
  }

  can<K extends keyof DefaultPolicyMap, MK extends keyof DefaultPolicyMap[K]>(
    action: MK | Ability,
    modelOrName?: K,
    // @ts-ignore
    ...args: PolicyMethodArgs<DefaultPolicyMap[K][MK]>
  ) {
    return inject(Gate.injectable)
      .withUser(this)
      .can(action, modelOrName, ...args);
  }

  cannot<K extends keyof DefaultPolicyMap, MK extends keyof DefaultPolicyMap[K]>(
    action: MK | Ability,
    modelOrName?: K,
    // @ts-ignore
    ...args: PolicyMethodArgs<DefaultPolicyMap[K][MK]>
  ) {
    return inject(Gate.injectable)
      .withUser(this)
      .cannot(action, modelOrName, ...args);
  }

  checkAnyCoursePermission(
    ...args: Parameters<OmitUserArg<PermissionsService['checkAnyCoursePermission']>>
  ) {
    return inject(PermissionsService.injectable).checkAnyCoursePermission(this, ...args);
  }

  checkCourseRolesAgainstSpecification(
    ...args: Parameters<OmitUserArg<PermissionsService['checkCourseRolesAgainstSpecification']>>
  ) {
    return inject(PermissionsService.injectable).checkCourseRolesAgainstSpecification(
      this,
      ...args
    );
  }

  /**
   * See if this user has permission to do something globally or because of a course role
   * @param ability
   * @returns {boolean}
   */
  hasAnyPermissionTo(ability: Ability) {
    return this.hasPermissionTo(ability) || this.hasCoursePermissionTo(ability);
  }

  /**
   * Checks to see if this user has the ability to do something through their
   * permissions or roles
   *
   * @param ability the permission to check
   * @returns {boolean}
   */
  hasPermissionTo(ability: Ability) {
    if (!this.permissions && !this.roles) {
      throw 'Permissions and roles not loaded';
    }

    if (this.permissions) {
      for (const permission of this.permissions) {
        if (ability === permission) {
          return true;
        }
      }
    }
    if (this.roles) {
      for (const role of this.roles) {
        if (role.hasPermissionTo(ability)) {
          return true;
        }
      }
    }
    return false;
  }

  /**
   * Checks to see if a user has permission to do something within a courseLike through
   * their permissions, roles, and course roles
   * @param ability
   * @param courseLikeId
   * @param active
   * @returns {boolean}
   * @deprecated
   */
  hasCoursePermissionTo(
    ability: Ability,
    courseLikeId: number | null = null,
    active: boolean = true
  ) {
    if (this.hasPermissionTo(ability)) {
      return true;
    }
    if (!this.courseRoles) {
      throw 'CourseRoles not loaded';
    }
    for (const courseRole of this.courseRoles) {
      if (courseLikeId !== null && courseRole.courseLikeId !== courseLikeId) {
        continue;
      }
      if (courseRole.role?.hasPermissionTo(ability) && (!active || courseRole.isActive)) {
        return true;
      }
    }
    return false;
  }

  /**
   * Checks to see if a permission trickles down from a parent courseLike to the given
   * child courseLike
   * @param {String} ability
   * @param {Number} courseLikeId
   * @param {Boolean} recursive
   * @param {Boolean} active
   * @returns {boolean|boolean|*}
   * @deprecated
   */
  hasInheritedCoursePermissionTo(
    ability: Ability,
    courseLikeId: number,
    recursive: boolean = false,
    active: boolean = true
  ): boolean {
    if (this.hasCoursePermissionTo(ability, courseLikeId, active)) {
      return true;
    }

    const courseLike = Course.find(courseLikeId) || CourseSection.find(courseLikeId);
    if (courseLike !== null) {
      if (courseLike.parentId) {
        if (recursive) {
          return this.hasInheritedCoursePermissionTo(
            ability,
            courseLike.parentId,
            recursive,
            active
          );
        } else {
          return this.hasCoursePermissionTo(ability, courseLike.parentId, active);
        }
      }
    }
    return false;
  }

  /**
   * Checks to see if a permission propagates upward from children up to this parent courseLike
   * @param {String} ability
   * @param {Number} courseLikeId
   * @param {Boolean} recursive
   * @param {Boolean} active
   * @returns {boolean}
   * @deprecated
   */
  hasPropagatedCoursePermissionTo(
    ability: Ability,
    courseLikeId: number,
    recursive = false,
    active = true
  ): boolean {
    let result = this.hasCoursePermissionTo(ability, courseLikeId, active);
    if (result) {
      return result;
    }

    const courseLike = Course.find(courseLikeId) || CourseSection.find(courseLikeId);
    if (courseLike !== null) {
      const children = courseLike.childrenIds;
      for (const child of children) {
        if (recursive) {
          result = this.hasPropagatedCoursePermissionTo(ability, child, recursive, active);
        } else {
          result = this.hasCoursePermissionTo(ability, child, active);
        }
        if (result) {
          return result;
        }
      }
    }
    return false;
  }

  /**
   * Checks to see if this user has a role. Must have roles loaded.
   *
   * @param name
   * @returns {boolean}
   */
  hasRoleByName(name: string) {
    if (!this.roles) {
      throw 'Roles not loaded';
    }
    for (const role of this.roles) {
      if (role.name === name) {
        return true;
      }
    }
    return false;
  }

  /**
   * Checks to see if this user has a role by name in a specific course.
   * CourseRoles must be loaded.
   *
   * @param name
   * @param courseLikeId
   * @returns {boolean}
   */
  hasCourseRoleByName(name: string, courseLikeId: number | null = null) {
    if (!this.courseRoles) {
      throw 'CourseRoles not loaded';
    }
    for (const courseRole of this.courseRoles) {
      if (courseLikeId !== null && courseRole.courseLikeId !== courseLikeId) {
        continue;
      }
      if (courseRole.role?.name === name) {
        return true;
      }
    }
    return false;
  }

  /**
   * Gets the CourseRole that a user has in a courseLike
   * @param {Number|Number[]|Set<Number>} courseLikeIds
   * @returns {Array}
   */
  getCourseRolesFor(courseLikeIds: number | number[] | Set<number>) {
    if (typeof courseLikeIds === 'number') {
      courseLikeIds = [courseLikeIds];
    }
    const courseLikeIdsSet = new Set(courseLikeIds);

    if (!this.courseRoles) {
      throw 'CourseRoles not loaded';
    }
    return this.courseRoles.filter((cr) => {
      return courseLikeIdsSet.has(cr.courseLikeId);
    });
  }

  /**
   * Gets the active status of a user, potentially with respect to a course.
   *
   * If no courseLikeIds are provided, simply returns inactive or fully registered
   * If courseLikeIds are provided, can return:
   *   STATUS_INACTIVE: user is in the system, but not active
   *   STATUS_PARTIAL_REGISTRATION: user is in the system and active,
   *     but not active in the list of courseLikes
   *   STATUS_FULL_REGISTRATION: user is both in the system and active in this
   *     course
   *
   * @param courseLikeIds
   * @returns {string}
   */
  getActiveStatus(courseLikeIds: number[] | null = null) {
    if (!this.isActive) {
      return STATUS_INACTIVE;
    }

    if (courseLikeIds) {
      for (const courseLikeId of courseLikeIds) {
        const courseRoles = this.getCourseRolesFor(courseLikeId);
        if (courseRoles.length) {
          for (const cr of courseRoles) {
            if (cr.isActive) {
              return STATUS_FULL_REGISTRATION;
            }
          }
        }
      }
      return STATUS_PARTIAL_REGISTRATION;
    }
    return STATUS_FULL_REGISTRATION;
  }

  /**
   * Checks to see if the user has the ability to view the course admin in any course or globally
   * @return {Boolean}
   */
  isFaculty(): boolean {
    if (this._isFaculty === null) {
      this._isFaculty = this.checkAnyCoursePermission(Ability.ViewCourseAdmin);
    }
    return this._isFaculty;
  }

  isSuperUser(): boolean {
    return this.hasRoleByName('Super User');
  }
}

export default User;
