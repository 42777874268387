import {Fields, Model} from '@vuex-orm/core';
import {
  NestedRawAssignmentPolicy,
  PolicyType,
  RawAssignmentPolicy,
  RawAssignmentPolicyContentMap,
} from '@/assignment-policies/types/RawAssignmentPolicy';
import {Joined} from '@/orm/types/Joined';
import {ContentNestingService} from '@/orm/services/ContentNestingService';
import {inject} from '@/container';
import {LateResponsePenaltyPolicyContentKeys} from '@/assignment-policies/types/RawLateResponsePenaltyPolicy';
import {LateResponsePenaltyPolicy} from '@/assignment-policies/models/LateResponsePenaltyPolicy';

/**
 * AssignmentPolicy class
 */
export class AssignmentPolicy<T extends PolicyType = PolicyType>
  extends Model
  implements Omit<NestedRawAssignmentPolicy<T>, 'content'>
{
  static entity = 'AssignmentPolicy';

  id!: number;
  type!: T;
  courseLikeAssignmentId!: number;
  content!: Joined<RawAssignmentPolicyContentMap[T]>;
  name!: string;

  static fields(): Fields {
    return {
      id: this.number(null),
      type: this.string(''),
      courseLikeAssignmentId: this.number(null),
      content: this.morphTo('id', 'type'),
      name: this.string(''),
    };
  }
}

export function nestAssignmentPolicy<
  PT extends PolicyType,
  P extends RawAssignmentPolicy<PT>,
  K extends keyof P,
>(policy: P) {
  // Cannot get TS inspection working
  const keys = ASSIGNMENT_POLICY_CONTENT_KEYS[policy.type] as K[];
  const {nest} = inject(ContentNestingService);
  return nest(policy, [...keys, 'id'], ['id']);
}

export const ASSIGNMENT_POLICY_CONTENT_KEYS = {
  [PolicyType.LateResponsePenaltyPolicy]: LateResponsePenaltyPolicyContentKeys,
};

export const ASSIGNMENT_POLICY_TYPE_CLASS_MAP = {
  [PolicyType.LateResponsePenaltyPolicy]: LateResponsePenaltyPolicy,
};

export default AssignmentPolicy;
