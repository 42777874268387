import {Fields} from '@vuex-orm/core';
import {StembleModel} from '@/common/models/StembleModel';

/**
 * Question class
 */
export class Question extends StembleModel {
  id!: number;

  static fields(): Fields {
    return {
      id: this.number(null),
    };
  }
}

export default Question;
