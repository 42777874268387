import {Policy} from '@/permissions/Policy';
import moment from 'moment';
import CourseLikeAssignment from '@/assignments/models/CourseLikeAssignment';
import IsOrIsParentOf from '@/permissions/services/permissions-service/specifications/IsOrIsParentOf';
import IsSectionOf from '@/permissions/services/permissions-service/specifications/IsSectionOf';
import {OrSpecification} from '@/permissions/services/permissions-service/specifications/OrSpecification';
import {User} from '@/users/models/User';
import {Ability} from '@/permissions/types/Ability';
import Assignment from '@/assignments/models/Assignment';
import CourseLikePolicy from '@/courses/permissions/CourseLikePolicy';
import CourseHierarchyServiceClass from '@/courses/services/CourseHierarchyServiceClass';
import {PermissionsService} from '@/permissions/services/PermissionsService';

export class CourseLikeAssignmentPolicy extends Policy {
  protected courseHierarchyService: CourseHierarchyServiceClass;

  constructor(
    permissionService: PermissionsService,
    courseHierarchyService: CourseHierarchyServiceClass
  ) {
    super(permissionService);
    this.courseHierarchyService = courseHierarchyService;
  }

  protected assignImplementation(
    user: User,
    assignment: Assignment,
    courseLikeId: number,
    action: keyof CourseLikePolicy
  ) {
    // TODO: might argue that this first check is business logic rather than authorization logic
    const isSameCourse =
      this.courseHierarchyService.getCourseId(assignment.ownerId) ===
      this.courseHierarchyService.getCourseId(courseLikeId);
    return (
      isSameCourse &&
      // TODO: this may have to change in the future, especially if there are public/shareable assignments
      user.can('test', 'Assignment', assignment) &&
      user.can(action, 'CourseLike', courseLikeId)
    );
  }

  /**
   * Permission to assign a given assignment to sections they
   * @param user
   * @param assignment
   * @param courseLikeId
   */
  createFor(user: User, assignment: Assignment, courseLikeId: number) {
    return this.assignImplementation(user, assignment, courseLikeId, 'assignAssignmentsTo');
  }

  /**
   * Permission to assign somewhere within a CourseLike
   * @param user
   * @param assignment
   * @param courseLikeId
   */
  createIn(user: User, assignment: Assignment, courseLikeId: number) {
    return this.assignImplementation(user, assignment, courseLikeId, 'assignAssignmentsIn');
  }

  /**
   * Whether or a not a user is allowed to submit a response for a
   * CourseLikeAssignment
   * @param {User} user
   * @param {CourseLikeAssignment} cla
   * @return {boolean|*}
   */
  submitResponses(user: User, cla: CourseLikeAssignment) {
    const isStarted = moment(cla.startDate).isSameOrBefore(moment());
    const hasInheritedPermission = user.hasInheritedCoursePermissionTo(
      Ability.SubmitResponses,
      cla.courseLikeId,
      true
    );
    const hasPropagatedPermission = user.hasPropagatedCoursePermissionTo(
      Ability.SubmitResponses,
      cla.courseLikeId,
      true
    );
    const canCreate = user.hasInheritedCoursePermissionTo(
      Ability.CreateAssignments,
      cla.courseLikeId,
      true
    );

    return (isStarted && (hasInheritedPermission || hasPropagatedPermission)) || canCreate;
  }

  viewGradesFor(user: User, cla: CourseLikeAssignment) {
    return user.can('viewGradesFor', 'CourseLike', cla.courseLikeId);
  }

  submitGradesFor(user: User, cla: CourseLikeAssignment) {
    return user.can('submitGradesFor', 'CourseLike', cla.courseLikeId);
  }

  viewTaskAssignmentsFor(user: User, cla: CourseLikeAssignment) {
    const claStartDate = moment(cla.startDate);
    const hasStarted = claStartDate.isBefore(moment());

    return this.view(user, cla) && (hasStarted || user.can('test', 'CourseLikeAssignment', cla));
  }

  view(user: User, cla: CourseLikeAssignment) {
    const courseLike = cla.courseLikeId;
    const spec = new OrSpecification(new IsOrIsParentOf(courseLike), new IsSectionOf(courseLike));
    const hasPermission = this.permissionService.checkAnyCoursePermission(
      user,
      Ability.ViewCourseLikeAssignments,
      spec
    );
    const isVisible = cla.isVisible;

    if (hasPermission && isVisible) {
      return true;
    } else if (!isVisible && user.can('test', 'CourseLikeAssignment', cla)) {
      return true;
    }
    return false;
  }

  test(user: User, cla: CourseLikeAssignment) {
    return user.can('test', 'Assignment', cla.assignment);
  }

  grantExtensionsFor(user: User, cla: CourseLikeAssignment) {
    return user.can('grantExtensionsFor', 'CourseLike', cla.courseLikeId);
  }
}

export default CourseLikeAssignmentPolicy;
