import {HTTP} from '@/common/api/http';
import {API_BASE_URL} from '@/common/api/base';
import {IStembleAxiosPromise} from '@/common/api/types/IStembleAxiosPromise';
import {IGetUsersParams} from '@/users/types/IGetUsersParams';
import {IGetUserParams} from '@/users/types/IGetUserParams';
import {IPatchUserParams} from '@/users/types/IPatchUserParams';
import {createFormData} from '@/utility/util';

export const USER_BASE_URL = API_BASE_URL + '/user';
export const USERS_BASE_URL = API_BASE_URL + '/users';

/**
 * Gets the current user
 * @param {boolean} loadRoles
 * @returns {IStembleAxiosPromise}
 */
export const getCurrentUser = async (loadRoles: boolean = true): IStembleAxiosPromise => {
  const params: any = {};
  if (loadRoles) {
    params.includeRoles = true;
  }
  return HTTP.get(USER_BASE_URL, {params});
};

/**
 * Get multiple users
 * @returns {IStembleAxiosPromise}
 * @param params
 */
export const getUsers = async (params: IGetUsersParams = {}): IStembleAxiosPromise => {
  return HTTP.get(USERS_BASE_URL, {params});
};

/**
 * Get a single user by ID
 *
 * @param {Number} userId
 * @param params
 * @returns {IStembleAxiosPromise}
 */
export const getUser = async (userId: number, params: IGetUserParams = {}) => {
  return HTTP.get(`${USERS_BASE_URL}/${userId}`, {params});
};

export const getUserActivity = async (userId: number) => {
  return HTTP.get(`${USERS_BASE_URL}/${userId}/activity`);
};

export const patchUser = async (userId: number, data: IPatchUserParams): IStembleAxiosPromise => {
  const formData = createFormData(data);
  formData.append('_method', 'PATCH');

  return HTTP.post(`${USERS_BASE_URL}/${userId}`, formData, {
    headers: {'Content-Type': 'multipart/form-data'},
  });
};

export const getInstructors = async (): IStembleAxiosPromise => {
  return HTTP.get(`${USERS_BASE_URL}/instructors`);
};

export default {
  getCurrentUser,
  getUsers,
  getUser,
};
