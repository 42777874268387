import {HTTP} from '@/common/api/http';
import {API_BASE_URL} from '@/common/api/base';
import {IGetAssignmentGradesParams} from '@/grades/types/IGetAssignmentGradesParams';
import {IStembleAxiosPromise} from '@/common/api/types/IStembleAxiosPromise';

export const ASSIGNMENT_GRADES_BASE_URL = API_BASE_URL + '/assignment-grades';

/**
 * Fetch many assignment grades
 * @param {IGetAssignmentGradesParams} params
 * @returns {IStembleAxiosPromise}
 */
export const getAssignmentGrades = async (
  params: IGetAssignmentGradesParams = {}
): IStembleAxiosPromise => {
  return HTTP.get(ASSIGNMENT_GRADES_BASE_URL, {params});
};
