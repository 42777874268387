import {ActionFactory} from '@/orm/api/actions';
import SectionEvent from '@/events/models/SectionEvent';
import {
  addEventsTo,
  getEventsFor,
  patchEventsIn,
  removeEventFrom,
  removeEventsFrom,
} from '@/events/api/schedule-events';
import {
  createEvent,
  deleteEvent,
  getEvent,
  getEvents,
  patchEvent,
  updateEvent,
} from '@/events/api/events';

const factory = new ActionFactory(() => SectionEvent);

export const fetchEventsForSchedule = factory.insertAction(getEventsFor);
export const addEventsToSchedule = factory.insertAction(addEventsTo);
export const patchEventsInSchedule = factory.insertAction(patchEventsIn);
export const removeEventsFromSchedule = removeEventsFrom;
export const removeEventFromSchedule = removeEventFrom;
export const fetch = factory.insertAction(getEvents);
export const get = factory.insertAction(getEvent);
export const create = factory.insertAction(createEvent);
export const post = factory.insertAction(createEvent);
export const update = factory.insertAction(updateEvent);
export const put = factory.insertAction(updateEvent);
export const patch = factory.insertAction(patchEvent);
export const remove = factory.deleteAction(deleteEvent);
