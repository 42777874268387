import {Model} from '@vuex-orm/core';

/**
 * TaskContent class
 */
export default class TaskContent extends Model {
  static entity = 'TaskContent';

  static fields() {
    return {
      id: this.number(null),
      taskId: this.number(null),
      parentContentId: this.number(null),
      position: this.number(null),
      contentType: this.string(''),
      content: this.morphTo('id', 'contentType'),
    };
  }

  static fullQuery({query = null} = {}) {
    query = query || this.query();

    query = query.orderBy('position');
    query = query.with('content', (query2) => {
      return query2.with('taskContent', (query3) => {
        query3 = query3.orderBy('position');
        return query3.with('content');
      });
    });

    return query;
  }
}
