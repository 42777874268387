import {IntegerValueDto} from '@/task-values/dtos/IntegerValueDto';
import {AbstractTaskValue} from '@/task-values/models/AbstractTaskValue';

export class IntegerValue<K extends string | number = string>
  extends AbstractTaskValue<number, K>
  implements IntegerValueDto
{
  static entity = 'IntegerValue';

  value!: number;

  static fields() {
    return {
      ...super.fields(),
      value: this.number(null),
    };
  }

  getValue() {
    return this.value;
  }
}
