import QuestionResponse from './QuestionResponse';
import {Fields} from '@vuex-orm/core';

/**
 * WatchVideoResponse class
 */
export class WatchVideoResponse extends QuestionResponse {
  static entity = 'WatchVideoResponse';

  watched!: boolean;

  static fields(): Fields {
    return {
      ...super.fields(),
      watched: this.boolean(false),
    };
  }
}

export default WatchVideoResponse;
