import {HTTP} from '@/common/api/http';
import {TASKS_BASE_URL} from '../../tasks/api/tasks';
import {API_BASE_URL} from '@/common/api/base';
import {IStembleAxiosPromise} from '@/common/api/types/IStembleAxiosPromise';
import {IGetTaskStatesParams} from '@/task-states/types/IGetTaskStatesParams';
import {IGetStateForTaskParams} from '@/task-states/types/IGetStateForTaskParams';

export const TASK_STATE_BASE_URL = `${API_BASE_URL}/task-states`;

/**
 * Get a single task state by ID
 * @param {Number} id
 * @return {IStembleAxiosPromise}
 */
export const getTaskState = async (id: number): IStembleAxiosPromise => {
  return HTTP.get(`${TASK_STATE_BASE_URL}/${id}`);
};

/**
 * Fetch many task states
 * @return {IStembleAxiosPromise}
 * @param params
 */
export const getTaskStates = async (params: IGetTaskStatesParams = {}) => {
  return HTTP.get(TASK_STATE_BASE_URL, {params});
};

/**
 * Get or reissue TaskState for a specific task
 *
 * If tryIt is true, then this will just get a random TaskState for the given task.
 *
 * Otherwise, the same TaskState will be issued each time until re-issued (if
 * one needs the IssuedTaskState, it is better to use that API to get both
 * simultaneously)
 *
 * @param {Number} task the task ID
 * @param params
 *                  where it need not saved as an IssuedTaskState
 * @returns {IStembleAxiosPromise}
 */
export const getStateForTask = (
  task: number,
  params: IGetStateForTaskParams = {}
): IStembleAxiosPromise => {
  const url = `${TASKS_BASE_URL}/${task}/state`;
  if (params.reissue) {
    return HTTP.post(url, params);
  } else {
    return HTTP.get(url, {params});
  }
};

export default {
  getStateForTask,
  getTaskState,
  getTaskStates,
};
