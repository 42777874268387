import {Model} from '@vuex-orm/core';
import TaskContent from './TaskContent';

/**
 * BlockContent class
 */
export default class BlockContent extends Model {
  static entity = 'BlockContent';

  static fields() {
    return {
      id: this.number(null),
      taskContent: this.hasMany(TaskContent, 'parentContentId'),
    };
  }
}
