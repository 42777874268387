import OrSpecification from './OrSpecification';
import Is from '@/permissions/services/permissions-service/specifications/Is';
import IsParentOf from '@/permissions/services/permissions-service/specifications/IsParentOf';

export class IsOrIsParentOf extends OrSpecification {
  constructor(courseLikes, depth = 0, crossCourseBoundaries = true) {
    super(new Is(courseLikes), new IsParentOf(courseLikes, depth, crossCourseBoundaries));
  }
}

export default IsOrIsParentOf;
