import {RawChemicalElementValue} from '@/task-states/types/RawTaskVariableValue';
import {ChemicalElementValue} from '@/task-values/models/ChemicalElementValue';
import LegacyChemicalIsotopeValue from '@/task-values/models/legacy/LegacyChemicalIsotopeValue';

/**
 * ChemicalElementValue class
 */
export class LegacyChemicalElementValue
  extends ChemicalElementValue<number, LegacyChemicalIsotopeValue>
  implements RawChemicalElementValue
{
  static entity = 'LegacyChemicalElementValue';

  static fields() {
    return {
      ...super.fields(),
      id: this.number(null),
    };
  }
}

export default LegacyChemicalElementValue;
